import React, { createContext, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useAPI } from '../../hooks/APIService';
import { Center } from '@chakra-ui/layout';
import OrganizationFilter from './OrganizationFilter';
import * as d3 from 'd3';
import { addSimulation, buildHierarchyGraph, buildNetworkLinks, drawHierarchyChart, drawNetworkLinks, initHierarchyChart } from './OrganizationChartProcessor';

export const ChartContext = createContext();
export const MODE = {
    Hierarchy: 1,
    Teams: 2,
    Network: 3
}

const useLocalStorage = (storageKey, fallbackState) => {
    const [value, setValue] = React.useState(JSON.parse(localStorage.getItem(storageKey)) ?? fallbackState);

    React.useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(value));
    }, [value, storageKey]);

    return [value, setValue];
}

const OrganizationChart = ({ mode = MODE.Network }) => {
    const canvasRef = useRef();
    const { getOrganizationNetwork, getRoleById } = useAPI();
    const [ loaded, setLoaded ] = useState(false);
    const [ data, setData ] = useState(null);
    const [ simulation, setSimulation ] = useState(null);
    const [ chartType, setChartType ] = useLocalStorage("chart_mode", mode);
    const [ selected, setSelected ] = useState(null);
    const [ labelNodes, addLabelNodes ] = useLocalStorage("chart_labels", false);
    const [ include, setInclude ] = useLocalStorage("chart_includes", {
        team: {
            primary: true,
            workgroup: false,
            community: false
        },
        project: {},
        relation: {
            leader: true,
            primary: true,
            contributor: false,
            line: false
        }
    });

    useEffect(() => {
        if (!include.project) {
            include.project = {};
            setInclude(include);
        }
        if (simulation) simulation.stop();
        if (!loaded) loadChart();
        else (updateChart(data));
    }, [chartType, labelNodes, include]);

    const loadChart = async() => {
        setLoaded(false);
        getOrganizationNetwork().then((data) => {
            setData(data);
            updateChart(data);
        }).catch((error) => {
            console.log("error loading organization data", error);
        }).finally(() => {
            setLoaded(true);
        })
    }

    const updateChart = (data) => {
        console.log("update chart", chartType);

        const aspect = getAspectRatio();
        const dimensions = getDimensions(aspect);
        const svg = createSVGCanvas(dimensions);

        const root = buildHierarchyGraph(data, chartType, labelNodes, include);
        const links = buildNetworkLinks(data, chartType, labelNodes, include);
        
        const graph = initHierarchyChart(root, chartType, dimensions);

        const linkNodes = drawNetworkLinks(links, svg);
        const nodes = drawHierarchyChart(graph, chartType, svg, onSelectItem);

        var sim = null;
        if (chartType !== MODE.Hierarchy) sim = addSimulation(nodes, linkNodes, chartType, dimensions, svg);
        else if (simulation) simulation.stop();
        setSimulation(sim);
    }

    const getAspectRatio = () => {
        const containerRect = canvasRef.current.getBoundingClientRect();
        const width = containerRect.width;
        const height = containerRect.height;
        const aspect = width / height;
        return aspect;
    }

    const getDimensions = (aspect) => {
        const dimensions = {
            x: 1000 * aspect,
            y: 1000
        }
        return dimensions;
    }

    const createSVGCanvas = (dimensions) => {
        const viewBox = [0, 0, dimensions.x, dimensions.y];
        const svg = d3.select(canvasRef.current)
            .attr("viewBox", viewBox)
            .attr("style", "max-width: 100%; font-size: 0.9rem")
            .attr("text-anchor", "middle");
        svg.selectAll("g").remove();
        svg.append("g").attr("class", "graph");
        return svg;
    }

    const onSelectItem = async (node) => {
        console.log("select", node);
        const data = node.data;
        if (!data.relation || !data.relation.roles || data.relation.roles.length === 0) {
            setSelected({ item: data.item });
        }
        else {
            const roles = await loadRoles(data.relation.roles);
            setSelected({ item: data.item, roles: roles });
        }
    }

    const loadRoles = async (roles) => {
        const list = [];
        for (const link of roles) {
            const role = await getRoleById(link._id);
            list.push(role);
        }
        return list;
    }

    return <Center h="full" w="full" p={0} m={0}>
        <svg width={"100%"} height={"100%"} ref={canvasRef} />
        <ChartContext.Provider value={{ chartType, setChartType, labelNodes, addLabelNodes, selected, include, setInclude }}>
            <OrganizationFilter />
        </ChartContext.Provider>
    </Center>

}

OrganizationChart.propTypes = {
    mode: PropTypes.number
}

export default OrganizationChart;