import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuButton from './MenuButton.js';
import { useAuth0 } from "@auth0/auth0-react";
import { FaSignOutAlt } from "react-icons/fa";
import { MdHelp, MdSettings } from 'react-icons/md';
import MenuSection from './MenuSection.js';

const BaseActions = () => {
    const navigate = useNavigate();
    const { logout } = useAuth0();
    const configure = () => navigate('/config');
    const help = () => navigate('/help');
    const handleLogout = () => logout({ returnTo: window.location.origin });

    return(
        <MenuSection>
            <MenuButton icon={MdSettings} label="Config" action={ configure } />
            <MenuButton icon={MdHelp} label="Help" action={ help } />
            <MenuButton icon={FaSignOutAlt} label="Logout" action={ handleLogout } />
        </MenuSection>
    )
}

export default BaseActions;