import React, { useRef, useState } from 'react';
//import { useAPI } from '../../hooks/APIService.js';
import PropTypes from 'prop-types';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Box, Button, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { useAPI } from '../../hooks/APIService';
import { RiEditFill } from 'react-icons/ri';
import { useDebounceEffect } from './useDebounceEffect';
import { canvasPreview } from './canvasPreview';

const ImageCropResizeUpload = ({ id, onImageUploaded, children }) => {
    const allowedFileTypes = "image/png, image/jpeg, image/x-png";
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const [imgSrc, setImgSrc] = useState();
    const inputRef = useRef();
    const { addPicture } = useAPI();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            console.log("read image data", e.target.files[0]);
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || '')
            );
            reader.readAsDataURL(e.target.files[0]);
            console.log("open modal");
            onOpen();
        }
    };

    // If you setState the crop in here you should return false.
    const onImageLoaded = (e) => {
        console.log("image loaded");
        const { width, height } = e.currentTarget;
        setCrop(makeAspectCrop({ unit: '%', width: 100}, 1, width, height));
        //setImage(image);
    };

    /*
    const onCropChange = (crop) => {
        console.log("crop changed");
        setCrop(crop);
    };

    const getCroppedImageBlob = (image, crop, fileName) => {
        const canvas = drawCroppedImage(image, crop);
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(file => {
                if (!file) {
                    reject(new Error('image canvas is empty'));
                    console.error('image canvas is empty');
                    return;
                }
                file.name = fileName;
                resolve(file);
            }, 'image/x-png');
        });
    }

    const drawCroppedImage = (image, crop, size = 500) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        //const scaleX = size / image.width;
        //const scaleY = size / image.height;
        //const pixelRatio = window.devicePixelRatio;
        //canvas.width = crop.width * pixelRatio * scaleX;
        //canvas.height = crop.height * pixelRatio * scaleY;
        canvas.width = size;
        canvas.height = size / crop.aspect;
        console.log("canvas size", canvas.width, canvas.height);
        const ctx = canvas.getContext('2d');
        //ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            size, size / crop.aspect
            //crop.width * scaleX,
            //crop.height * scaleY
        );
        return canvas;
    }
    */

    const uploadImage = async () => {
        if (!previewCanvasRef.current) {
            throw new Error("Crop canvas does not exist");
        }

        previewCanvasRef.current.toBlob(async (blob) => {
            if (!blob) {
                throw new Error("Failed to create blob");
            }
            const file = new File([blob], id + ".png");
            //const file = blobUrlRef.current.file;
            const res = await addPicture(id, file, id + ".png");
            console.log("image uploaded", res);
            if (onImageUploaded) onImageUploaded(res.url);
            console.log(res);
        })

        resetCrop();
    };

    const resetCrop = () => {
        setCrop(null);
        setCompletedCrop(null);
        setImgSrc(null);
        onClose();
    };

    useDebounceEffect(async () => {
        console.log("use debounce effect", completedCrop);
        if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
            // We use canvasPreview as it's much faster than imgPreview.
            canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 500);
        }
    }, 100, [completedCrop])

    return (
        <Box className="ImageCrop" m="0" w="full" position="relative">
            { children }
            <input style={{ display: "none" }} id="upload-image" name="upload photo" type="file"
                multiple={false} accept={allowedFileTypes} onChange={onFileChange} ref={inputRef} />
            <Box position="absolute" bottom={0} right={0} m="0" p="0">
                <IconButton icon={<RiEditFill />} cursor="pointer" variant="link" size="xs" m="0" p="0" onClick={() => inputRef.current.click()} />
            </Box>
            <Modal isOpen={isOpen} onClose={resetCrop}>
                <ModalOverlay />
                <ModalContent color="black">
                    <ModalHeader>Select Image Section...</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        { !!imgSrc &&
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={1}
                                ruleOfThirds
                                circularCrop
                            >
                                <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoaded} height={"50%"} />
                            </ReactCrop>
                        }
                        { !!completedCrop &&
                            <>
                            <div style={{display: "none"}}>
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        border: '1px solid black',
                                        objectFit: 'contain',
                                        width: completedCrop.width,
                                        height: completedCrop.height,
                                    }}
                                />
                            </div>
                            <Button size="xs" onClick={uploadImage}>Upload</Button>
                            </>
                        }
                        </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );

}

ImageCropResizeUpload.propTypes = {
    id: PropTypes.string.isRequired,
    onImageUploaded: PropTypes.func,
    children: PropTypes.node
}

export default ImageCropResizeUpload;