import React from 'react';
import { Box, HStack, VStack, Text, Flex, Center } from '@chakra-ui/layout';
import { PropTypes } from 'prop-types';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Paragraphs from '../../components/editable/Paragraphs';
import TeamMiniCard from '../../components/card/TeamMiniCard';
import MiniCardLoader from '../../components/card/MiniCardLoader';
import { ArrowLeftIcon } from '@chakra-ui/icons';

const TeamDisplay = ({ item, size = 6, onSelect }) => {
    const navigate = useNavigate();
    const openItem = () => {
        navigate('/' + item.class + '/' + item._id);
    }

    //const color = getItemColor(item);

    return(
        <Box w="full" h="full" p="2%" borderRadius="5%" borderWidth={1} boxShadow="-10px 0px 10px -7px #ccc, 10px 0px 10px -7px #ccc, 5px 5px 15px 5px rgba(0,0,0,0)">
            <VStack alignItems="center" color="black" h="full" w="full" spacing={"3%"} m="0 auto">
                <HStack spacing={"5%"} alignItems="stretch" w="full">
                    <TeamMiniCard item={item} size={size * 1.2} />
                    { item.membership?.length > 0 && <>
                        <Center><ArrowLeftIcon boxSize={3} /></Center>
                        <Flex direction="row" gap={2} spacing={2} wrap="wrap" w="full" justifyContent={"flex-start"} alignContent={"center"}>
                            {item.membership?.map((member, index) => {
                                return <MiniCardLoader key={index} id={member._id} size={size / 3} showBadge={false} onClick={onSelect}/>
                            })}
                        </Flex>
                    </>}
                </HStack>
                <VStack spacing={0} h="full" w="full">
                    <Text fontSize="sm" fontWeight="bold">{item.name}</Text>
                    <Text fontSize="xs" lineHeight="1rem">{item.title}</Text>
                    <Box p={2} fontSize="xs" lineHeight="1rem" textOverflow="ellipsis" height="full">
                        { item.purpose &&
                            <Paragraphs text={item.purpose} />
                        }
                    </Box>
                </VStack>
                <Button variant="link" size="xs" lineHeight="1rem" onClick={openItem}>open profile</Button>
            </VStack>
        </Box>
    )
}

TeamDisplay.propTypes = {
    item: PropTypes.object,
    size: PropTypes.number,
    onSelect: PropTypes.func
}

export default TeamDisplay;