import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useAPI } from '../../hooks/APIService.js';
import { Box, Stack } from '@chakra-ui/layout';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { Radio, RadioGroup } from '@chakra-ui/radio';

const TeamForm = ({ onClose }) => {
    const navigate = useNavigate();
    const { addTeam } = useAPI();
    const [name, setName] = useState("");
    const [type, setType] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const createTeam = (name, type) => {
        const team = {
            name: name,
            type: type ? type : "primary"
        }
        console.log("add team: ", team);
        addTeam(team).then(data => {
            onClose();
            navigate("/team/" + data);
        }).catch(error => {
            console.log('ERROR: ' + error);
            setError({general: "error creating person in database: " + error});
        });
    }

    const handleNameChange = event => setName(event.target.value);
    const handleSubmit = event => {
        event.preventDefault();
        setSubmitted(true);
        if (name) createTeam(name, type);
    }

    return (
        <form name="addTeamForm" className="TeamForm Form" onSubmit={handleSubmit}>
            { submitted && error?.general &&
                <Box my={4}>
                    <Alert status="error" borderRadius={4}>
                        <AlertIcon />
                        <AlertDescription>{error?.general}</AlertDescription>
                    </Alert>
                </Box>
            }
            <FormControl id="name-input" mb="2" display="flex" isRequired isInvalid={error?.name}>
                <FormLabel fontSize="xs">Name</FormLabel>
                <Input placeholder="Name" aria-label="Name" size="sm" focusBorderColor="green.500" variant="flushed" value={name} onChange={handleNameChange} />
                <FormErrorMessage>{error?.name}</FormErrorMessage>
            </FormControl>
            <FormControl id="type-select" mb="8" display="flex" gridGap="4">
                <FormLabel fontSize="xs">Type</FormLabel>
                <RadioGroup size="sm" onChange={setType} value={type} colorScheme="green" focusBorderColor="green.500">
                    <Stack direction="column">
                        <Radio value="primary">Primary</Radio>
                        <Radio value="workgroup">Workgroup</Radio>
                        <Radio value="community">Community</Radio>
                    </Stack>
                </RadioGroup>
                <FormHelperText fontSize="xs" m="0">select <strong>Workgroup</strong> for temporary or part time collaborations and <strong>Community</strong> for out of work communites</FormHelperText>
            </FormControl>
            <ButtonGroup size="sm">
                <Button type="submit" isDisabled={!name||!type}>Add Team</Button>
                <Button type="button" onClick={onClose} variant="outline">Cancel</Button>
            </ButtonGroup>
        </form>
    )
}

TeamForm.propTypes = {
    onClose: PropTypes.func
}

export default TeamForm;