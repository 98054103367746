import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useAPI } from '../../hooks/APIService';
import { Box, Text, Textarea, useToast } from '@chakra-ui/react';
import { getTextColor } from '../../theme';

const EditableTextarea = ({ item, property, textAlign = "left", useTheme = false, fontWeight = "normal", fontSize = "md", minHeight = "2rem", placeholder = "not specified" }) => {
    const { patchPerson, patchTeam, patchProject, patchRole } = useAPI();
    const [ value, setValue ] = useState(item[property]);
    const [ isEdited, setEdited ] = useState(false);
    const textarea = useRef();
    const toast = useToast();

    useEffect(() => {
        if (isEdited) autoGrow();
    }, [isEdited]);

    /*
    const onTextEnter = (event) => {
        if (event.key === 'Enter') {
            console.log("enter text", event.target.value);
            event.target.blur();
        }
    }
    */

    const autoGrow = () => {
        const element = textarea.current;
        if (element.clientHeight < element.scrollHeight) {
            element.style.height = element.scrollHeight + "px";
            if (element.clientHeight < element.scrollHeight) {
                element.style.height = (element.scrollHeight * 2 - element.clientHeight) + "px";
            }
        }
    }

    const onEdit = () => {
        setEdited(true);
    }

    const onTextBlur = (event) => {
        setEdited(false);
        if (event.target.value === value) return;
        var map = {};
        map[property] = event.target.value;
        switch (item.class) {
            case "person": patchPerson(item._id, map); break;
            case "team": patchTeam(item._id, map); break;
            case "project": patchProject(item._id, map); break;
            case "role": patchRole(item._id, map); break;
            default:
                toast({
                    title: 'Invalid object class ' + item.class + '.',
                    description: property.charAt(0).toUpperCase() + property.slice(1) + " updated failed.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
        }
        toast({
            title: 'Saved changes.',
            description: property.charAt(0).toUpperCase() + property.slice(1) + " updated successfully.",
            status: 'success',
            duration: 5000,
            isClosable: true,
        })
        setValue(event.target.value);
    }

    const borderColor = useTheme ? getTextColor(item) : "gray.200";

    if (!isEdited) {
        var text = value ? value : placeholder;
        const lines = text.split(/\r?\n/);
        //text = (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />');
        const color = value ? null : "gray.300";
        const italic = value ? "normal" : "italic";
        return (
            <Box w="full">
                { lines.map( (line, index) => {
                    return <Text key={ "line" + index } onClick={onEdit} noOfLines={8} textAlign={textAlign}
                        variant="flushed" focusBorderColor={borderColor} bgColor="transparent"
                        fontWeight={fontWeight} size="xs" fontSize={fontSize} minHeight="1rem" borderBottom="none"
                        width="90%" pt={0} color={color} fontStyle={italic} lineHeight="1rem" mt={0}>
                            {line}
                    </Text>
                })}
            </Box>
        )
    }
    else {
        return <Textarea autoFocus defaultValue={value} placeholder={placeholder} textAlign={textAlign}
            variant="outline" focusBorderColor={borderColor} borderWidth={1} bgColor="transparent"
            fontWeight={fontWeight} size="xs" fontSize={fontSize} minHeight={minHeight} borderBottom="none"
            onKeyUp={autoGrow} onBlur={onTextBlur} width="100%" pt={0} pl={0} pr={0} ref={textarea} resize="none" />
    }
}

EditableTextarea.propTypes = {
    item: PropTypes.object.isRequired,
    property: PropTypes.string.isRequired,
    textAlign: PropTypes.string,
    useTheme: PropTypes.bool,
    fontWeight: PropTypes.string,
    minHeight: PropTypes.string,
    placeholder: PropTypes.string,
    fontSize: PropTypes.string
}

export default EditableTextarea;