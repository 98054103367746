import React, { useState, useContext, useEffect } from 'react';
import { PersonContext } from './Person.js';
import TeamSelector from '../../components/selector/TeamSelector.js';
import { RiAddCircleLine, RiCloseLine, RiStarFill, RiStarSLine } from 'react-icons/ri';
import { Divider, Heading, HStack, VStack, Wrap } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Button, Icon, IconButton, useDisclosure, useToast } from '@chakra-ui/react';
import PersonCapacity from './PersonCapacity.js';
import CapacityBadge from './CapacityBadge.js';
import { useAPI } from '../../hooks/APIService.js';
import TeamSticker from '../../components/sticker/TeamSticker.js';
import RoleListing from '../role/RoleListing.js';

const PersonMemberships = () => {
    const { person } = useContext(PersonContext);
    const [ teams, setTeams ] = useState(null);
    const { getTeams, patchTeam } = useAPI();
    const [ selector, setSelector ] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    useEffect(() => {
        loadTeams();
    }, []);

    const loadTeams = async() => {
        console.log("load teams");
        try {
            const data = await getTeams({ "membership._id": person._id });
            setTeams(data);
            console.log("teams loaded", data);
        }
        catch (e) {
            console.log("error retrieving memberships", e);
        }
    }

    const updateTeamProperty = (team, property, value) => {
        console.log("update team property", team, property, value);
        var map = {};
        map[property] = value;
        patchTeam(team._id, map);
        team[property] = value;
    }

    const addTeam = (team) => {
        console.log("add team", team);
        const isMember = teams.some(link => link._id == team._id);
        if (isMember) {
            toast({
                title: 'Warning',
                description: person.name + " is already member of team " + team.name,
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
            setSelector(null)
            return;
        }
        if (!team.membership) team.membership = [];
        team.membership.push({ _id: person._id, type: selector.type });
        updateTeamProperty(team, "membership", team.membership);
        const refresh = [...teams, team];
        setTeams(refresh);
        setSelector(null);
    }

    const removeTeam = (team) => {
        console.log("remove team", team);
        const update = team.membership.filter(link => link._id !== person._id);
        updateTeamProperty(team, "membership", update);
        const refresh = teams.filter(link => link._id !== team.id);
        setTeams(refresh);
    }

    const setPrimaryTeam = (team) => {
        console.log("set primary team", team);
        // remove current primary
        const list = [...teams];
        list.map(check => {
            const link = check.membership.find(ref => (ref._id === person._id && ref.type === "primary"));
            if (link) {
                link.type = "contributor";
                updateTeamProperty(check, "membership", check.membership);
            }
        });
        // set new primary
        const update = team.membership.find(link => link._id === person._id);
        update.type = "primary";
        updateTeamProperty(team, "membership", team.membership);
        // trigger refresh of screen
        setTeams(list);
    }

    const resetSelector = () => {
        setSelector(null);
    }

    const selectCapacity = (e) => {
        e.stopPropagation();
        onOpen();
    }

    const links = {};
    teams?.map(team => {
        const link = team.membership.find(ref => ref._id === person._id);
        if (link) {
            if (!links[link.type]) links[link.type] = [];
            links[link.type].push({team: team, link: link});
        }
    })
    const teamType = links.primary ? "contributor" : "primary";

    return (
        <VStack spacing={4} w="full">
            <VStack w="full" align={"left"}>
                <HStack mb={2}>
                    <Heading fontSize="md">Teams</Heading>
                    <Button size="xs" leftIcon={<RiAddCircleLine />} fontWeight="normal" variant="link" onClick={ () => setSelector({type: teamType, reftype: "primary"})}>
                        join a team
                    </Button>
                </HStack>
                {links.primary?.map(item =>
                    <TeamSticker item={item.team} key={"team" + item.team._id} size={4} element={<RoleListing team={item.team} person={person} type="membership" />}>
                        <CapacityBadge person={person} teamLink={item.link} onClick={selectCapacity} />
                        <VStack>
                            <Icon as={RiStarFill} onClick={ (e) => { setPrimaryTeam(item.team); e.stopPropagation(); } }/>
                            <IconButton isRound icon={<RiCloseLine />} size="xs" variant="link" onClick={ (e) => { removeTeam(item.team); e.stopPropagation(); } } />
                        </VStack>
                    </TeamSticker>
                )}
                <Wrap spacing={2} overflow="visible" w="full">
                    {links.contributor?.map(item =>
                        <TeamSticker item={item.team} key={"team" + item.team._id} size={3} element={<RoleListing team={item.team} person={person} type="membership" />}>
                            <CapacityBadge person={person} teamLink={item.link} onClick={selectCapacity} />
                            <VStack>
                                <IconButton isRound icon={<RiStarSLine />} size="xs" variant="link" onClick={ (e) => { setPrimaryTeam(item.team); e.stopPropagation(); } } />
                                <IconButton isRound icon={<RiCloseLine />} size="xs" variant="link" onClick={ (e) => { removeTeam(item.team); e.stopPropagation(); } } />
                            </VStack>
                        </TeamSticker>
                    )}
                </Wrap>
            </VStack>
            <Divider />
            <VStack w="full" align={"left"}>
                <HStack mb={2}>
                    <Heading fontSize="md">Workgroups</Heading>
                    <VStack spacing={0}>
                        <Button size="xs" leftIcon={<RiAddCircleLine />} fontWeight="normal" variant="link" onClick={ () => setSelector({type: "workgroup", reftype: "workgroup"})}>
                            join a workgroup
                        </Button>
                    </VStack>
                </HStack>
                {links.workgroup &&
                    <Wrap spacing={2} overflow="visible" w="full">
                        {links.workgroup.map(item =>
                            <TeamSticker item={item.team} key={"team" + item.team._id} size={2.5}>
                                <CapacityBadge person={person} teamLink={item.link} onClick={selectCapacity} />
                                <IconButton isRound icon={<RiCloseLine />} size="xs" variant="solid" onClick={ (e) => { removeTeam(item.team); e.stopPropagation(); } } />
                            </TeamSticker>
                        )}
                    </Wrap>
                }
            </VStack>
            <Divider />
            <VStack w="full" align={"left"}>
                <HStack mb={2}>
                    <Heading fontSize="md">Communities</Heading>
                    <VStack spacing={0}>
                        <Button size="xs" leftIcon={<RiAddCircleLine />} fontWeight="normal" variant="link" onClick={ () => setSelector({type: "community", reftype: "community"})}>
                            join a community
                        </Button>
                    </VStack>
                </HStack>
                {links.community &&
                    <Wrap spacing={2} overflow="visible" w="full">
                        {links.community.map(item =>
                            <TeamSticker item={item.team} key={"team" + item.team._id} size={2}>
                                <IconButton isRound icon={<RiCloseLine />} size="xs" variant="solid" onClick={ (e) => { removeTeam(item.team); e.stopPropagation(); } } />
                            </TeamSticker>
                        )}
                    </Wrap>
                }
            </VStack>
            { selector &&
                <Modal isOpen={selector} onClose={resetSelector}>
                    <ModalOverlay />
                    <ModalContent color="black">
                        <ModalHeader>Select a Team...</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <TeamSelector onSelect={(team) => addTeam(team)} type={ selector.reftype } />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
            { teams &&
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent color="black">
                        <ModalHeader>Specify Capacity Distribution</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <PersonCapacity teams={teams} onClose={onClose} />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </VStack>
    )
}

export default PersonMemberships;