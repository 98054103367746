import React from "react";
import PropTypes from "prop-types";
import { Box, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, Tooltip, useDisclosure, VStack } from "@chakra-ui/react";

const TagLevel = ({ level = 0, color, onLevelChange }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();

    const onSelect = (level) => {
        if (onLevelChange) onLevelChange(level);
    }

    if (level > 3) level = 3;
    if (level < 0) level = 0;
    const levels = ["Unknown", "Basic", "Advanced", "Master"]
    const gray = "#eee";

    return (
        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} gutter={1} autoFocus closeOnEsc closeOnBlur placement='top-start'>
            <PopoverTrigger>
                <Box cursor="pointer" verticalAlign="middle">
                    { level > 0 &&
                        <Tooltip hasArrow label={levels[level] + " Level"} fontSize="xs" bg={color} placement="top">
                            <svg width="10" height="11">
                                <rect x="0" y="0" width="10" height="3" fill={level > 2 ? color : gray} />
                                <rect x="0" y="4" width="10" height="3" fill={level > 1 ? color : gray} />
                                <rect x="0" y="8" width="10" height="3" fill={level > 0 ? color : gray} />
                            </svg>
                        </Tooltip>
                    }
                    { level <= 0 &&
                        <Tooltip hasArrow label={levels[level] + " Level"} fontSize="xs" bg={color} placement="top">
                            <svg width="10" height="11">
                                <rect x="0" y="0" width="10" height="11" fill={level > 2 ? color : gray} />
                            </svg>
                        </Tooltip>
                    }
                </Box>
            </PopoverTrigger>
            <PopoverContent _focus={{ outline: "none" }} width="auto" borderRadius={0}>
                <PopoverBody p={1}>
                    <VStack spacing={0} alignItems="start">
                        <Text fontSize="xs" pl={1} w="full" cursor="pointer" _hover={{ bgColor: "gray.200" }} onClick={() => onSelect(3)}>Master</Text>
                        <Text fontSize="xs" pl={1} w="full" cursor="pointer" _hover={{ bgColor: "gray.200" }} onClick={() => onSelect(2)}>Advanced</Text>
                        <Text fontSize="xs" pl={1} w="full" cursor="pointer" _hover={{ bgColor: "gray.200" }} onClick={() => onSelect(1)}>Basic</Text>
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )

}

TagLevel.propTypes = {
    level: PropTypes.number,
    color: PropTypes.string,
    onLevelChange: PropTypes.func
}

export default TagLevel;