import React, { useEffect, useState } from 'react';
import { useAPI } from '../../hooks/APIService.js';
import PropTypes from 'prop-types';
import PersonSticker from './PersonSticker.js';
import TeamSticker from './TeamSticker.js';
import ProjectSticker from './ProjectSticker.js';
import { Spinner } from '@chakra-ui/react';

const StickerLoader = ({ id, size = 4, element, children }) => {
    const [item, setItem] = useState();
    const { getItemById } = useAPI();

    useEffect(() => {
        loadItem();
    }, [])

    const loadItem = async() => {
        const item = await getItemById(id);
        setItem(item);
    }

    if (!item) return <Spinner />;
    if (item.class === "team") return <TeamSticker item={item} size={size} element={element}>{children}</TeamSticker> 
    else if (item.class === "person") return <PersonSticker item={item} size={size} element={element}>{children}</PersonSticker>
    else if (item.class === "project") return <ProjectSticker item={item} size={size} element={element}>{children}</ProjectSticker>
}

StickerLoader.propTypes = {
    id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
    size: PropTypes.number,
    onClick: PropTypes.func,
    element: PropTypes.node,
    children: PropTypes.node
}

export default StickerLoader;