import React from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Spinner from "../spinner/Spinner.js";

const ProtectedRoute = ({ component, ...args }) => {
    const showSpinner = () => {
        return <Spinner />
    }
    //args.onRedirecting = showSpinner;
    const Component = withAuthenticationRequired(component, {args, onRedirecting: showSpinner});
    return <Component />;
};

ProtectedRoute.propTypes = {
    component: PropTypes.func,
    args: PropTypes.object
}

export default ProtectedRoute;