import React from 'react';
import PropTypes from 'prop-types';
import PersonEmblem from '../emblem/PersonEmblem.js';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { getItemColor } from '../../theme.js';
import { useNavigate } from 'react-router-dom';

const PersonSticker = ({ item, size, element, children }) => {
    const navigate = useNavigate();

    const openItem = () => {
        navigate('/' + item.class + '/' + item._id);
    }

    const color = getItemColor(item);

    return (
        <HStack w={size + 15 + "rem"} p={"0.2rem"} onClick={openItem} boxShadow="0 0 3px 3px #ccc" cursor="pointer" bg="white" borderColor={color} borderWidth={2} borderRadius={size * 10}>
            <PersonEmblem item={item} displayName={false} size={size} isLinked={false} />
            <VStack w="full" align={"left"} verticalAlign={"center"} spacing={0}>
                <Text fontSize="xs" fontWeight={"bold"} noOfLines={1}>{item.name}</Text>
                {element}
            </VStack>
            {children}
        </HStack>
    )
}

PersonSticker.propTypes = {
    item: PropTypes.object.isRequired,
    size: PropTypes.number,
    element: PropTypes.node,
    children: PropTypes.node
}

export default PersonSticker;