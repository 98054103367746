import React, { useEffect, useState, createContext } from 'react';
import { useParams } from 'react-router';
import { useAPI } from '../../hooks/APIService';
import Spinner from '../../components/spinner/Spinner.js';
import PersonMemberships from './PersonMemberships.js';
import Card from '../../components/card/Card';
import PersonProfile from './PersonProfile';
import { HStack } from '@chakra-ui/layout';
import PersonLine from './PersonLine';
import PersonInterests from './PersonInterests';
import PersonObjectives from './PersonObjectives';
import PersonTalents from './PersonTalents';
import { getItemColor } from '../../theme';
import PersonCardHeader from './PersonCardHeader';
import { useAuth0 } from '@auth0/auth0-react';
import PersonLeadership from './PersonLeadership';

export const PersonContext = createContext();

const Person = () => {
    const [person, setPerson] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(false);
    const [isCurrentUser, setIsCurrentUser] = useState(false);
    const params = useParams();
    const api = useAPI();
    const auth0 = useAuth0();

    useEffect(() => {
        loadPerson();
    }, [params]);

    const loadPerson = async() =>  {
        console.log("load person", params.id);
        const person = await api.getPersonById(params.id);
        setPerson(person);
        const userId = auth0.user.sub.split("|", 2)[1];
        setCurrentUserId(userId);
        setIsCurrentUser(person._id === userId);
        console.log("person", person);
    }

    const updatePersonProperty = (property, value) => {
        console.log("update person property", person, property, value);
        var map = {};
        map[property] = value;
        api.patchPerson(person._id, map);
        person[property] = value;
        setPerson(person);
    }

    const deletePerson = () => {
        console.log("delete person", person);
        api.deletePerson(person._id);
        setPerson(null);
    }

    if (!person) {
        return <Spinner />
    }

    return (
        <HStack w="full" h="full" gap={"5%"} pl={5} pr={5} justify="space-around" align="center"
            bgImage="url('/background.svg')" bgSize="cover" bgPosition="center" >
            <PersonContext.Provider value={{ person, setPerson, updatePersonProperty, isCurrentUser, currentUserId, deletePerson }}>
                <Card color={getItemColor(person)} key={person._id}>
                    <PersonCardHeader />
                    <PersonProfile />
                    <PersonLine />
                    <PersonLeadership />
                    <PersonTalents />
                    <PersonInterests />
                    <PersonObjectives />
                </Card>
                <PersonMemberships key={person._id + "x"} />
            </PersonContext.Provider>
        </HStack>
    )
}

export default Person;