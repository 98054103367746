import React from 'react';
import PropTypes from 'prop-types';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import ProjectEmblem from '../emblem/ProjectEmblem.js';

const ProjectListItem = ({ item, size, onClick }) => {
    //if (!key) key=item._id;
    return (
        <HStack w="full" onClick={ onClick } cursor="pointer" textAlign="center">
            <ProjectEmblem item={item} displayName={false} size={size} isLinked={false} />
            <VStack>
                <Text>{item.name}</Text>
                <Text fontSize="xs">{item.client}</Text>
            </VStack>
        </HStack>
    )
}

ProjectListItem.propTypes = {
    item: PropTypes.object.isRequired,
    size: PropTypes.number,
    onClick: PropTypes.func
}

export default ProjectListItem;