import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useAPI } from '../../hooks/APIService';
import { Input, Text, useToast } from '@chakra-ui/react';
import { getTextColor } from '../../theme';

const EditableText = ({ item, property, textAlign = "left", useTheme = false, fontWeight = "normal", fontSize = "md", placeholder = "not specified" }) => {
    const { patchPerson, patchTeam, patchProject, patchRole } = useAPI();
    const [ isEdited, setEdited ] = useState(false);
    const [ value, setValue ] = useState(item[property]);
    const toast = useToast();

    const onEdit = () => {
        setEdited(true);
    }

    const onTextEnter = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    }

    const onTextBlur = (event) => {
        setEdited(false);
        if (event.target.value === value) return;
        var map = {};
        map[property] = event.target.value;
        switch (item.class) {
            case "person": patchPerson(item._id, map); break;
            case "team": patchTeam(item._id, map); break;
            case "project": patchProject(item._id, map); break;
            case "role": patchRole(item._id, map); break;
            default:
                toast({
                    title: 'Invalid object class ' + item.class + '.',
                    description: property.charAt(0).toUpperCase() + property.slice(1) + " updated failed.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
        }
        toast({
            title: 'Saved changes.',
            description: property.charAt(0).toUpperCase() + property.slice(1) + " updated successfully.",
            status: 'success',
            duration: 5000,
            isClosable: true,
        })
        setValue(event.target.value);
    }

    const borderColor = useTheme ? getTextColor(item) : "black";

    if (!isEdited) {
        const color = value ? null : "gray.300";
        const italic = value ? "normal" : "italic";
        return (
            <Text onClick={onEdit} textAlign={textAlign}
                variant="flushed" focusBorderColor={borderColor} bgColor="transparent"
                fontWeight={fontWeight} size="xs" fontSize={fontSize} borderBottom="none"
                width="90%" pt={0} color={color} lineHeight="1rem" fontStyle={italic}>
                    {value ? value : placeholder}
            </Text>
        )
    }
    else {
        return <Input autoFocus defaultValue={value} placeholder={placeholder} textAlign={textAlign}
            variant="flushed" focusBorderColor={borderColor} bgColor="transparent"
            fontWeight={fontWeight} size="xs" fontSize={fontSize} borderBottom="none"
            onKeyDown={onTextEnter} onBlur={onTextBlur} height="1rem" width="90%" pt={0} />
    }
}

EditableText.propTypes = {
    item: PropTypes.object.isRequired,
    property: PropTypes.string.isRequired,
    textAlign: PropTypes.string,
    useTheme: PropTypes.bool,
    fontWeight: PropTypes.string,
    placeholder: PropTypes.string,
    fontSize: PropTypes.string,
}

export default EditableText;