import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from "d3";
import { Box } from '@chakra-ui/react';

const OQScoreChart = ({ score, width = 200, height = 400 }) => {
    const canvasRef = React.createRef();

    useEffect(() => {
        drawReport()
    }, []);

    const drawReport = () => {
        const margin = {
            top: 10,
            bottom: 80,
            left: 30,
            right: 0
        }

        // create the SVG canvas
        const svg = d3.select(canvasRef.current).classed("report", true).attr("id", "OQScore");

        // create the display
        svg.selectAll("g").remove();
        const display = svg.append("g").classed("display", true).attr("transform", `translate(${margin.left}, ${margin.top})`);
        const chartWidth = width - margin.left - margin.right;
        const chartHeight = height - margin.top - margin.bottom;

        const xScale = d3.scaleBand()
            .domain(["Overall OQ Score"])
            .range([0, chartWidth])
            .padding(0.25);
        const yScale = d3.scaleLinear()
            .domain([0, 200])
            .range([chartHeight, 0]);
        const colorScale = d3.scaleLinear()
            .domain([0,100,200])
            .range(["#4B707A", "#99BCA8", "#F9D663"])


        const yGridlines = d3.axisLeft().ticks(2).scale(yScale).tickSizeInner(-chartWidth-5).tickSizeOuter(0).tickFormat("");
        display.append("g").classed("gridline", true).attr('transform', `translate(-5,0)`).call(yGridlines).attr("color", "#cccccc");

        const xAxis = d3.axisBottom().scale(xScale).tickSizeOuter(0);
        display.append("g").classed("x axis", true).attr('transform', `translate(0,${chartHeight})`).call(xAxis)
            .selectAll("text")
                .attr("transform", "translate(-10,0) rotate(-45)")
                .style("text-anchor", "end")

        const yAxis = d3.axisLeft().ticks(5).scale(yScale).tickSizeOuter(0);
        display.append("g").classed("y axis", true).attr('transform', `translate(-5,0)`).call(yAxis);

        // draw reference line for overall score
        const vertLineYCoord = yScale(score);
        display.append('g').classed("referenceline", true).append('line')
            .attr('x1', 0)
            .attr('y1', vertLineYCoord)
            .attr('x2', width)
            .attr('y2', vertLineYCoord)
            .style("stroke-width", 1)
            .style("stroke-dasharray","5,5")
            .style("stroke", "#F38F22")

            /*
        display.select('.x.axis')
            .append('text')
            .attr('x', chartWidth/2)
            .attr('y', 40)
            .attr('fill', '#000')
            .style('font-size', '1.2em')
            .style('text-anchor', 'middle')
            .text(xLabel);
        display.select('.y.axis')
            .append('text')
            .attr('x', 0)
            .attr('y', 0)
            .attr('transform', `translate(-30, ${chartHeight/2}) rotate(-90)`)
            .attr('fill', '#000')
            //.style('font-size', '1.2em')
            //.style('text-anchor', 'middle')
            //.text(yLabel);
        */

        display.append("g").classed("bar", true).append("rect")
            .attr("x", chartWidth * 0.15)
            .attr("y", yScale(0))
            .attr("width", chartWidth * 0.7)
            .attr("height", chartHeight - yScale(0))
            .attr("fill", colorScale(score));

        // Animation
        display.selectAll("rect").transition()
            .duration(800)
            .attr("y", yScale(score))
            .attr("height", chartHeight - yScale(score))
            .delay((d,i) => { return(i*100) })

        /*
        display.selectAll(".bar").append("rect").classed("bar", true)
            .attr("x", chartWidth * 0.15)
            .attr("y", yScale(score))
            .attr("width", chartWidth * 0.7)
            .attr("height", chartHeight)
            .attr("fill", "black")
            //.on("click", (event, d) => showItems(d.items) )
            //.style("cursor", "pointer");
            */

        display.append("g").classed("bar-label", true).append("text")
            .text(score)
            .attr("x", chartWidth * 0.5)
            .attr("dx", 0)
            .attr("y", yScale(score))
            .attr("dy", -5)
            .style('font-size', '1em')
            .style('text-anchor', 'middle');
    }

    return <Box><svg ref={canvasRef} width={width} height={height} /></Box>

}

OQScoreChart.propTypes = {
    score: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
}

export default OQScoreChart;