import React from 'react';
import Logo from '../../logo.svg';
import { Flex, Heading, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { PropTypes } from 'prop-types';
import { getIndexedColor, getIndexedTextColor } from '../../theme';
import { useConfig } from '../../hooks/ConfigService';

const BannerDisplay = ({ size = 6 }) => {
    const [config, isLoading, error] = useConfig();
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

    const col_index = Math.floor(Math.random() * 8);
    const color = getIndexedColor(col_index);
    const textColor = getIndexedTextColor(col_index);

    return(
        <Flex direction="column" h="full" w="full" alignItems="center" justifyContent="space-evenly"
            color={textColor} bg={color} borderColor="white" borderWidth="0.25rem" boxShadow="0 0 10px 0px rgba(0,0,0,0.25)">
            { (isLoading || error || !config?.image) &&
                <>
                    <Heading fontSize={size*1.8}>YOUR COMPANY</Heading>
                    <Image src={Logo} alt="LEAGUE OF TEAMS Logo" h={size * 0.6 + "rem"} w={size * 0.6 + "rem"} />
                </>
            }
            { config?.image &&
                <Image src={IMAGE_URL + config?.image} alt="Company Logo" p="1rem" bg="white" />
            }
            <Text fontSize={size*1}>A LEAGUE OF TEAMS</Text>
        </Flex>
    )
}

BannerDisplay.propTypes = {
    size: PropTypes.number
}

export default BannerDisplay;