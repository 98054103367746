import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { getCssColor, getItemColor, getTextColor } from '../../theme';
import { Box, Center, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import RoundedBox from '../shapes/RoundedBox';

const TeamMiniCard = ({ item, size = 6, children, onClick, isLinked = true }) => {
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
    const navigate = useNavigate();

    const openItem = () => {
        navigate('/' + item.class + '/' + item._id);
    }

    const color = getItemColor(item);
    const textColor = getTextColor(item);

    return <Box position="relative" cursor="pointer" onClick={ () => { if (onClick) onClick(item); else if (isLinked) openItem(); } }>
        { item &&
            <RoundedBox size={size} radius="15%" color={color} cssColor={getCssColor(item)} thickness={size * 0.05} >
                { item.picture &&
                    <Image src={IMAGE_URL + item.picture} w="full" h="full" borderRadius="10%"/>
                }
                { !item.picture &&
                    <Center h="full" w="full" bg={color} borderRadius="10%">
                        <Text fontSize={(size / 8) + "rem"} color={textColor} textAlign="center">{item.name}</Text>
                    </Center>
                }
            </RoundedBox>
        }
        {children}
    </Box>
}

TeamMiniCard.propTypes = {
    item: PropTypes.object.isRequired,
    size: PropTypes.number,
    onClick: PropTypes.func,
    isLinked: PropTypes.bool,
    children: PropTypes.node
}

export default TeamMiniCard;