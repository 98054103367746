export class OQDimension {
    name;
    parameters = [];

    constructor(_name) {
        this.name = _name;
    }

    addParameter = (parameter) => {
        this.parameters.push(parameter);
    }

    getChoiceCountBySentiment = (sentiment) => {
        var count = 0;
        for (var i = 0; i < this.parameters.length; i++) {
            count += this.parameters[i].getChoiceCountBySentiment(sentiment);
        }
        return count;
    }
}

export class OQDimParameter {
    dimension;
    id;
    name;
    options = [];

    constructor(dimension, _id, _name) {
        this.dimension = dimension;
        this.id = _id;
        this.name = _name;
        this.dimension.addParameter(this);
    }

    addOption = (option) => {
        this.options.push(option)
    }

    // find the intersecting option for the other dimension specified
    findDimensionOption = (other) => {
        for (var i = 0; i < this.options.length; ++i) {
            if (this.dimension.name === "Domain" && this.options[i].valueParam === other) return this.options[i];
            if (this.dimension.name === "Value" && this.options[i].domainParam === other) return this.options[i];
        }
    }

    // return th number of trees
    getTreeCount = () => {
        const trees = [];
        for (var i = 0; i < this.options.length; ++i) {
            const option = this.options[i];
            if (!trees.includes(option.tree)) {
                trees.push(option.tree);
            }
        }
        return trees.length;
    }

    // return the average score for the dimension options
    getScore = () => {
        var score = 0;
        for (var i = 0; i < this.options.length; ++i) {
            score += this.options[i].getScore();
        }
        return score / this.options.length;
    }

    // return the average score for the dimension options
    getOQScore = () => {
        const score = (this.getScore() + 1) * 100;
        return Math.round(score);
    }

    // get the average rating for the dimension options
    getRating = (reference) => {
        var sum = 0;
        for (var i = 0; i < this.options.length; i++) {
            if (this.options[i] !== reference) sum += this.options[i].getRating(reference);
        }
        return sum / (this.options.length - 1);
    }

    getChoiceCountBySentiment = (sentiment) => {
        var count = 0;
        for (var i = 0; i < this.options.length; i++) {
            count += this.options[i].getChoiceCountBySentiment(sentiment);
        }
        return count;
    }

}

export class OQScore {
    options = [];
    answers = [];
    maxAnswers = 40;
    expectedAnswers = 30;
    offsetAnswers = 0;
    treeCount = -1;

    addOption = (option) => {
        this.options.push(option);
    }

    getActualAnswerCount = () => {
        return this.answers.length - this.offsetAnswers;
    }

    getExpectedAnswerCount = () => {
        return this.expectedAnswers - this.offsetAnswers;
    }

    addAnswer = (answer) => {
        this.answers.push(answer);
        answer.best.option.addAnswer(answer);
        answer.neutral.option.addAnswer(answer);
        answer.least.option.addAnswer(answer);
    }

    addAnswers = (answers) => {
        console.log("add answers", answers);
        for (var i = 0; i < answers.length; i++) {
            const best = this.findStatementById(answers[i].best);
            const neutral = this.findStatementById(answers[i].neutral);
            const least = this.findStatementById(answers[i].least);
            const answer = new OQAnswer(best, neutral, least);
            if (best && neutral && least) this.addAnswer(answer);
            else console.log("ERROR: statement not found for answer", answers[i], best, neutral, least);
        }
    }

    reset = () => {
        console.log("reset score");
        for (var i = 0; i < this.options.length; i++) {
            this.options[i].reset();
        }
        this.answers = [];
        this.expectedAnswers = 30;
        this.offsetAnswers = 0;
        this.updateScore();
    }

    enhance = (count) => {
        console.log("enhance answers", count);
        const remove = this.answers.length + count - this.maxAnswers;
        if (remove > 0) {
            for (var i = 0; i < this.options.length; i++) {
                this.options[i].reset();
            }
            const remaining = this.answers.slice(remove);
            console.log("remaining answers", remaining.length);
            this.answers = [];
            for (i = 0; i < remaining.length; i++) {
                this.addAnswer(remaining[i]);
            }
        }
        this.offsetAnswers = this.answers.length;
        this.expectedAnswers = this.offsetAnswers + count;
        console.log("new offset", this.offsetAnswers);
        console.log("new expected answers", this.expectedAnswers);
        console.log("actual answers", this.answers.length);
        this.updateScore();
    }

    findStatementById = (id) => {
        for (var i = 0; i < this.options.length; i++) {
            for (var j = 0; j < this.options[i].statements.length; j++) {
                if (this.options[i].statements[j].id === id) return this.options[i].statements[j];
            }
        }
        console.log("statement not found", id);
        return null;
    }

    updateScore = () => {
        this.rebuildTrees();
        this.sortTreeByLinks();
        this.updateRawScores();
        this.calculateScores();
        this.sortTreeByScore();
    }

    getOQScore = () => {
        var score = 0;
        for (var i = 0; i < this.options.length; i++) {
            score += this.options[i].score;
        }
        score = ((score / this.options.length) + 1) * 100;
        return Math.round(score);
    }

    // build the trees from the links
    rebuildTrees = () => {
        for (var i = 0; i < this.options.length; i++) {
            this.options[i].tree = -1;
        }
        var tree = 0;
        var option = this.findNextLooseOption();
        while (option !== null) {
            this.addOptionToTree(option, tree);
            option = this.findNextLooseOption();
            tree++;
        }
        this.treeCount = tree;
    }

    // find an option without tree
    findNextLooseOption = () => {
        for (var i = 0; i < this.options.length; i++) {
            if (this.options[i].tree === -1) return this.options[i];
        }
        return null;
    }

    // add the option to the specified tree
    addOptionToTree = (option, tree) => {
        // if already added, return
        if (option.tree === tree) return;
        option.tree = tree;
        // add all links to the same tree
        for (var i = 0; i < option.better.length; i++) {
            if (option.better[i].tree !== tree) this.addOptionToTree(option.better[i], tree);
        }
        for (i = 0; i < option.worse.length; i++) {
            if (option.worse[i].tree !== tree) this.addOptionToTree(option.worse[i], tree);
        }
    }

    // sort options based on links (better before worse)
    sortTreeByLinks = () => {
        const sortedArray = [];
        // run through all options
        for (var i = 0; i < this.options.length; i++) {
            const option = this.options[i];
            // check if option is already added and add if not
            var index = sortedArray.indexOf(option);
            if (index < 0) sortedArray.push(option);
            // run through all better options and make sure they are listed before the current option
            for (var j = 0; j < option.better.length; j++) {
                const better = option.better[j];
                // check if better is not contained in worse
                index = option.worse.indexOf(better);
                if (index < 0) {
                    // get actual position of option
                    const actual = sortedArray.indexOf(option);
                    // check where better is located
                    index = sortedArray.indexOf(better);
                    // if located behind actual remove from current position
                    if (index > actual) sortedArray.splice(index, 1);
                    // add before current option, if not added or located behind
                    if (index < 0 || index > actual) {
                        sortedArray.splice(actual, 0, better);
                    }
                }
            }
            // run through all worse options and make sure they are listed behind the current option
            for (j = 0; j < option.worse.length; j++) {
                const worse = option.worse[j];
                // check if worse is not contained in better
                index = option.better.indexOf(worse);
                if (index < 0) {
                    // get actual position of option
                    const actual = sortedArray.indexOf(option);
                    // check if worse is already added
                    index = sortedArray.indexOf(worse);
                    // if located before actual remove from current position
                    if (index != -1 && index < actual) sortedArray.splice(index, 1);
                    // add after current option, if not added or located befor
                    if (index < 0 || index < actual) {
                        sortedArray.splice(actual + 1, 0, worse);
                    }
                }
            }
        }

        // transfer sorted array to options array
        for (i = 0; i < this.options.length; i++) {
            this.options[i] = sortedArray[i];
        }
    }

    updateRawScores = () => {
        for (var i = 0; i < this.options.length; i++) {
            this.options[i].updateRawScore();
        }
    }

    // update the scores of the options
    calculateScores = () => {
        for (var i = 0; i < this.options.length; i++) {
            this.options[i].score = this.options[i].rawScore;
        }

        const minDist = 0.05;
        const inc = 0.1;

        var totalForce = 1;

        console.log("calculate scores");
        for (var a = 0; a < 1000 && totalForce > 0.01; a++) {
            totalForce = 0;

            for (i = 0; i < this.options.length; i++) {
                const option = this.options[i];
                var force = (option.rawScore - option.score);
                if (option.answers.length > 0) force *= (option.getChoiceCount() / option.answers.length);
                for (var j = 0; j < option.better.length; j++) {
                    const dist = option.better[j].score - option.score;
                    if (dist < minDist) force += dist - minDist;
                }
                for (j = 0; j < option.worse.length; j++) {
                    const dist = option.worse[j].score - option.score;
                    if (dist > minDist) force += dist + minDist;
                }
                force /= option.better.length + option.worse.length + 1; 
                totalForce += Math.abs(force);
                option.force = force;
            }

            for (i = 0; i < this.options.length; i++) {
                this.options[i].score += this.options[i].force * inc;
                if (this.options[i].score > 1) this.options[i].score = 1;
                if (this.options[i].score < -1) this.options[i].score = -1;
            }

        }

        for (i = 0; i < this.options.length; i++) {
            var option = this.options[i];
            var error = 0;
            for (j = 0; j < option.better.length; j++) {
                if (option.better[j].score < option.score) {
                    const index = option.worse.indexOf(option.better[j]);
                    if (index < 0) error += option.score - option.better[j].score;
                }
            }
            for (j = 0; j < option.worse.length; j++) {
                if (option.worse[j].score > option.score) {
                    const index = option.better.indexOf(option.worse[j]);
                    if (index < 0) error += option.worse[j].score - option.score;
                }
            }
            option.error = error;
        }

        console.log("score update inc", a, totalForce);

    }

    findNextUndefinedScore = () => {
        // find items with defined better and worse scores
        for (var i = 0; i < this.options.length; i++) {
            const test = this.options[i];
            if (test.score === undefined) {
                var isUndefined = false;
                for (var j = 0; j < test.better.length && !isUndefined; j++) {
                    if (test.better[j].score === undefined) isUndefined = true;
                }
                for (j = 0; j < test.worse.length && !isUndefined; j++) {
                    if (test.worse[j].score === undefined) isUndefined = true; 
                }
                if (!isUndefined) {
                    console.log("next undefined filled", test.id);
                    return test;
                }
            }
        }
        
        // find next items from all options
        for (i = 0; i < this.options.length; i++) {
            if (this.options[i].score === undefined) {
                console.log("next undefined any", this.options[i].id);
                return this.options[i];
            }
        }

        return null;
    }

    sortTreeByScore = () => {        
        for (var i = 1; i < this.options.length; i++) {
            var compare = i;
            while (compare > 0) {
                const index = this.options[i].better.indexOf(this.options[compare - 1]);
                const index2 = this.options[i].worse.indexOf(this.options[compare - 1]);
                if ((index >= 0 && index2 == -1) || this.options[i].score < this.options[compare - 1].score) {
                    break;
                }  
                compare--;
            }
            if (compare < i) {
                const removed = this.options.splice(i, 1);
                this.options.splice(compare, 0, removed[0]);
            }
        }
    }

    getChoiceCountBySentiment = (sentiment) => {
        var count = 0;
        for (var i = 0; i < this.options.length; i++) {
            count += this.options[i].getChoiceCountBySentiment(sentiment);
        }
        return count;
    }

    getAnswersCount = () => {
        return this.answers.length;
    }

    getError = () => {
        var error = 0;
        for (var i = 0; i < this.options.length; i++) {
            error += this.options[i].error;
        }
        return error / this.options.length;
    }
    
    getDBScore = () => {
        var dimScore = [];
        var optScore = [];
        var dbscore = {
            score: this.getOQScore(),
            dimensions: dimScore,
            options: optScore
        }

        var valueScore = [];
        for (var i = 0; i < this.options[0].valueParam.dimension.parameters.length; i++) {
            const param = this.options[0].valueParam.dimension.parameters[i];
            valueScore.push({parameter: param.id, score: param.getOQScore()});
        }
        dimScore.push({dimension: this.options[0].valueParam.dimension.name, parameters: valueScore});

        var domainScore = [];
        for (i = 0; i < this.options[0].domainParam.dimension.parameters.length; i++) {
            const param = this.options[0].domainParam.dimension.parameters[i];
            domainScore.push({parameter: param.id, score: param.getOQScore()});
        }
        dimScore.push({dimension: this.options[0].domainParam.dimension.name, parameters: domainScore});

        for (i = 0; i < this.options.length; i++) {
            const option = this.options[i];
            optScore.push({value: option.valueParam.id, domain: option.domainParam.id, score: option.getOQScore()});
        }
        return dbscore;
    }

}

export class OQOption {
    id;
    domainParam;
    valueParam;
    statements = [];

    answers = [];
    better = [];
    worse = [];
    rawScore = 0;
    score = 0;
    force = 0;
    error = 0;
    tree = -1;

    constructor(_id, _domainParam, _valueParam) {
        this.id = _id;
        this.domainParam = _domainParam;
        this.valueParam = _valueParam;
        this.domainParam.addOption(this);
        this.valueParam.addOption(this);
    } 

    addStatement = (statement) => {
        this.statements.push(statement)
    }

    reset = () => {
        this.answers= [];
        this.better = [];
        this.worse = [];
        this.rawScore = 0;
        this.score = 0;
        this.force = 0;
        this.error = 0;
        this.tree = -1;
    }

    addAnswer = (answer) => {
        this.answers.push(answer);
        if (answer.best.option === this) {
            if (answer.best.positive) {
                this.addWorseOption(answer.neutral.option);
                this.addWorseOption(answer.least.option);
                if (!answer.least.positive) this.addBetterOption(answer.least.option, true);
            }
            else {
                this.addBetterOption(answer.neutral.option);
                this.addBetterOption(answer.least.option);
                if (answer.least.positive) this.addWorseOption(answer.least.option, true);
            }
        }
        else if (answer.least.option === this) {
            if (answer.least.positive) {
                this.addBetterOption(answer.neutral.option);
                this.addBetterOption(answer.best.option);
                if (!answer.best.positive) this.addWorseOption(answer.best.option, true);
            }
            else {
                this.addWorseOption(answer.neutral.option);
                this.addWorseOption(answer.best.option);
                if (answer.best.positive) this.addBetterOption(answer.best.option, true);
            }
        }
        else if (answer.neutral.option === this) {
            if (answer.best.positive) this.addBetterOption(answer.best.option);
            else this.addWorseOption(answer.best.option);
            if (answer.least.positive) this.addWorseOption(answer.least.option);
            else this.addBetterOption(answer.least.option);
        }
    }

    addBetterOption = (option, extra = false) => {
        console.log("add better option", this.id, option.id, extra)
        // if already existing, try to reduce
        const worseCount = this.worse.filter(x => x === option).length;
        const betterCount = this.better.filter(x => x === option).length;
        // remove if multiple worse or one worse and at least one better exists
        if (worseCount > 1 || (worseCount > 0 && betterCount > 0)) {
            const index = this.worse.indexOf(option);
            this.worse.splice(index, 1);
        }
        // otherwise add to better
        else {
            this.better.push(option);
        }
    }

    addWorseOption = (option, extra = false) => {
        console.log("add worse option", this.id, option.id, extra)
        // if already existing, try to reduce
        const betterCount = this.better.filter(x => x === option).length;
        const worseCount = this.worse.filter(x => x === option).length;
        // remove if multiple better or one better and at least one worse exists
        if (betterCount > 1 || (betterCount > 0 && worseCount > 0)) {
            const index = this.better.indexOf(option);
            this.better.splice(index, 1);
        }
        // otherwise add to worse
        else {
            this.worse.push(option);
        }
    }

    updateRawScore = () => {
        var posChoice = 0;
        var negChoice = 0;
        for (var i = 0; i < this.answers.length; i++) {
            if (this.answers[i].best.option === this) {
                if (this.answers[i].best.positive) posChoice++;
                else negChoice++;
            }
            else if (this.answers[i].least.option === this) {
                if (this.answers[i].least.positive) negChoice++;
                else posChoice++;
            }
        }
        if (posChoice === 0 && negChoice === 0) this.rawScore = 0;
        else this.rawScore = (posChoice - negChoice) / (posChoice + negChoice);
    }

    getScore = () => {
        return this.score;
    }

    getOQScore = () => {
        return Math.round((this.score + 1) * 100);
    }

    // return a rating for the options based on
    // - ensure every option is answered
    // - ensure different trees get connected
    // - try to resolve errors
    // - try to maximize answers for each option
    // - try to avoid options are offered together several times
    //
    // - number of answers (more is better)
    // - number of choices (more is better)
    // - bi-directional links (less significant)
    // - tree (same is better)
    // - error (more is worse)
    // for comprison [TODO]
    // - option not offered with other option before
    getRating = (reference) => {
        // skip, if same option
        if (reference === this) return -1;
        // skip, if no intersecting dimensions
        if (this.domainParam !== reference.domainParam && this.valueParam !== reference.valueParam) return -1;

        if (this.answers.length === 0 && reference.answers.length === 0) return 1;
        if (this.answers.length === 0) return 1 - reference.answers.length * 0.01;
        if (reference.answers.length === 0) return 1 - this.answers.length * 0.01;
        if (this.tree !== reference.tree) return 0.95;

        var rating = 0;

        // maximize rating for lower number of answers
        rating += 0.3 / (Math.abs(this.answers.length + reference.answers.length) + 1);

        // maximize rating for error reduction
        const index = this.better.indexOf(reference);
        if (index >= 0 && reference.score < this.score) {
            const error = this.score - reference.score;
            if (error > 0) rating += error / 2 * 0.3;
        }

        // maximize rating for variety of combinations
        var count = 0;
        for (var i = 0; i < this.answers.length; i++) {
            if (this.answers[i].containsOption(reference)) count++;
        }
        rating += 0.3 / (count + 1);

        return rating;

        /*
        // more answers means higher rating
        var rating = this.answers.length;

        // validate against reference
        if (reference) {
            // reduce if different tree
            if (this.tree !== reference.tree) rating /= 2;
            // reduce if combination already used
            var count = 0;
            for (i = 0; i < this.answers.length; i++) {
                if (this.answers[i].getOptionAnswer(reference)) count++;
            }
            rating /= count + 1;
        }

        // more choices increases rating
        rating += this.getChoiceCount();

        // reduce rating for bi-directional links
        for (var i = 0; i < this.better.length; i++) {
            if (this.worse.indexOf(this.better[i]) != -1) rating -= 0.5;
        }
        for (i = 0; i < this.worse.length; i++) {
            if (this.better.indexOf(this.worse[i]) != -1) rating -= 0.5;
        }

        // reduce rating in case of error
        if (this.error > 0) {
            rating /= 2;
            var diff = 0.5 - this.error;
            if (diff < 0) diff = 0;
            rating *= diff * 2;
        }

        return rating;
        */
    }

    // return number of choices in answers for this option
    getChoiceCount = () => {
        var count = 0;
        for (var i = 0; i < this.answers.length; i++) {
            if (this.answers[i].best.option === this || this.answers[i].least.option === this) count++
        }
        return count;
    }

    // return number of choices in answers for this option
    getChoiceCountBySentiment = (sentiment) => {
        var count = 0;
        for (var i = 0; i < this.answers.length; i++) {
            if (this.answers[i].best.option === this && this.answers[i].best.positive === sentiment) count++;
            else if (this.answers[i].least.option === this && this.answers[i].least.positive !== sentiment) count++;
        }
        return count;
    }
}

export class OQStatement {
    option;
    id;
    positive = true;
    text;
    description;
    answers = [];

    constructor(_option, _id, _positive, _text = undefined) {
        this.option = _option;
        this.id = _id;
        this.positive = _positive;
        this.description = _option.id + ": " + _option.domainParam.name + " / " + _option.valueParam.name + " / " + _positive;
        if (_text) this.text = _text;
        else this.text = this.description;
        this.option.addStatement(this);
    }

    addAnswer = (answer) => {
        this.answers.push(answer);
        this.option.addAnswer(answer);
    }

    getCount = () => {
        return this.answers.length;
    }
}

export class OQAnswer {
    best;
    neutral;
    least;

    constructor(_best, _neutral, _least) {
        this.best = _best;
        this.neutral = _neutral;
        this.least = _least;
    }

    containsOption = (option) => {
        return (this.best.option === option || this.neutral.option === option || this.least.option === option);
    }

    // return the information to be stored in the database for this option
    getDBObject = () => {
        return { best: this.best.id, neutral: this.neutral.id, least: this.least.id }
    }

}

export default OQDimension;