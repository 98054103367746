import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Divider, FormControl, FormLabel, Text } from '@chakra-ui/react';
import CapacityInput from './CapacityInput';
import { PersonContext } from './Person';
import TeamListItem from '../../components/listitem/TeamListItem';
import { useAPI } from '../../hooks/APIService';

const PersonCapacity = ({ teams, onClose }) => {
    const { person, updatePersonProperty } = useContext(PersonContext);
    const [ total, setTotal ] = useState(person.capacity ? person.capacity : 40);
    const { patchTeam } = useAPI();

    const handleSubmit = event => {
        event.preventDefault();
        updatePersonProperty("capacity", total);
        console.log("updated person capacity");
        teams.forEach(team => {
            updateTeamProperty(team, "membership", team.membership);            
        });
        console.log("updated team capacities");
        onClose();
    }

    const updateTeamProperty = (team, property, value) => {
        console.log("update team property", team, property, value);
        var map = {};
        map[property] = value;
        patchTeam(team._id, map);
        team[property] = value;
    }

    const handleTotalChange = (value) => {
        setTotal(value);
    }

    const handleChange = (index, value) => {
        const link = teams[index].membership.find((link) => link._id === person._id);
        link.capacity = value;
    }

    return <form name="addPersonForm" className="PersonForm Form" onSubmit={handleSubmit}>
        <FormControl id="total-input" mb="2" display="flex" alignItems="center">
            <FormLabel fontSize="xs" minW={48}>Available Capacity</FormLabel>
            <CapacityInput max={40} current={40} total={total} handleChange={(value) => handleTotalChange(value)} />
            <Text fontSize="xs" pl={2}>hours / week</Text>
        </FormControl>
        <Divider />
        { teams.map((team, index) => {
            if (team.type == "primary" || team.type == "workgroup") {
                const link = teams[index].membership.find((link) => link._id === person._id);
                if (!link.capacity) link.capacity = 0;
                return <FormControl id="name-input" mb="2" display="flex" key={"capacity_" + team._id} alignItems="center">
                    <FormLabel fontSize="xs" minW={48}><TeamListItem item={team} size={2} /></FormLabel>
                    <CapacityInput max={40} current={link.capacity} total={total} handleChange={(value) => handleChange(index, value)} />
                    <Text fontSize="xs" pl={2}>{ Math.round(link.capacity / total * 100) + "%"}</Text>
                </FormControl>
            }
        })}
        <ButtonGroup size="sm">
            <Button type="submit" >Save</Button>
            <Button type="button" onClick={onClose} variant="outline">Cancel</Button>
        </ButtonGroup>
    </form>

}

PersonCapacity.propTypes = {
    teams: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClose: PropTypes.func.isRequired
}

export default PersonCapacity;