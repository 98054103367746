import React, { useState } from 'react';
import { Alert, AlertDescription, AlertIcon, Box, Button, Center, FormControl, FormErrorMessage, FormHelperText, FormLabel, HStack, Heading, Image, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Stack, Text, useToast } from '@chakra-ui/react';
import { useAPI } from '../../hooks/APIService';
import { validateEmail } from './OQScannerUtilities';

const OQScannerLicenses = () => {
    const [ error, setError ] = useState();
    const [ submitted, setSubmitted ] = useState();
    const [ email, setEmail ] = useState("");
    const [ name, setName ] = useState("");
    const [ users, setUsers ] = useState(1);
    const { addLicense } = useAPI();
    const toast = useToast();

    const handleEmailChange = event => setEmail(event.target.value);
    const handleNameChange = event => setName(event.target.value);
    const handleUsersChange = users => setUsers(users);

    const checkEmail = () => {
        console.log("validate email", email);
        const isValid = validateEmail(email);
        if (!isValid) {
            setError({ email: "No valid email address" });
            return;
        }
        setError({ email: null });

        if (name.length < 1) {
            var defaultName;
            if (users > 1) defaultName = "Organization License: " + email;
            else defaultName = "Personal License: " + email;
            console.log("default name", defaultName);
            setName(defaultName);
        }
    }

    const createLicense = async event => {
        event.preventDefault();
        checkEmail();
        setSubmitted(true);
        if (!error || !error.email) {
            addLicense(email, {name: name, users: users}).then((result) => {
                toast({
                    title: 'Created License.',
                    description: "License created with access code: " + result.code,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                setEmail("");
                setName("");
                setUsers(1);
            })
            .catch(error => {
                toast({
                    title: 'Failed to Create License.',
                    description: "Error: " + error,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })    
            })
        }
    }

    return <Stack direction="column" align={"center"} width="100%">
        <Center width="80%" mt="2rem">
            <Stack spacing={"3rem"} width="100%" alignItems={'center'}>
                <Stack>
                    <Heading as="h1" size="3xl" fontWeight={"bold"}>OQ-Scanner</Heading>
                    <Heading as="h2" size="md">How intelligent is your organization?</Heading>
                </Stack>
                <Stack spacing={"2rem"}>
                    <Text>Please enter the license parameters</Text>
                    <Stack spacing={"5"}>
                        { submitted && error?.general &&
                            <Box my={4}>
                                <Alert status="error" borderRadius={4}>
                                    <AlertIcon />
                                    <AlertDescription>{error?.general}</AlertDescription>
                                </Alert>
                            </Box>
                        }
                        <FormControl id="email-input" mb="2" display="block" isRequired isInvalid={error?.email}>
                            <FormLabel fontSize="xs">Email (License Owner)</FormLabel>
                            <Input placeholder="License Owner's Email Address" aria-label="Email" size="sm" focusBorderColor="green.500" variant="flushed" value={email} onChange={handleEmailChange} onBlur={checkEmail} />
                            <FormHelperText>Please provide the email of the license owner.</FormHelperText>
                            <FormErrorMessage>{error?.email}</FormErrorMessage>
                        </FormControl>
                        <FormControl id="name-input" mb="2" display="block" isRequired isInvalid={error?.name}>
                            <FormLabel fontSize="xs">License Name</FormLabel>
                            <Input placeholder="License Name" aria-label="Name" size="sm" focusBorderColor="green.500" variant="flushed" value={name} onChange={handleNameChange} onBlur={checkEmail} />
                            <FormHelperText>Please provide a name for the license.</FormHelperText>
                            <FormErrorMessage>{error?.email}</FormErrorMessage>
                        </FormControl>
                        <FormControl id="users-input" mb="2" display="block" isRequired isInvalid={error?.users}>
                            <FormLabel fontSize="xs">Users</FormLabel>
                            <NumberInput value={users} aria-label="Users" size="sm" variant="flushed" maxW={32} min={1} max={1000} onChange={handleUsersChange}>
                                <NumberInputField focusBorderColor="green.500" />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                            <FormHelperText>Please provide the number of users associated with the license.</FormHelperText>
                            <FormErrorMessage>{error?.users}</FormErrorMessage>
                        </FormControl>
                        <Button onClick={createLicense}>Create License</Button>
                    </Stack>
                </Stack>
            </Stack>
        </Center>
        <HStack pt={3} pb={1}>
            <Text fontSize={'xs'}>powered by</Text>
            <a href="https://leapfroq.com/" target='_empty'><Image src='../leapfroq.png' w={100} /></a>
        </HStack>
    </Stack>
    
}

OQScannerLicenses.propTypes = {
}

export default OQScannerLicenses;