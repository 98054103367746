import React, { useEffect, useState, useRef } from 'react';
import { useAPI } from '../../hooks/APIService.js';
import { Grid, GridItem } from '@chakra-ui/layout';
import TeamDisplay from './TeamDisplay.js';
import PersonDisplay from './PersonDisplay.js';
import BannerDisplay from './BannerDisplay.js';
import { AnimatePresence, motion } from 'framer-motion';
import { Spinner } from '@chakra-ui/react';
import BoardItem from './BoardItem.js';
import ProjectDisplay from './ProjectDisplay.js';

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */

function shuffleArray(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export const GRID_SIZE = 8; // grid size measured in em
const DISPLAY_SIZE = 3;
const SPEED = 8; // seconds between auto updates

const Board = () => {
    const [dim, setDim] = useState({ rows: 1, cols: 1, count: 0 });
    const [data, setData] = useState({ items: null, current: null, full: true });
    const [mounted, setMounted] = useState(false);
    const { getRandomItems } = useAPI();
    const containerRef = useRef();

    var timerId;

    useEffect(() => {
        let started = true;
        computeBoardDimensions();
        if (!data.items || dim.count != data.items.length) loadItems();
        if (timerId) clearInterval(timerId);
        timerId = setInterval(() => { if (started) nextDisplay() }, SPEED * 1000);
        if (!mounted) setMounted(true);
        // on Unmount
        return () => {
            setMounted(false);
            started = false;
            clearInterval(timerId);
        }
    }, [dim, data])

    const computeBoardDimensions = () => {
        const containerRect = containerRef.current.getBoundingClientRect();
        const minGridSize = parseFloat(getComputedStyle(containerRef.current).fontSize) * GRID_SIZE;
        let cols = Math.floor(containerRect.width / minGridSize);
        const rows = Math.floor(containerRect.height / minGridSize);

        if ((cols - Math.ceil(DISPLAY_SIZE)) % 2) cols--;

        // determine the gap for the display
        var gap_col = Math.ceil(DISPLAY_SIZE);
        var gap_row = Math.ceil(DISPLAY_SIZE);
        if (cols < gap_col) gap_col = cols;
        if (rows < gap_row) gap_row = rows;
        // determine the number of items required to fill the board
        const count = (cols * rows) - (gap_col * gap_row);

        if (count != dim.count || cols != dim.cols || rows != dim.rows)
            setDim({ rows: rows, cols: cols, count: count });
    }

    const loadItems = () => {
        console.log("load items", dim.count);
        if (dim.count === 0) {
            setData({ items: [], current: null, full: true });
            return;
        }

        getRandomItems(dim.count).then(items => {
            const array = new Array(dim.count);
            for (var i = 0; i < items.length; i++) {
                array[i] = items[i];
            }
            shuffleArray(array);
            setData({ items: array, current: null, full: items.length === dim.count });
        }).catch(error => {
            console.log('ERROR: ' + error);
        });
    }

    const nextDisplay = () => {
        console.log("display next");
        // do nothing, as long as no items are displayed
        if (data.items.length === 0) return;
        const index = Math.floor(Math.random() * data.items.length);
        selectItem(index);
    }

    const onSelect = (item) => {
        console.log("select item", item);
        const index = data.items.findIndex((check) => ( check?._id === item._id ));
        selectItem(index);
    }

    const selectItem = (index) => {
        const next = data.items[index] ? { ...data.items[index] } : null;
        if (next === data.current) return;
        if (!data.full) {
            data.items[index] = data.current;
            setData({ items: data.items, current: next, full: false });
        }
        else {
            getRandomItems(1).then(items => {
                data.items[index] = items[0];
                setData({ items: data.items, current: next, full: true });
            }).catch(error => {
                console.log('ERROR: ' + error);
            });
        }
    }

    const gridItems = new Array(dim.count);
    for (let index = 0; index < dim.count; index++) {
        if (!data.items || data.items.length <= index) gridItems[index] = <Spinner color='gray.100' />
        else gridItems[index] = <BoardItem item={data.items[index]} size={GRID_SIZE} onSelect={onSelect} initial={mounted} />
    }

    return (
        <Grid ref={containerRef} w="full" h="full"
            alignItems="center" alignContent="center" justifyContent="space-evenly"
            templateColumns={"repeat(" + dim.cols + ", " + GRID_SIZE + "rem)"}
            templateRows={"repeat(" + dim.rows + ", " + GRID_SIZE + "rem)"}>
                <GridItem key="display" h={"95%"}
                    w={"95%"} alignSelf="center" justifySelf="center"
                    colStart={Math.max(1, Math.floor((dim.cols - DISPLAY_SIZE) / 2.0) + 1)}
                    rowStart={Math.max(1, Math.floor((dim.rows - DISPLAY_SIZE) / 2.0) + 1)}
                    colSpan={(dim.cols < DISPLAY_SIZE) ? dim.cols : Math.ceil(DISPLAY_SIZE)}
                    rowSpan={(dim.rows < DISPLAY_SIZE) ? dim.rows : Math.ceil(DISPLAY_SIZE)}>
                    <AnimatePresence mode="wait">
                        <motion.div key={"display" + data.current?._id}
                            initial={mounted ? { scaleX: 0, scaleY: 0, opacity: 0 } : null}
                            animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                            exit={{ scaleX: 0, scaleY: 0, opacity: 0 }}
                            transition={{ duration: 0.5 }} style={{ height: "100%" }}>
                            { !data.current &&
                                <BannerDisplay key="banner" size={DISPLAY_SIZE * GRID_SIZE}/> }
                            { data.current && data.current.class === "person" &&
                                <PersonDisplay key={"person" + data.current._id} item={data.current} size={GRID_SIZE} /> }
                            { data.current && data.current.class === "team" &&
                                <TeamDisplay key={"team" + data.current._id} item={data.current} size={GRID_SIZE} /> }
                            { data.current && data.current.class === "project" &&
                                <ProjectDisplay key={"project" + data.current._id} item={data.current} size={GRID_SIZE} /> }
                        </motion.div>
                    </AnimatePresence>
                </GridItem>
                { gridItems.map((item, index) => {
                    return <GridItem key={"item" + index} m="0 auto">
                        { item }
                    </GridItem>
                })}
        </Grid>
    );

}

export default Board;