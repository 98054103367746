import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from "d3";
import { Box } from '@chakra-ui/react';

const OQScoreDimensionChart = ({ dimension, referenceScore = 100, selectParameter, width = 350, height = 400 }) => {
    const canvasRef = React.createRef();

    useEffect(() => {
        drawReport()
    }, []);

    const drawReport = () => {
        const margin = {
            top: 10,
            bottom: 80,
            left: 30,
            right: 0
        }

        // create the SVG canvas
        const svg = d3.select(canvasRef.current).classed("report", true).attr("id", "OQDimensionScore " + dimension.name);

        // create the display
        svg.selectAll("g").remove();
        const display = svg.append("g").classed("display", true).attr("transform", `translate(${margin.left}, ${margin.top})`);
        const chartWidth = width - margin.left - margin.right;
        const chartHeight = height - margin.top - margin.bottom;

        const xScale = d3.scaleBand()
            .domain(dimension.parameters.map(function(param) { return param.name; }))
            .range([0, chartWidth])
            .padding(0.25);
        const yScale = d3.scaleLinear()
            .domain([0, 200])
            .range([chartHeight, 0]);
        const colorScale = d3.scaleLinear()
            .domain([0,100,200])
            .range(["#4B707A", "#99BCA8", "#F9D663"])

        const yGridlines = d3.axisLeft().ticks(2).scale(yScale).tickSizeInner(-chartWidth-5).tickSizeOuter(0).tickFormat("");
        display.append("g").classed("gridline", true).attr('transform', `translate(-5,0)`).call(yGridlines).attr("color", "#cccccc");

        const xAxis = d3.axisBottom().scale(xScale).tickSizeOuter(0);
        display.append("g").classed("x axis", true).attr('transform', `translate(0,${chartHeight})`).call(xAxis)
            .selectAll("text")
                .attr("transform", "translate(-10,0) rotate(-45)")
                .style("text-anchor", "end")
                .call(wrap, 70);

        const yAxis = d3.axisLeft().ticks(5).scale(yScale).tickSizeOuter(0);
        display.append("g").classed("y axis", true).attr('transform', `translate(-5,0)`).call(yAxis);

        /*
        display.select('.x.axis')
            .append('text')
            .attr('x', chartWidth/2)
            .attr('y', 40)
            .attr('fill', '#000')
            .style('font-size', '1.2em')
            .style('text-anchor', 'middle')
            .text(xLabel);
        */

        // draw reference line for overall score
        const vertLineYCoord = yScale(referenceScore);
        display.append('g').classed("referenceline", true).append('line')
            .attr('x1', 0)
            .attr('y1', vertLineYCoord)
            .attr('x2', width)
            .attr('y2', vertLineYCoord)
            .style("stroke-width", 1)
            .style("stroke-dasharray","5,5")
            .style("stroke", "#F38F22")
        display.select(".referenceline", true).append('text')
            .attr('x', -30)
            .attr('y', vertLineYCoord)
            .attr('fill', '#F38F22')
            .style('font-size', '0.7em')
            .style('text-anchor', 'left')
            .text(referenceScore);

        /*
        display.append("g").classed("bar", true).append("rect")
            .attr("x", chartWidth * 0.15)
            .attr("y", yScale(dimension.score))
            .attr("width", chartWidth * 0.7)
            .attr("height", chartHeight - yScale(dimension.score))
            .attr("fill", getCssIndexedColor(6));
        */

        display.selectAll(".bar").data(dimension.parameters).enter().append("rect").classed("bar", true)
            .attr("x", (d) => xScale(d.name))
            .attr("y", yScale(0))
            .attr("width", xScale.bandwidth())
            .attr("height", chartHeight - yScale(0))
            .attr("fill", d => colorScale(d.getOQScore()))
            .on("click", (event, d) => selectParameter(d) )
            .style("cursor", "pointer");

        // Animation
        display.selectAll("rect").transition()
            .duration(800)
            .attr("y", d => yScale(d.getOQScore()) )
            .attr("height", d => (chartHeight - yScale(d.getOQScore())))
            .delay((d,i) => { return(i*100) })

        display.selectAll(".bar-label").data(dimension.parameters).enter().append("text").classed("bar-label", true)
            .text(d => Math.round(d.getOQScore()))
            .attr("x", d => xScale(d.name) + xScale.bandwidth() * 0.5)
            .attr("dx", 0)
            .attr("y", d => yScale(d.getOQScore()))
            .attr("dy", -5)
            .style('font-size', '0.7em')
            .style('text-anchor', 'middle');
    }

    // copied from https://gist.github.com/mbostock/7555321
    const wrap = (text, width) => {
        text.each(function() {
            var text = d3.select(this),
                words = text.text().split(/\s+/).reverse(),
                word = words.pop(),
                line = [],
                lineNumber = 0,
                lineHeight = 1.1, // ems
                y = text.attr("y"),
                dy = parseFloat(text.attr("dy")),
                tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
            while (word) {
                line.push(word);
                tspan.text(line.join(" "));
                if (tspan.node().getComputedTextLength() > width) {
                    line.pop();
                    tspan.text(line.join(" "));
                    line = [word];
                    tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                }
                word = words.pop();
            }
        });
    }

    return <Box><svg ref={canvasRef} width={width} height={height} /></Box>

}

OQScoreDimensionChart.propTypes = {
    dimension: PropTypes.object,
    referenceScore: PropTypes.number,
    selectParameter: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
}

export default OQScoreDimensionChart;