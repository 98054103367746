// User Service - get User Information
import { useEffect, useState } from 'react';
import { useAPI } from './APIService';
//import { useEffect, useState } from 'react';

export const useConfig = () => {
    const [config, setConfig] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const { getConfig } = useAPI();
    
    useEffect(() => {
        let subscribed = true;
        setIsLoading(true);
        getConfig().then(
            result => {
                if (subscribed) {
                    setIsLoading(false);
                    setConfig(result);
                }
            },
            e => {
                setIsLoading(false);
                setError(e);
            }
        )
    
        return function cleanup() {
          subscribed = false
        }
    }, [])

    return [config, error, isLoading];
}
