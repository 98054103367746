export const validateEmail = (email) => {
    console.log("validate email", email);
    if (!email) return false;
    const at = email.indexOf('@');
    const dot = email.lastIndexOf('.');
    const postfix = email.length - dot;
    console.log("validate email", email, email.length, at, dot, postfix);
    if (at <= 0 || at > dot || postfix > 4 || postfix < 3) return false;
    else return true;
}
