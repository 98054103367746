import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAPI } from '../../hooks/APIService';
import { Input, Text, VStack, useToast } from '@chakra-ui/react';

const OQScannerStatement = ({ statement, debug, editable }) => {
    const [text, setText] = useState();
    const [edited, setEdited] = useState(false);
    const { patchStatementText } = useAPI();
    const toast = useToast();

    useEffect(() => {
        setText(statement.text);
    }, []);

    const onTextEnter = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    }

    const onTextBlur = (event) => {
        setEdited(false);
        if (event.target.value === text) return;
        
        patchStatementText(statement.id, event.target.value).then(() => {
            setText(event.target.value);
            statement.text = event.target.value;
            toast({
                title: 'Saved changes.',
                description: "Statement updated successfully.",
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        }).catch(error => {
            console.log("error saving statement", error);
            toast({
                title: 'Error saving changes.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        });
        setEdited(false);
    }

    return <VStack align={'start'} pl={"2"}>
        { (!editable || !edited) &&
            <Text onClick={ () => { if (editable) setEdited(true) }}>{statement.text}</Text>
        }
        { editable && edited &&
            <Input autoFocus defaultValue={statement.text} variant="flushed" focusBorderColor={"black"} bgColor="transparent"
                borderBottom="none" onKeyDown={onTextEnter} onBlur={onTextBlur} height="1rem" width="full" pt={0} />
        }
        { debug &&
            <Text fontSize="xs">{statement.description}</Text>
        }
    </VStack>

}

OQScannerStatement.propTypes = {
    statement: PropTypes.object.isRequired,
    debug: PropTypes.bool,
    editable: PropTypes.bool
}

export default OQScannerStatement;