import { Container, HStack, Icon, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { BiUserPin } from "react-icons/bi";
import { MdAddCircleOutline, MdClose, MdOutlineSwapHoriz } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import PersonEmblem from "../../components/emblem/PersonEmblem";
import PersonSelector from "../../components/selector/PersonSelector";
import { useAPI } from "../../hooks/APIService";
import { getItemColor } from "../../theme";
import { PersonContext } from "./Person";

const PersonLineManager = () => {
    const { person, updatePersonProperty } = useContext(PersonContext);
    const [lineManager, setLineManager] = useState(null);
    const [isLoaded, setLoaded] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { getPersonById } = useAPI();
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        loadLineManager();
    }, []);

    const loadLineManager = async() => {
        if (person.line) {
            try {
                const item = await getPersonById(person.line);
                setLineManager(item);
            }
            catch (e) {
                console.log("error retrieving line manager", e);
            }
        }
        setLoaded(true);
    }

    const openItem = () => {
        navigate('/person/' + lineManager._id);
    }

    const updateLineManager = async (item) => {
        console.log("update line manager", item);
        updatePersonProperty("line", item._id);
        setLineManager(item);
        onClose();
        toast({
            title: 'Saved changes.',
            description: "Line Manager updated successfully.",
            status: 'success',
            duration: 5000,
            isClosable: true,
        })
    }

    const removeLineManager = async () => {
        console.log("remove line manager");
        updatePersonProperty("line", null);
        setLineManager(null);
        toast({
            title: 'Saved changes.',
            description: "Line Manager updated successfully.",
            status: 'success',
            duration: 5000,
            isClosable: true,
        })
    }

    const color = getItemColor(person);

    return (
        <HStack p="0.5rem 0 0.5rem 0" width="full">
            <Tooltip hasArrow label="Line Manager" fontSize="xs" placement="top-start" bg={color}>
                <Container p="0" m="0" w="auto">
                    <Icon as={BiUserPin} boxSize={5} />
                </Container>
            </Tooltip>
            <HStack cursor="pointer" width="100%" gap="0" spacing="0">
                { lineManager &&
                    <>
                        <PersonEmblem size={2} item={lineManager} displayName={false} />
                        <Text pl="2" fontSize="xs" width="100%" onClick={openItem}>{lineManager.name}</Text>
                        <IconButton icon={<MdClose />} variant="ghost" cursor="pointer" size="xs" onClick={removeLineManager} />
                        <IconButton icon={<MdOutlineSwapHoriz />} variant="ghost" cursor="pointer" size="xs" onClick={onOpen} />
                    </>
                }
                { !lineManager && isLoaded &&
                    <>
                        <Text fontSize="xs" color="gray.500" width="100%">no line manager</Text>
                        <IconButton icon={<MdAddCircleOutline />} variant="ghost" cursor="pointer" size="xs" onClick={onOpen} />
                    </>
                }
                { !lineManager && !isLoaded &&
                    <Text fontSize="xs" color="gray.500" width="100%">loading...</Text>
                }
            </HStack>
            <Modal isOpen={isOpen} onClose={onClose} size="sm">
                <ModalOverlay />
                <ModalContent color="black">
                    <ModalHeader fontSize="1rem">Select the Line Manager...</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <PersonSelector onSelect={updateLineManager} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </HStack>)
}

export default PersonLineManager;