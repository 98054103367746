import React, { useEffect, useState } from 'react';
import { useAPI } from '../../hooks/APIService.js';
import PropTypes from 'prop-types';
import { Spinner } from '@chakra-ui/react';
import PersonMiniCard from './PersonMiniCard.js';
import TeamMiniCard from './TeamMiniCard.js';
import ProjectMiniCard from './ProjectMiniCard.js';

const MiniCardLoader = ({ id, size = 4, children }) => {
    const [item, setItem] = useState();
    const { getItemById } = useAPI();

    useEffect(() => {
        loadItem();
    }, [])

    const loadItem = async () => {
        const item = await getItemById(id);
        setItem(item);
    }

    if (!item) return <Spinner />;
    if (item.class == "team") return <TeamMiniCard item={item} size={size}>{children}</TeamMiniCard> 
    else if (item.class == "project") return <ProjectMiniCard item={item} size={size}>{children}</ProjectMiniCard> 
    else return <PersonMiniCard item={item} size={size}>{children}</PersonMiniCard>
}

MiniCardLoader.propTypes = {
    id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    size: PropTypes.number,
    children: PropTypes.node
}

export default MiniCardLoader;