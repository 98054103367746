import React, { useState } from 'react';
import { useAPI } from '../../hooks/APIService.js';
import PropTypes from 'prop-types';
import SearchInput from '../search/SearchInput.js';
import PersonListItem from '../listitem/PersonListItem.js';
import { VStack, Text } from '@chakra-ui/layout';

const PersonSelector = ({ onSelect }) => {
    const [result, setResult] = useState();
    const { searchItems } = useAPI();

    const search = async (query) => {
        var items = await searchItems(query, "person");
        setResult({query: query, items: items});
    }

    return (
        <VStack alignItems="start">
            <SearchInput onSearch={ search } />
            <VStack>
                { result?.items.length == 0 && <Text>No people found for: {result?.query}.</Text> }
                { result?.items.map(item => {
                    return <PersonListItem item={item} key={item._id} onClick={ () => { onSelect(item) }} />
                })}
            </VStack>
        </VStack>
    )
}

PersonSelector.propTypes = {
    onSelect: PropTypes.func
}

export default PersonSelector;