import React, { useState } from 'react';
import { useAPI } from '../../hooks/APIService.js';
import PropTypes from 'prop-types';
import SearchInput from '../search/SearchInput.js';
import { Text, VStack } from '@chakra-ui/layout';
import ProjectListItem from '../listitem/ProjectListItem.js';

const ProjectSelector = ({onSelect, type = null}) => {
    const [result, setResult] = useState(null);
    const { searchItems } = useAPI();

    const search = async (query) => {
        var items = await searchItems(query, "project", type);
        setResult({ query: query, items: items });
    }

    return (
        <VStack alignItems="start">
            <SearchInput onSearch={ search } />
            <VStack>
                { result?.items.length == 0 && <Text>No projects found for: {result.query}</Text> }
                { result?.items.map(item => {
                    return <ProjectListItem item={item} key={item._id} onClick={ () => { onSelect(item) }} />
                })}
            </VStack>
        </VStack>
    )
}

ProjectSelector.propTypes = {
    onSelect: PropTypes.func,
    type: PropTypes.string
}

export default ProjectSelector;