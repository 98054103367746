import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';

const OQScoreImprovements = ({ score }) => {
    const [ topAreas, setTopAreas ] = useState([]);

    useEffect(() => {
        determineTopAreas()
    }, []);

    const determineTopAreas = () => {
        score.options.sort((a, b) => a.score - b.score);
        const topAreas = [score.options[0]];
        for (var i = 1; i < 5 && score.options[i].score - score.options[0].score < 0.25; i++) {
            topAreas.push(score.options[i]);
        }
        setTopAreas(topAreas);
    }

    return <VStack alignItems={'flex-start'} spacing={5} pb={5}>
        <Text>Top Areas for Improvement</Text>
        <Text fontSize={'xs'}>
            The following domain combinations show the lowest OQ Scores and require some special attention:
        </Text>
        <HStack fontSize={'xs'} spacing={10}>
            { topAreas.map((option, index) => {
                return <VStack key={index} spacing={2} alignItems={'flex-start'}>
                    <HStack>
                        <Image boxSize={6} src={'../icons/' + option.valueParam.name + '.png'} alt={option.valueParam.name} />
                        <Text>{option.valueParam.name}</Text>
                    </HStack>
                    <HStack>
                        <Image boxSize={6} src={'../icons/' + option.domainParam.name + '.png'} alt={option.domainParam.name} />
                        <Text key={index}>{option.domainParam.name}</Text>
                    </HStack>
                    <Text fontWeight={'bold'}>Combined OQ-Score: {option.getOQScore()}</Text>
                </VStack>
            })}
        </HStack>
    </VStack>

}

OQScoreImprovements.propTypes = {
    score: PropTypes.object
}

export default OQScoreImprovements;