import React from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Center, Flex, Spinner } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Menu from './containers/menu/Menu.js';
import Board from './containers/board/Board.js';
import Team from './containers/team/Team.js';
import Person from './containers/person/Person.js';
import SearchResults from './containers/search/SearchResults.js';
import Reports from './containers/reports/Reports.js';
import Configuration from './containers/config/Configuration.js';
import Help from './containers/help/Help.js';
import UpdateEditor from './containers/notifications/UpdateEditor.js';
import NewsEditor from './containers/notifications/NewsEditor.js';
import Support from './containers/support/Support.js';
import Project from './containers/project/Project.js';
import OrganizationChart from './containers/charts/OrganizationChart';

const App = () => {
    const { isLoading } = useAuth0();

    if (isLoading) return <Center><Spinner /></Center>

    return <Flex align="center" justify="center" direction="column" h="100%" bgColor="white">
        <Menu />
        <Routes>
            <Route path="/team/:id" element={<ProtectedRoute component={Team} key={Math.random()}/>} />
            <Route path="/person/:id" element={<ProtectedRoute component={Person} />} />
            <Route path="/project/:id" element={<ProtectedRoute component={Project} />} />
            <Route path="/search/:query" element={<ProtectedRoute component={SearchResults}/>} />
            <Route exact path="/chart" element={<ProtectedRoute component={OrganizationChart}/>} />
            <Route exact path="/reports" element={<ProtectedRoute component={Reports}/>} />
            <Route exact path="/config" element={<ProtectedRoute component={Configuration}/>} />
            <Route exact path="/help" element={<ProtectedRoute component={Help}/>} />
            <Route exact path="/notification/update" element={<ProtectedRoute component={UpdateEditor}/>} />
            <Route exact path="/notification/news" element={<ProtectedRoute component={NewsEditor}/>} />
            <Route path='/' element={<ProtectedRoute component={Board}/>} />
        </Routes>
        <Support />
    </Flex>
}

export default withAuthenticationRequired(App);
