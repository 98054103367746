import Icon from '@chakra-ui/icon';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import React, { useContext } from 'react';
import { RiDirectionFill } from 'react-icons/ri';
import EditableTextarea from '../../components/editable/EditableTextarea';
import { TeamContext } from './Team';

const TeamProcedures = () => {
    const {team} = useContext(TeamContext);

    return (
        <VStack justifyItems="start" alignItems="start" spacing={1} width="full">
            <HStack spacing="1">
                <Icon as={RiDirectionFill} />
                <Text fontSize="xs" fontWeight="bold">Communication Plan</Text>
            </HStack>
            <EditableTextarea item={team} property="procedures" fontSize="xs" placeholder="no procedures defined" />
        </VStack>
    );
}

export default TeamProcedures;