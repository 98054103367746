import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Box, Button, ButtonGroup, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { GrGroup, GrUser } from "react-icons/gr";
import { MdOutlineAddTask } from "react-icons/md";
import { ChatIcon, RepeatIcon } from '@chakra-ui/icons';
import { useAPI } from '../../hooks/APIService';
import OQScoreChart from './OQScoreChart';
import OQScoreText from './OQScoreText';
import OQScoreDimension from './OQScoreDimension';
import OQScoreDetails from './OQScoreDetails';
import OQScoreImprovements from './OQScoreImprovements';
import OQScoreRecommendations from './OQScoreRecommendations';

const OQScannerSummary = ({ domainDimension, valueDimension, score, user, onProceed, debug }) => {
    const [ topTabIndex, setTopTabIndex ] = useState(1);
    const [ tabIndex, setTabIndex ] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();  
    const { deleteAnswers } = useAPI();

    const resetScan = () => {
        console.log("reset scan");
        onClose();
        deleteAnswers(user.license, user.email).then(() => {
            console.log("deleted all answers");
            score.reset();
            onProceed();
        });
    }

    const refineScan = () => {
        console.log("refine scan");
        score.enhance(5);
        onProceed();
    }

    const requestOrgQuote = () => {
        console.log("request a quote for your organization");
        location.href = "mailto:service@leapfroq.com"
        + "?subject="
        + "OQ-Scanner: Quote for Organization-Wide License"
        + "&body="
        + "Hi,%0D%0A%0D%0AI am interested in using OQ-Scanner for the whole organization.%0D%0APlease send me a non-binding offer.%0D%0A%0D%0AExpected number of users:%0D%0AUnit/Team Drill-Down: [yes/no]%0D%0A%0D%0AThank you.";
    }

    const handleTopTabsChange = (index) => {
        console.log("handle top tabs change", index);
        setTopTabIndex(index)
    }
    
    const handleTabsChange = (index) => {
        console.log("handle tabs change", index);
        setTabIndex(index)
    }
    
    return <Tabs variant='enclosed' colorScheme='green' index={topTabIndex} onChange={handleTopTabsChange}>
        <TabList mb='1em'>
            <Tab><HStack><GrGroup /><Text>Organization OQ Score</Text></HStack></Tab>
            <Tab><HStack><GrUser /><Text>Individual OQ Score</Text></HStack></Tab>
        </TabList>
        <TabPanels>
            <TabPanel p={0}>
                <Alert status='warning' fontSize={'xs'}>
                    <AlertIcon />
                    <Box>
                        <Text>Your personal license does not include oganization results.</Text>
                        <Text>If you would like to extend your license to include results from more members of your organization, please request an extended license.</Text>
                    </Box>
                </Alert>
                <ButtonGroup variant={"outline"} size={"xs"} colorScheme='orange' mt={3} mb={3}>
                    <Tooltip label='Request a quote for extending your license to include more users from your organization.'>
                        <Button onClick={requestOrgQuote} leftIcon={<ChatIcon />}>Request Quote</Button>
                    </Tooltip>
                </ButtonGroup>
            </TabPanel>
            <TabPanel p={0}>
                <Alert status='warning' fontSize={'xs'}>
                    <AlertIcon />
                    <Text>
                        Your individual scores might be biased and can never be as accurate as the scores determined for a complete organization.
                        Use the individual scores for personal reference only.
                        You should prefer to use the <span onClick={() => setTopTabIndex(1)} style={{cursor: "pointer", textDecoration: "underline"}}>Organization OQ Score</span> to derive measures.
                    </Text>
                </Alert>
                <ButtonGroup variant={"outline"} size={"xs"} colorScheme='green' mt={3} mb={3}>
                    <Tooltip label='Reset previous results and rerun the complete test.'>
                        <Button onClick={onOpen} leftIcon={<RepeatIcon />}>Reset Scan</Button>
                    </Tooltip>
                    <Tooltip label='Refine your existing OQ scan by rating few more statements.'>
                        <Button onClick={refineScan} leftIcon={<MdOutlineAddTask />}>Refine Scan</Button>
                    </Tooltip>
                </ButtonGroup>
                <Tabs variant='solid-rounded' size='sm' colorScheme='green' index={tabIndex} onChange={handleTabsChange}>
                    <TabList mb='1em'>
                        <Tab fontSize="xs"><HStack><Text>Overall</Text></HStack></Tab>
                        <Tab fontSize="xs"><HStack><Text>Dimension: Principles</Text></HStack></Tab>
                        <Tab fontSize="xs"><HStack><Text>Dimension: Stages</Text></HStack></Tab>
                        <Tab fontSize="xs"><HStack><Text>Details</Text></HStack></Tab>
                        <Tab fontSize="xs"><HStack><Text>Top Areas for Improvement / Recommendations</Text></HStack></Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <HStack alignItems={'flex-start'} spacing={10}>
                                <OQScoreChart score={score.getOQScore()} />
                                <OQScoreText score={score.getOQScore()} changeTab={setTabIndex} />
                            </HStack>
                        </TabPanel>
                        <TabPanel>
                            <OQScoreDimension dimension={valueDimension} referenceScore={score.getOQScore()} changeTab={setTabIndex} />
                        </TabPanel>
                        <TabPanel>
                            <OQScoreDimension dimension={domainDimension} referenceScore={score.getOQScore()} changeTab={setTabIndex} />
                        </TabPanel>
                        <TabPanel>
                            <OQScoreDetails domainDimension={domainDimension} valueDimension={valueDimension} score={score} debug={debug} />
                        </TabPanel>
                        <TabPanel>
                            <OQScoreImprovements score={score} />
                            <OQScoreRecommendations />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontWeight='bold'>
                                Reset OQ Scan
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                Are you sure? You will remove all previous answers and have to run a full scan again.
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button colorScheme='red' onClick={resetScan} ml={3}>
                                    Reset
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </TabPanel>
        </TabPanels>
    </Tabs>

}

OQScannerSummary.propTypes = {
    domainDimension: PropTypes.object.isRequired,
    valueDimension: PropTypes.object.isRequired,
    score: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onProceed: PropTypes.func.isRequired,
    debug: PropTypes.bool
}

export default OQScannerSummary;