import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Input, Tag, TagLabel, TagRightIcon, Text } from '@chakra-ui/react';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import { getCssIndexedColor, getIndexedColor } from '../../theme';
import TagLevel from './TagLevel';

const TalentTag = ({ talent = null, onSave, onExit, onConfirm, onAccept, onLevelChange, onRemove }) => {
    const inputRef = useRef();

    useEffect(() => {
        if (inputRef?.current) inputRef.current.focus();
    });

    const changeTalentLevel = (level) => {
        if (onLevelChange) onLevelChange(talent.label, level);
    }

    const onTextEnter = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
        if (event.key === 'Escape') {
            event.target.value = null;
            if (onExit) onExit();
        }
    }

    const onTextBlur = (event) => {
        var value = event.target.value;
        if (value?.length > 0) {
            value = value.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
            onSave(value, talent?.value);
        }
        if (onExit) onExit();
    }

    const getColorCode = (label) => {
        if (!label) return 0;
        let c = 0;
        for (let i = 0; i < label.length; i++) {
            c += label.charCodeAt(i);
        }
        return c % 8;
    }

    const variant = (talent?.proposed) ? "outline" : "subtle";
    const colorSkill = getCssIndexedColor(4);
    const borderColor = getColorCode(talent?.label);

    return (
        <Tag size='sm' colorScheme="gray" variant={variant} pt="0" pb="0" borderWidth={1.5} borderColor={getIndexedColor(borderColor)}>
            { talent &&
                <TagLevel level={talent.level} color={colorSkill} onLevelChange={changeTalentLevel} /> }
            <TagLabel pl="1">
                { talent && <Text size="xs">{talent.label}</Text> }
                { !talent &&
                    <Input variant="unstyled" size="xs" height="1rem" ref={inputRef}
                        onKeyUp={onTextEnter} onBlur={onTextBlur} width="100%" pt={0} />
                }
            </TagLabel>
            { onConfirm && talent &&
                <Box onClick={() => onConfirm(talent.label)} cursor="pointer">
                    <Text fontSize="0.6rem" ml={1} _hover={{color: "coral.500"}} cursor="pointer">
                        +{talent.confirmed ? talent.confirmed?.length : ""}
                    </Text>
                </Box>
            }
            { !onConfirm && talent && talent.confirmed?.length > 0 &&
                <Text fontSize="0.6rem" ml={1}>+{talent.confirmed?.length}</Text>
            }
            { onAccept &&
                <TagRightIcon as={RiCheckLine} cursor="pointer" onClick={() => onAccept(talent.label)} /> }
            { onRemove &&
                <TagRightIcon as={RiCloseLine} ml={1} cursor="pointer" onClick={() => onRemove(talent.label) } /> }
        </Tag>
    );

}

TalentTag.propTypes = {
    talent: PropTypes.object,
    onSave: PropTypes.func,
    onExit: PropTypes.func,
    onConfirm: PropTypes.func,
    onAccept: PropTypes.func,
    onLevelChange: PropTypes.func,
    onRemove: PropTypes.func
}

export default TalentTag;