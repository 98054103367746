import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { useAPI } from '../../hooks/APIService.js';
import { Input } from '@chakra-ui/input';
import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/form-control';
import { Switch } from '@chakra-ui/switch';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';

const PersonForm = ({ onClose }) => {
    const navigate = useNavigate();
    const { addPerson } = useAPI();
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [permanent, setPermanent] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const createPerson = () => {
        console.log("create person", );
        const person = {
            name: name,
            email: email,
            type: permanent ? "permanent" : "temporary"
        }
        console.log("add person: ", person);
        addPerson(person).then(data => {
            onClose();
            navigate("/person/" + data);
        }).catch(error => {
            console.log('ERROR: ' + error);
            setError({general: "error creating person in database: " + error});
        });
    }

    const handleNameChange = event => setName(event.target.value);
    const handleEmailChange = event => setEmail(event.target.value);
    const handlePermanentChange = event => setPermanent(event.target.checked);
    const handleSubmit = event => {
        event.preventDefault();
        validateEmail();
        setSubmitted(true);
        console.log("error test", error);
        if (!error || !error.email) {
            createPerson();
        }
    }

    const validateEmail = () => {
        if (!email) return "Email must be specified";
        const at = email.indexOf('@');
        const dot = email.lastIndexOf('.');
        const postfix = email.length - dot;
        console.log("validate email", email.length, at, dot, postfix);
        if (at <= 0 || at > dot || postfix > 4 || postfix < 3) setError({ email: "No valid email address" });
        else setError({ email: null });
    }

    return (
        <form name="addPersonForm" className="PersonForm Form" onSubmit={handleSubmit}>
            { submitted && error?.general &&
                <Box my={4}>
                    <Alert status="error" borderRadius={4}>
                        <AlertIcon />
                        <AlertDescription>{error?.general}</AlertDescription>
                    </Alert>
                </Box>
            }
            <FormControl id="name-input" mb="2" display="flex" isRequired isInvalid={error?.name}>
                <FormLabel fontSize="xs">Name</FormLabel>
                <Input placeholder="Name" aria-label="Name" size="sm" focusBorderColor="green.500" variant="flushed" value={name} onChange={handleNameChange} />
                <FormErrorMessage>{error?.name}</FormErrorMessage>
            </FormControl>
            <FormControl id="email-input" mb="2" display="flex" isRequired isInvalid={error?.email}>
                <FormLabel fontSize="xs">Email</FormLabel>
                <Input placeholder="Email" aria-label="Email" size="sm" focusBorderColor="green.500" variant="flushed" value={email} onChange={handleEmailChange} onBlur={validateEmail} />
                <FormErrorMessage>{error?.email}</FormErrorMessage>
            </FormControl>
            <FormControl id="permanent-switch" mb="8" display="flex" gridGap="2">
                <FormLabel fontSize="xs">Permanent?</FormLabel>
                <Switch size="sm" colorScheme="green" defaultChecked value={permanent} onChange={handlePermanentChange} />
                <FormHelperText fontSize="xs" m="0">disable if person has a temporary engagement</FormHelperText>
            </FormControl>
            <ButtonGroup size="sm">
                <Button type="submit" isDisabled={!name||!email}>Add Person</Button>
                <Button type="button" onClick={onClose} variant="outline">Cancel</Button>
            </ButtonGroup>
        </form>
    )
}

PersonForm.propTypes = {
    onClose: PropTypes.func
}

export default PersonForm;