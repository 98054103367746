import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { getCssColor, getItemColor, getTextColor } from '../../theme';
import { Box, Center, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import RoundedBox from '../shapes/RoundedBox';

const PersonMiniCard = ({ item, size = 6, children, onClick, isLinked = true }) => {
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
    const navigate = useNavigate();

    const openItem = () => {
        navigate('/' + item.class + '/' + item._id);
    }

    const nameAbbr = () => {
        const initials = item.name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
        return initials.join('');
    }

    const color = getItemColor(item);
    const textColor = getTextColor(item);

    return <Box cursor="pointer" onClick={ () => { if (onClick) onClick(item); else if (isLinked) openItem(); } }>
        { item &&
            <RoundedBox size={size} radius="10%" color={color} cssColor={getCssColor(item)} thickness={size * 0.025}>
                { item.picture && 
                    <Image src={IMAGE_URL + item.picture} w="95%" h="95%" borderRadius="30%" />
                }
                { !item.picture &&
                    <Center h="95%" w="95%" bg={color} borderRadius="30%">
                        <Text fontSize={(size / 3) + "rem"} color={textColor} textAlign="center">{nameAbbr()}</Text>
                    </Center>
                }
            </RoundedBox>
        }
        {children}
    </Box>
}

PersonMiniCard.propTypes = {
    item: PropTypes.object,
    size: PropTypes.number,
    onClick: PropTypes.func,
    isLinked: PropTypes.bool,
    children: PropTypes.node
}

export default PersonMiniCard;