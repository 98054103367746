import React, { useState } from 'react';
import { useAPI } from '../../hooks/APIService.js';
import PropTypes from 'prop-types';
import SearchInput from '../search/SearchInput.js';
import PersonListItem from '../listitem/PersonListItem.js';
import { VStack, Text } from '@chakra-ui/layout';
import TeamListItem from '../listitem/TeamListItem.js';

const ItemSelector = ({ onSelect }) => {
    const [result, setResult] = useState();
    const { searchItems } = useAPI();

    const search = async (query) => {
        var items = await searchItems(query);
        setResult({query: query, items: items});
    }

    return (
        <VStack alignItems="start">
            <SearchInput onSearch={ search } />
            <VStack>
                { result?.items.length == 0 && <Text>No people or teams found for: {result?.query}.</Text> }
                { result?.items.map(item => {
                    if (item.class === "person") {
                        return <PersonListItem item={item} key={item._id} onClick={ () => { onSelect(item) }} />
                    }
                    if (item.class === "team") {
                        return <TeamListItem item={item} key={item._id} onClick={ () => { onSelect(item) }} />
                    }
                })}
            </VStack>
        </VStack>
    )
}

ItemSelector.propTypes = {
    onSelect: PropTypes.func
}

export default ItemSelector;