import React, { useContext, useRef, useState } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/layout';
import { getItemColor, getTextColor } from '../../theme';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import EditableText from '../../components/editable/EditableText';
import { ProjectContext } from './Project';
import { useNavigate } from 'react-router-dom';
import OptionSelector from '../../components/selector/OptionSelector';

const ProjectCardHeader = () => {
    const {project, updateProjectProperty, deleteProject} = useContext(ProjectContext);
    const [type, setType] = useState(project.type);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const navigate = useNavigate();

    const onTypeSelect = (type) => {
        console.log("type select", type);
        updateProjectProperty("type", type);
        setType(type);
    }

    const onDelete = () => {
        console.log("delete project", project);
        deleteProject();
        onClose();
        if (navigate.length > 0) navigate(-1);
        else navigate("/");
    }

    const color = getItemColor(project);
    const textColor = getTextColor(project);

    return <HStack justify="center" alignContent="center" p={1}>
        <Box w="5%"></Box>
        <VStack gap={0} spacing={1} width="90%" color={textColor}>
            <EditableText item={project} property="name" textAlign="center" useTheme={true} />
            <OptionSelector options={["client", "service"]} onSelect={onTypeSelect}>
                <Text fontSize="xs">- {type?.toUpperCase()} -</Text>
            </OptionSelector>
        </VStack>
        <Tooltip hasArrow label={"Delete Project permanently" } fontSize="xs" placement="top-start" bg={color} color={textColor}>
            <Box w="5%">
                <IconButton minW="1.5rem" icon={<RiDeleteBin2Fill />} variant="link" size="md" onClick={onOpen} color={textColor} />
            </Box>
        </Tooltip>
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='md' fontWeight='bold'>
                        Delete Project
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Text>You will remove this project <u>permanently</u> from this organization. This cannot be undone afterwards.</Text>
                        <Text>Are you sure?</Text>
                    </AlertDialogBody>
                    <AlertDialogFooter justifyContent="center">
                        <Button ref={cancelRef} onClick={onClose}>Cancel</Button>
                        <Button colorScheme='red' onClick={onDelete} ml={3}>Delete</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </HStack>
}

export default ProjectCardHeader;