import { React, useEffect, useRef, useState } from 'react';
import { useContext } from "react";
import { PersonContext } from "./Person";
import Icon from "@chakra-ui/icon";
import { HStack, Text, VStack } from "@chakra-ui/layout";
import { RiAwardFill } from 'react-icons/ri';
import { Flex, IconButton, useToast } from '@chakra-ui/react';
import TalentTag from '../../components/tag/TalentTag';
import { MdAddCircleOutline, MdOutlineMapsUgc } from 'react-icons/md';

const PersonTalents = () => {
    const { person, updatePersonProperty, isCurrentUser, currentUserId } = useContext(PersonContext);
    const [ talents, setTalents ] = useState(person.talents);
    const [ newItem, setNewItem ] = useState(null);
    const toast = useToast();
    const inputRef = useRef();

    useEffect(() => {
        if (inputRef.current) inputRef.current.focus();
    }, [newItem]);

    const onSave = (value) => {
        const index = talents?.findIndex((element) => element.label.toLowerCase() === value.toLowerCase());
        if (index === undefined || index < 0) {
            const list = talents ? [...talents] : [];
            var obj = { label: value };
            // make this a proposal, if user is not equal person
            if (!isCurrentUser) obj.proposed = currentUserId;
            list.push(obj);
            updatePersonProperty("talents", list);
            setTalents(list);
        }
        else {
            showWarning("Talent with label '" + value + "' already exists.");
        }
        setNewItem(false);
    }

    const onRemove = (value) => {
        const index = talents?.findIndex((element) => element.label.toLowerCase() === value.toLowerCase());
        if (index >= 0) {
            if (!isCurrentUser) {
                if (talents[index].proposed != currentUserId) {
                    showError("Only talents either proposed by you or from your own profile can be removed.");
                    return;
                }
            }    
            // need to do deep copy to have qualifications change recognized
            var update = [...talents];
            update.splice(index, 1);
            updatePersonProperty("talents", update);
            setTalents(update);
        }
        else {
            showError("Talent with label '" + value + "' not found.");
        }
    }

    const onAccept = (value) => {
        if (!isCurrentUser) {
            showError("Only proposed talents for your own profile can be accepted.");
            return;
        }

        const index = talents?.findIndex((element) => element.label.toLowerCase() === value.toLowerCase());
        if (index >= 0) {
            // need to do deep copy to have qualifications change recognized
            var update = [...talents];
            update[index].accepted = update[index].proposed;
            delete update[index].proposed;
            updatePersonProperty("talents", update);
            setTalents(update);
        }
        else {
            showError("Talent with label '" + value + "' not found.");
        }
    }

    const onConfirm = (value) => {
        if (isCurrentUser) {
            showError("You cannot confirm your own talents.");
            return;
        }

        const index = talents?.findIndex((element) => element.label.toLowerCase() === value.toLowerCase());
        if (index >= 0) {
            // need to do deep copy to have qualifications change recognized
            var update = [...talents];
            if (!update[index].confirmed) update[index].confirmed = [];
            // check if current user already confirmed the skill
            const userIndex = update[index].confirmed.findIndex((element) => element === currentUserId);
            if (userIndex >= 0) {
                showWarning("You already confirm the talent.");
                return;
            }
            update[index].confirmed.push(currentUserId);
            updatePersonProperty("talents", update);
            setTalents(update);
        }
        else {
            showError("Talent with label '" + value + "' not found.");
        }
    }

    const onLevelChange = (value, level) => {
        const index = talents?.findIndex((element) => element.label.toLowerCase() === value.toLowerCase());
        if (index >= 0) {
            // check if this can be changed
            if (!isCurrentUser && !talents[index].proposed) {
                showError("Levels can only be changed for proposed talents or for your own talents.");
                return;
            }
            // need to do deep copy to have qualifications change recognized
            var update = [...talents];
            update[index].level = level;
            updatePersonProperty("talents", update);
            setTalents(update);
        }
        else {
            showError("Talent with label '" + value + "' not found.");
        }
    }

    const showWarning = (text) => {
        toast({
            title: 'Warning',
            description: text,
            status: 'warning',
            duration: 5000,
            isClosable: true,
        })
    }

    const showError = (text) => {
        toast({
            title: 'Error',
            description: text,
            status: 'error',
            duration: 5000,
            isClosable: true,
        })
    }

    return (
        <VStack justifyItems="start" alignItems="start" spacing="0" width="full">
            <HStack spacing="1" width="full">
                <Icon as={RiAwardFill} />
                <Text fontSize="xs" width="full">Skills / Talents</Text>
                { isCurrentUser &&
                    <IconButton icon={<MdAddCircleOutline />} variant="ghost" cursor="pointer" size="xs" onClick={() => setNewItem(true)} />
                }
                { !isCurrentUser &&
                    <IconButton icon={<MdOutlineMapsUgc />} variant="ghost" cursor="pointer" size="xs" onClick={() => setNewItem(true)} />
                }
            </HStack>
            <Flex direction="row" gap="2" wrap="wrap" pt={2}>
                { talents?.map( (item, index) => {
                    const userIndex = item.confirmed?.findIndex((element) => element === currentUserId);
                    // confirm talent: is not current user and not confirmed by current user?
                    const ca = (!isCurrentUser && (userIndex === undefined || userIndex < 0)) ? onConfirm : null;
                    // accept proposal: is current user and proposed, but not accepted
                    const aa = (isCurrentUser && item.proposed && item.accepted === undefined) ? onAccept : null;
                    // change level: is current user or is user who proposed and not accepted yet
                    const la = (isCurrentUser || currentUserId === item.proposed) ? onLevelChange : null;
                    // remove: is current user or is user who proposed and not accepted yet
                    const ra = (isCurrentUser || currentUserId === item.proposed) ? onRemove : null;
                    return <TalentTag key={"talent" + index} talent={item} onConfirm={ca} onAccept={aa} onLevelChange={la} onRemove={ra} />
                })}
                { newItem &&
                    <TalentTag key={"talent_new"} onSave={onSave} onExit={() => setNewItem(false)} />
                }
            </Flex>
        </VStack>
    );
}

export default PersonTalents;