import { Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, useDisclosure, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const OptionSelector = ({ options, onSelect, placement = "bottom-start", children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onOptionSelect = (option) => {
        onSelect(option);
        onClose();
    }

    return (
        <Popover gutter={-2} isOpen={isOpen} onOpen={onOpen} onClose={onClose} autoFocus closeOnEsc closeOnBlur placement={placement}>
            <PopoverTrigger>
                { children }
            </PopoverTrigger>
            <PopoverContent width="auto" borderRadius={0}>
                <PopoverBody p={0.5}>
                    <VStack gap={0} spacing={0} alignItems="start" color="black">
                        { options.map( (option, index) => {
                            return <Text key={"option" + index} pl={1} pr={1} fontSize="xs" w="full" cursor="pointer" _hover={{ bgColor: "gray.200" }} onClick={() => onOptionSelect(option)}>{option}</Text>
                        })}
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

OptionSelector.propTypes = {
    options: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    placement: PropTypes.string,
    children: PropTypes.node
}

export default OptionSelector;