import * as d3 from 'd3';
import { getCssIndexedColor } from '../../theme';
import { MODE } from './OrganizationChart';
//import { distance2D } from 'framer-motion';

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const personColor = getCssIndexedColor(4);
const teamColor = getCssIndexedColor(1);
const workGroupColor = getCssIndexedColor(0);
const communityColor = getCssIndexedColor(6);
const deliverableColor = getCssIndexedColor(2);
const serviceColor = getCssIndexedColor(5);
const leaderColor = getCssIndexedColor(3);
const personLightColor = getCssIndexedColor(4, 800);
const teamLightColor = getCssIndexedColor(1, 800);
const workGroupLightColor = getCssIndexedColor(0, 800);
const communityLightColor = getCssIndexedColor(6, 800);
const deliverableLightColor = getCssIndexedColor(2, 800);
const serviceLightColor = getCssIndexedColor(5, 800);

const itemColorScale = d3.scaleOrdinal()
    .domain(["permanent", "temporary", "primary", "workgroup", "community", "deliverable", "service"])
    .range([personColor, personColor, teamColor, workGroupColor, communityColor, deliverableColor, serviceColor]);

const itemLightColorScale = d3.scaleOrdinal()
    .domain(["permanent", "temporary", "primary", "workgroup", "community", "deliverable", "service"])
    .range([personLightColor, personLightColor, teamLightColor, workGroupLightColor, communityLightColor, deliverableLightColor, serviceLightColor]);

const linkColorScale = d3.scaleOrdinal()
    .domain(["line", "leader", "primary", "contributor", "workgroup", "community", "label", "project"])
    .range([personColor, leaderColor, teamColor, teamColor, workGroupColor, communityColor, "gray", deliverableColor]);

const strokeScale = d3.scaleOrdinal()
    .domain(["line", "leader", "primary", "contributor", "workgroup", "community", "label", "project"])
    .range([1.5, 1.5, 1, 1, 0.5, 0.5, 0.5, 1]);

const strokeDashScale = d3.scaleOrdinal()
    .domain(["line", "leader", "primary", "contributor", "workgroup", "community", "label", "project"])
    .range([0, 0, 0, 1, 0, 0, 1, 0]);

/*
const forceStrengthScale = d3.scaleOrdinal()
    .domain(["team", "person"])
    .range([-2000, 0]);
*/

const linkStrengthScale = d3.scaleOrdinal()
    .domain(["line", "leader", "primary", "contributor", "workgroup", "community", "label"])
    .range([0.5, 0.5, 1, 0.1, 0.1, 0.1, 1]);

/*
const linkDistanceScale = d3.scaleOrdinal()
    .domain(["line", "leader", "primary", "contributor", "workgroup", "community", "label"])
    .range([400, 400, 100, 300, 1000, 1000, 0]);
*/

const lineGenerator = d3.line();

export const buildHierarchyGraph = (data, mode, addLabelNodes, include) => {
    // if network mode return all items as children of root
    if (mode == MODE.Network) {
        const children = [];
        data.forEach(item => {
            if ((item.class === "team" && include.team[item.type]) || item.class === "project" && include.project[item.type] || item.class === "person") {
                children.push({ _id: item._id, item: item, relation: null, type: "undefined", children: null});
                if (addLabelNodes) {
                    children.push({ _id: item._id + "_l", item: item, type: "label", name: item.name });
                }
            }
        })
        return { _id: null, item: null, relation: null, type: "root", parent: null, children: children };
    }

    // create a map of all items for easy lookup
    const teams = new Map();
    const projects = new Map();
    const persons = new Map();
    data.forEach(item => {
        if (item.class === "team" && include.team[item.type]) teams.set(item._id, { item: item });
        if (item.class === "project" && include.project[item.type]) projects.set(item._id, { item: item });
        if (item.class === "person") persons.set(item._id, { item: item });
    })

    // iterate all nodes and create child relations
    const primary = new Map();
    const unresolved = new Map();
    teams.forEach((team) => {
        if (!team._id) team._id = team.item._id;

        // each member becomes a child of the team
        team.item.membership?.forEach(member => {
            const child = persons.get(member._id);
            if (!team.children) team.children = [];
            var id = child.item._id;
            if (member.type !== "primary") id = child.item._id + "/" + team.item._id;
            team.children.push({ _id: id, item: child.item, relation: member, type: member.type, parent: team });
            // mark child to have a parent
            child.parent = team;
            // store primary teams for persons for lookup
            if (member.type === "primary") primary.set(member._id, team);
        });

        // each project becomes a child of the team
        team.item.projects?.forEach(project => {
            const child = projects.get(project._id);
            if (child) {
                if (!team.children) team.children = [];
                var id = child.item._id + "/" + team.item._id;
                team.children.push({ _id: id, item: child.item, relation: project, type: project.type, parent: team });
                // mark child to have a parent
                child.parent = team;
            }
        });

        // in hierarchy view each leader becomes a child + it's primary team becomes the parent of the team
        if (mode === MODE.Hierarchy) {
            team.item.leader?.forEach(leader => {
                // check if leader is a team
                var parent = teams.get(leader._id);
                if (parent) {
                    // add parent team only for hierarchy mode
                    if (mode == MODE.Hierarchy) addChildTeam(parent, team);
                }
                // ...otherwise leader is a person
                else {
                    parent = persons.get(leader._id);
                    if (!team.children) team.children = [];
                    team.children.push({ _id: parent.item._id, item: parent.item, relation: leader, type: "leader", parent: team });
                    parent.parent = team;
                    // store leaders for parent/child resolution for teams
                    unresolved.set(team.item._id, leader._id);
                }
            });
        }
    })

    // add children for unresolved leaders in hierarchy mode
    if (mode == MODE.Hierarchy) {
        unresolved.forEach((leaderId, teamId) => {
            // find primary team of leader
            const parent = primary.get(leaderId);
            if (parent) {
                // find team to be added to primary parent
                const child = teams.get(teamId);
                addChildTeam(parent, child);
            }
        })
    }
    
    // iterate roots, find roots and add label nodes for teams
    const roots = [];
    teams.forEach((team) => {
        if (!team.parent) roots.push(team);
        // add node for labels of teams
        if (addLabelNodes) {
            if (!team.children) team.children = [];
            team.children.push({ _id: team.item._id + "_l", item: team.item, type: "label", name: team.item.name, parent: team });
        }
    })

    // determine single root
    var root = null;
    if (roots.length == 1) root = roots[0];
    else {
        root = { _id: "root", item: null, relation: null, type: "root", parent: null, children: roots };
        roots.forEach(node => node.parent = root);
    }

    // add persons without team to root node
    persons.forEach((person) => {
        if (!person.parent) {
            // ensure items are not linked to teams
            if (root.type !== "root") {
                root = { _id: "root", item: null, relation: null, type: "root", parent: null, children: [root] };
                root.children[0].parent = root;
            }
            if (!root.children) root.children = [];
            root.children.push({ _id: person.item._id, item: person.item, type: "undefined", parent: root })
        }
    })

    // add projects without team to root node
    projects.forEach((project) => {
        if (!project.parent) {
            // ensure items are not linked to teams
            if (root.type !== "root") {
                root = { _id: "root", item: null, relation: null, type: "root", parent: null, children: [root] };
                root.children[0].parent = root;
            }
            if (!root.children) root.children = [];
            root.children.push({ _id: project.item._id, item: project.item, type: project.item.type, parent: root })
        }
    })

    // return the root node
    return root;
}

const addChildTeam = (parent, team) => {
    // check if team is already linked to a team
    if (team.parent) {
        // if not same team, create a shallow "clone" to maintain same children lists (have same children list added to both teams -> no need to reiterate)
        if (team.parent.item._id !== team.item._id) {
            const copy = { item: team.item };
            copy.children = [];
            team.children.forEach((child) => {
                copy.children.push(child);
            });
            addChildTeam(parent, copy);
        }
        // otherwise nothing to do
    }
    else {
        // check for circular reference
        const circular = findCircularReference(team, parent);
        if (circular) {
            // mark as circular
            team.circular = circular;
            circular.circular = team;
            // must be a shallow copy already -> remove children to break circular reference
            team.children = null;
        }
        // add team as child of parent
        if (!parent.children) parent.children = [];
        team.parent = parent;
        parent.children.push(team);
    }
}

const findCircularReference = (node, parent = null) => {
    if (!parent) parent = node.parent;
    while (parent) {
        if (parent.item._id === node.item._id) return parent;
        // go up one level
        parent = parent.parent;
    }
    return null;
}

export const buildNetworkLinks = (data, mode, addLabelNodes, include) => {
    // for pure hierarchy mode do not create links
    if (mode == MODE.Hierarchy) return [];

    // iterate data for relations
    var links = [];
    data.forEach(item => {
        // for team mode do not include person links
        //if (item.class === "person" && mode === MODE.Teams) return;
        // dismiss filtered items
        if (item.class === "team" && !include.team[item.type]) return;
        // add line management relation (only exists for persons) / always primary
        if (item.line && include.relation.line) {
            links.push({ source: item._id, target: item.line, type: "line" });
        }
        // add leader relations / always primary
        if (item.leader && include.relation.leader) {
            item.leader.forEach(leader => {
                const ref = data.find(element => element._id === leader._id);
                if (ref.class !== "team" || include.team[ref.type]) {
                    links.push({ source: item._id, target: leader._id, type: "leader" });
                }
            });
        }
        // add membership relations (only relevant for network mode)
        if (mode === MODE.Network && item.membership && item.class === "team") item.membership?.forEach(member => {
            // check relations (not primary team always have primary relations)
            if ((item.type === "primary" && include.relation[member.type]) ||
                (item.type !== "primary" && include.relation.primary)) {
                    const ref = data.find(element => element._id === member._id);
                    if (ref.class !== "team" || include.team[ref.type]) {
                        var id = member._id;
                        if (mode === MODE.Teams && member.type === "contributor") id = member._id + "/" + item._id;
                        links.push({ source: item._id, target: id, type: member.type });
                    }
            }
        });
        // add projects relations (only relevant for network mode)
        if (mode === MODE.Network && item.projects && item.class === "team") item.projects?.forEach(project => {
            const ref = data.find(element => element._id === project._id);
            if (include.project[ref.type]) {
                var id = project._id;
                links.push({ source: item._id, target: id, type: "project" });
            }
        });
        // add label links (only relevant for network mode)
        if (mode == MODE.Network && addLabelNodes) links.push({ source: item._id, target: item._id + "_l", type: "label"});
    });
    return links;
}

export const initHierarchyChart = (root, mode, dimensions) => {
    const padding = 10;

    const graph = d3.hierarchy(root);
    graph.sum(d => (d.item?.class === "team" || d.item?.class === "project") ? 2 : (d.type === "contributor") ? 0.5 : 1);
    graph.sort((a, b) => { return b.height - a.height || b.value - a.value });

    var pack = d3.pack().size([dimensions.x, dimensions.y]).padding(padding);
    pack(graph);

    if (mode === MODE.Teams) {
        for (const descendant of graph) {
            if (descendant.data.type === "label" || (descendant.data.item?.class === "person" && descendant.parent.data.item) || (descendant.data.item?.class === "project" && descendant.parent.data.item)) {
                descendant.tx = descendant.parent.x - descendant.x;
                descendant.ty = descendant.parent.y - descendant.y;
            }
        }
    }

    /*
    if (mode === MODE.Teams) {
        graph.descendants(d => d.item.class === "person")
            .attr("translateX", d => d.parent.x - d.x)
            .attr("translateY", d => d.parent.y - d.y)
    }
    */

    return graph;
}

export const drawHierarchyChart = (graph, mode, svg, onSelect) => {
    // TODO : remove root from nodes for teams and network view
    var data = graph.descendants();
    if (mode !== MODE.Hierarchy) data = data.filter((item) => item.parent !== null);

    const g = svg.selectAll("g.graph").append("g").attr("class", "nodes");

    const nodes = g.selectAll("g")
        .data(data)
        .join("g")
        .attr("transform", d => "translate(" + d.x + "," + d.y + ")");

    nodes.append("circle")
        .attr("id", d => d.data.item ? "node-" + d.data.item._id + "-" + d.data.type : "node-undefined")
        .attr("r", d => d.data.type === "leader" ? d.r * 0.8 : d.r)
        .attr("fill",  d => {
            if (d.data.circular) return "red";
            if (!d.data.item || d.data.type === "root" || d.data.type === "label") return null;
            if (mode === MODE.Hierarchy) return itemColorScale(d.data.item.type)
            return itemLightColorScale(d.data.item.type);
        })
        .attr("fill-opacity", d => !d.parent || d.data.type === "label" ? 0 : (mode === MODE.Hierarchy) ? 0.1 : 1)
        .attr("stroke",  d => {
            if (d.data.circular) return "red";
            if (!d.data.item || d.data.type === "label") return null;
            if (d.data.type === "leader" && d.data.item?.class === "person") return leaderColor;
            return itemColorScale(d.data.item.type)
        })
        .attr("stroke-width", 2)
        .attr("stroke-dasharray", d => strokeDashScale(d.data.type))
        .on("click", (event, d) => onSelect(d))
        .style("cursor", "pointer");

    var imageNodes = nodes.filter(d => { return (mode === MODE.Network || d.data.item?.class === "project" || d.data.item?.class === "person") && d.data.type != "label" });

    imageNodes.append("clipPath")
        .attr("id", d => "clip-" + d.data.item._id + "-" + d.data.type)
        .append("use")
        .attr("xlink:href", d => "#node-" + d.data.item._id + "-" + d.data.type);

    imageNodes.append("text")
        .attr("clip-path", d => "url(#clip-" + d.data.item._id + "-" + d.data.type)
        .attr("textLength", d => d.data.type === "leader" ? d.r * 1.6 : d.r * 2)
        .attr("lengthAdjust", "spacingAndGlyphs")
        .style("cursor", "pointer")
        .on("click", (event, d) => onSelect(d) )
        .selectAll("tspan")
        .data(d => d.data.item.name.split(/^\b(\w+)\b(.*)\b(\w+)\b/g))
        .enter().append("tspan")
        .attr("x", 0)
        .attr("y", (d, i, nodes) => 19 + (i - nodes.length / 2 - 0.5) * 15)
        .text(d => d);

    imageNodes.append("svg:image")
        .attr("xlink:href", d => d.data.item.picture ? IMAGE_URL + d.data.item.picture : null)
        .attr("width", d => d.data.type === "leader" ? d.r * 1.6 : d.r * 2)
        .attr("height", d => d.data.type === "leader" ? d.r * 1.6 : d.r * 2)
        .attr("x", d => d.data.type === "leader" ? -d.r * 0.8 : -d.r)
        .attr("y", d => d.data.type === "leader" ? -d.r * 0.8 : -d.r)
        .attr("clip-path", d => "url(#clip-" + d.data.item._id + "-" + d.data.type)
        .style("cursor", "pointer")
        .on("click", (event, d) => onSelect(d) )

    var leaderNodes = nodes.filter(d => d.data.type === "leader");
    leaderNodes.append("circle")
        .attr("r", d => d.r)
        .attr("fill",  d => {
            return itemColorScale(d.data.item.type)
        })
        .attr("fill-opacity", 0)
        .attr("stroke",  d => {
            if (d.data.circular) return "red";
            if (!d.data.item || d.data.type === "label") return null;
            if (d.data.type === "leader" && d.data.item?.class === "person") return leaderColor;
            return itemColorScale(d.data.item.type)
        })
        .attr("stroke-width", 1)
        .attr("stroke-dasharray", d => strokeDashScale(d.data.type))
        .style("cursor", "pointer")
        .on("click", (event, d) => onSelect(d) )

    var labelNodes = nodes.filter(d => d.data.type === "label");

    labelNodes.append("text")
        .attr("clip-path", d => "url(#clip-" + d.data.item._id + "l)")
        //.attr("textLength", d => d.r * 2)
        //.attr("lengthAdjust", "spacingAndGlyphs")
        .selectAll("tspan")
        .data(d => d.data.item.name.split(/^\b(\w+)\b(.*)\b(\w+)\b/g))
        .enter().append("tspan")
        .attr("x", 0)
        .attr("y", (d, i, nodes) => 19 + (i - nodes.length / 2 - 0.5) * 15)
        .text(d => d);

    nodes.append("title")
        .text(d => d.data.item ? d.data.item.name : "");

    return nodes;
}

export const drawNetworkLinks = (data, svg) => {
    const g = svg.selectAll("g.graph").append("g").attr("class", "links");

    const links = g.selectAll("path")
        .data(data)
        .enter()
        .append("path")
        .attr("stroke", d => linkColorScale(d.type))
        .attr("stroke-width", d => strokeScale(d.type))
        .attr("stroke-dasharray", d => strokeDashScale(d.type))
        .attr("fill", "none");

    return links;
}

export const addDragDropZoom = (node, svg, simulation) => {
    // add drag and drop
    const click = (event, d) => {
        delete d.fx;
        delete d.fy;
        d3.select(this).classed("fixed", false);
        simulation.alpha(0.1).restart();
    }
    
    const dragged = (event, d) => {
        d.fx = event.x;
        d.fy = event.y;
        simulation.alpha(0.1).restart();
    }        

    const dragended = (event, d) => {
        d.fx = undefined;
        d.fy = undefined;
    }

    const drag = d3.drag().on("drag", dragged).on("end", dragended);
    node.call(drag).on("click", click);

    // add zoom behaviour
    const zoomed = (event) => {
        svg.select("g").attr("transform", event.transform)
    }

    const zoom = d3.zoom().on("zoom", zoomed);

    svg.call(zoom);
}

export const addSimulation = (nodes, links, mode, dimensions, svg) => {
    // in team mode, separate simulation nodes and links from display nodes and links
    var simNodes = nodes.filter(d => d.tx === undefined);
    var simLinksData = [];
    links.each(link => {
        const source = nodes.filter(d => d.data._id === link.source).datum();
        const target = nodes.filter(d => d.data._id === link.target).datum();
        link.source = source;
        link.target = target;
        const linkSource = source.tx === undefined ? source : source.parent;
        const linkTarget = target.tx === undefined ? target : target.parent;
        simLinksData.push({source: linkSource, target: linkTarget, type: link.type});
    })

    // setup and run simulation
    const simulation = d3.forceSimulation()
        .nodes(simNodes.data())
        .force("spring", springForce().links(simLinksData))
        .force("center", d3.forceCenter(dimensions.x / 2, dimensions.y / 2)
            .strength(1)
        )
        .force("collision", d3.forceCollide().radius(d => d.r * 1.2))
        /*
        .force("charge", d3.forceManyBody()
            .strength(d => forceStrengthScale(d.data.item?.class))
            //.strength(d => d.data.type !== "label" && (d.data.item.class !== "person" || !d.parent.data.item) ? 200 : 0)
            //.strength(d => d.data.item.class === "person" ? 0 : -2)
            //.distanceMax(200)
            //.distanceMax(d => d.class === "team" && d.type === "primary" ? 50 : 500)
            .distanceMin(30)
            .distanceMax(500)
        )
        .force("link", d3.forceLink().links(simLinksData)
            .strength(d => linkStrengthScale(d.type))
            .distance(d => linkDistanceScale(d.type))
        )
        */
        /*
        .force("x", d3.forceX()
            .x(d => d.tx === undefined ? dimensions.x / 2 : d.parent.x)
            .strength(d => d.tx === undefined ? 0.05 : 0.5)
        )
        .force("y", d3.forceY()
            .y(d => d.ty === undefined ? dimensions.y / 2 : d.parent.y)
            .strength(d => d.ty === undefined ? 0.05 : 0.5)
        )
        */

    simulation.on("tick", () => {
        const minmax = {
            minx: dimensions.x,
            maxx: 0,
            miny: dimensions.y,
            maxy: 0
        };
        simNodes
            .attr("transform", d => {
                updateMinMax(minmax, d.x, d.y, d.r);
                return "translate(" + d.x + "," + d.y +")"
            })
        nodes.filter(d => d.tx !== undefined)
            .attr("transform", d => {
                d.x = d.parent.x + d.tx;
                d.y = d.parent.y + d.ty;
                return "translate(" + d.x + "," + d.y + ")"
            })
        links
            .attr("d", d => lineGenerator([[d.source.x, d.source.y], [d.target.x, d.target.y]]));
        zoomToFit(svg, dimensions, minmax);
    }).alphaDecay(0.01);

    return simulation;
}

/*
const getIdFromNode = (node) => {
    return node.data._id;
}
*/

const updateMinMax = (minmax, x, y, r) => {
    if (x - r < minmax.minx) minmax.minx = x - r;
    if (x + r > minmax.maxx) minmax.maxx = x + r;
    if (y - r < minmax.miny) minmax.miny = y - r;
    if (y + r > minmax.maxy) minmax.maxy = y + r;
}

const zoomToFit = (svg, dimensions, minmax) => {
    const margin = 10;
    const extend = {
        x: minmax.maxx - minmax.minx + 2 * margin,
        y: minmax.maxy - minmax.miny + 2 * margin
    };
    if (extend.x > 0 || extend.y > 0) {
        var scale = extend.x / dimensions.x;
        if (extend.y / dimensions.y > scale) scale = extend.y / dimensions.y;
        const start = {
            x: minmax.minx - ((dimensions.x * scale) - extend.x) / 2 - margin,
            y: minmax.miny - ((dimensions.y * scale) - extend.y) / 2 - margin
        }
        const viewBox = [start.x, start.y, dimensions.x * scale, dimensions.y * scale];
        svg.attr("viewBox", viewBox);
    }
}

const springForce = () => {
    var nodes;
    var links = [];
    var unlinked = [];
    var distance = 200;
    var springForce = 0.1;

    const force = () => {
        // repell nodes with no link
        //var total = 0;
        //var ok = 0;
        for (var i = 0; i < unlinked.length; ++i) {
            updateUnlinked(unlinked[i][0], unlinked[i][1]);
            //total += error;
            //if (error == 0) ok++; 
        }
        //console.log("unlinked error", total / unlinked.length, ok);
        //total = 0;
        // attract nodes with a link
        for (i = 0; i < links.length; i++) {
            updateLinked(links[i].source, links[i].target, links[i].type);
            //total += error;
        }
        // console.log("links distance", total / links.length);
    }

    force.initialize = function(_nodes) {
        nodes = _nodes;
        initializeUnlinked();
    }

    force.links = (_links) => {
        links = _links;
        return force;
    }

    const initializeUnlinked = () => {
        console.log("determine unlinked", nodes.length, links.length);
        for (var i = 0; i < nodes.length; ++i) {
            var nodeA = nodes[i];
            for (var j = i + 1; j < nodes.length; j++) {
                var nodeB = nodes[j];
                if (!isLinked(nodeA, nodeB)) {
                    unlinked.push([nodeA, nodeB]);
                }
            }
        }
        console.log("unlinked", unlinked.length);
    }

    const updateUnlinked = (nodeA, nodeB) => {
        var min = (nodeA.r + nodeB.r) * 1.2;
        var x = nodeA.x - nodeB.x;
        var y = nodeA.y - nodeB.y;
        var dist = Math.sqrt(x * x + y * y) - min;
        if (dist < distance) {
            // isolate team nodes from other nodes not belonging to the team
            var mult = nodeA.data.item.class === "team" || nodeB.data.item.class === "team" ? 1 : 0.1;
            // error = normalized distance
            var error = (distance - dist) / distance;
            var forceX = x * error * springForce * mult;
            var forceY = y * error * springForce * mult;
            nodeA.vx += forceX;
            nodeA.vy += forceY;
            nodeB.vx -= forceX;
            nodeB.vy -= forceY;
            return error;
        }
        return 0;
    }

    const updateLinked = (nodeA, nodeB, type) => {
        var min = (nodeA.r + nodeB.r) * 1.2;
        var x = nodeA.x - nodeB.x;
        var y = nodeA.y - nodeB.y;
        var dist = Math.sqrt(x * x + y * y) - min;
        if (dist > 0) {
            var mult = linkStrengthScale(type);
            //var mult = (type === "primary" || type === "label") ? 1 : type === "leader" ? 0.5 : 0.1;
            var error = Math.sqrt(dist / distance);
            var forceX = x * error * springForce * mult;
            var forceY = y * error * springForce * mult;
            nodeA.vx -= forceX;
            nodeA.vy -= forceY;
            nodeB.vx += forceX;
            nodeB.vy += forceY;
        }
        return dist;
    }

    const isLinked = (nodeA, nodeB) => {
        for (var i = 0; i < links.length; ++i) {
            var link = links[i];
            if ((link.source === nodeA && link.target === nodeB) || (link.source === nodeB && link.target === nodeA)) {
                return true;
            }
        }
        return false;
    }

    return force;
}