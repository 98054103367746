import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Editable, EditableInput, EditablePreview, HStack, IconButton, Text } from "@chakra-ui/react";
import { useDrag, useDrop } from "react-dnd";
import { MdDragIndicator } from "react-icons/md";
import { RiCloseLine } from "react-icons/ri";

export const CardTypes = {
    ACTIVITY: 'activity'
}

export const RoleActivityCard = ({ activity, moveItem, findItemIndex, onChange, onRemove }) => {
    const [ text, setText ] = useState(activity.description);
    const originalIndex = findItemIndex(activity);
    const [{ isDragging }, drag] = useDrag(() => ({
        type: CardTypes.ACTIVITY,
        item: { activity, originalIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        end: (item, monitor) => {
            const { activity: droppedItem, originalIndex} = item;
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                moveItem(droppedItem, originalIndex);
            }
            else {
                console.log("save move");
                if (onChange) onChange();
            }
        }
    }), [ activity, originalIndex, moveItem]);
    const [, drop] = useDrop(() => ({
        accept: CardTypes.ACTIVITY,
        hover({ activity: draggedItem }) {
            if (draggedItem !== activity) {
                const overIndex = findItemIndex(activity);
                moveItem(draggedItem, overIndex);
            }
        }
    }), [findItemIndex, moveItem]);

    const handleSubmit = (nextValue) => {
        setText(nextValue);
        activity.description = nextValue;
        console.log("save update");
        if (onChange) onChange();
    }

    const opacity = isDragging ? 0 : 1;

    return <HStack w="full" ref={(node) => drag(drop(node))} gap={0} spacing={1} style={{opacity: opacity}} border={"1px dashed gray"} p="0" mb="0.25rem" bg="white" cursor="move">
        <MdDragIndicator />
        <Editable w="full" defaultValue={text ? text : ""} fontSize="xs" placeholder="add an activity description" _placeholder={{color: 'gray.200'}} onSubmit={handleSubmit}>
            <Text as={EditablePreview} mx={1} color={text ? "black" : "gray.200"} />
            <EditableInput px={1} py={0.5} my={0.5} />
        </Editable>
        { onRemove &&
            <IconButton minW="1.5rem" icon={<RiCloseLine />} variant="link" size="md" onClick={onRemove} />
        }
    </HStack>
}

RoleActivityCard.propTypes = {
    activity: PropTypes.object.isRequired,
    moveItem: PropTypes.func.isRequired,
    findItemIndex: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onRemove: PropTypes.func
}

export default RoleActivityCard;