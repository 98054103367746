// User Service - get User Information
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useAPI } from './APIService';
//import { useEffect, useState } from 'react';

export const useCurrentUser = () => {
    const [user, setUser] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const { getPersonById } = useAPI();
    const auth0 = useAuth0();
    
    useEffect(() => {
        let subscribed = true;
        setIsLoading(true);
    
        if (auth0) {
            const id = auth0.user.sub.split("|", 2)[1];
            getPersonById(id).then(
                result => {
                    if (subscribed) {
                        setIsLoading(false);
                        setUser(result);
                    }
                },
                e => {
                    setIsLoading(false);
                    setError(e);
                }
            )
        }
    
        return function cleanup() {
          subscribed = false
        }
    }, [])

    return [user, error, isLoading];
}

export const useOrganizations = () => {
    const [organizations, setOrganizations] = useState([]);
    const [current, setCurrent] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const { getOrganizations } = useAPI();
    const auth0 = useAuth0();

    useEffect(() => {
        let subscribed = true;
        setIsLoading(true);
    
        if (auth0) {
            getOrganizations().then(
                result => {
                    if (subscribed) {
                        setIsLoading(false);
                        setOrganizations(result.organizations);
                        setCurrent(result.current);
                    }
                },
                e => {
                    setIsLoading(false);
                    setError(e);
                }
            )
        }
    
        return function cleanup() {
            subscribed = false
        }
    }, [])

    return [organizations, current, error, isLoading];
}
