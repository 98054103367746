import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '@chakra-ui/layout';
import NotImplementedLabel from './NotImplementedLabel';

const NotImplementedSection = ({ name }) => {
    return (
        <Box textAlign="center">
            <NotImplementedLabel />
            <Text fontSize="1rem">{name}</Text>
        </Box>
    )
}

NotImplementedSection.propTypes = {
    name: PropTypes.string
}

export default NotImplementedSection;