import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertIcon, Box, Button, ButtonGroup, Image, Text, Tooltip, VStack } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';

const OQScoreDimensionText = ({ parameter = null, changeTab }) => {

    return <Box>
        { !parameter &&
            <VStack width={'full'} textAlign={'left'} fontSize={'xs'} spacing={2}>
                <Alert status='info'>
                    <AlertIcon />
                    Select an item from the chart to get more information about the parameter.
                </Alert>
            </VStack>
        }
        { parameter &&
            <VStack textAlign={'center'} spacing={2}>
                <Image boxSize={10} src={'../icons/' + parameter.name + '.png'} alt={parameter.name} />
                <Text fontWeight={"bold"}>{parameter.name}</Text>
                <Text>Your Personal OQ-Score:</Text>
                <Text fontSize={'xl'} fontWeight={'extrabold'}>{parameter.getOQScore()}</Text>
                <Box fontSize={'xs'} textAlign={'justify'}>
                    { parameter.name === 'Big Picture & Strategy' &&
                        <Text>
                            Introduce the WHY to the Organization.
                            Contrast Projects & People.
                            Establish a basis for Factual Discussions.
                            Big Picture of what is moving your Company.
                            Acknowledgement of People (Who)
                            Acceptance of the Organizational Status (Why).
                            Commitment to act/change.
                        </Text>
                    }
                    { parameter.name === 'Workforce Utilization' &&
                        <Text>
                            Foster a Sense of Responsibility (for the Organization).
                            Understand required Activities, Skills, Roles.
                            Challenge Needs vs. Skills vs. Capacities.
                            Uncover Hidden Potentials.
                            Clear Definition of Roles & Responsibilities.
                            Understanding of Organisational Bottlenecks/Shortages.
                            New Opportunities unlocked for People (I) and Company (We).
                            Compassion for each other.
                        </Text>
                    }
                    { parameter.name === 'Work Management' &&
                        <Text>
                            Connect the Organization consistently (vertically and horizontally).
                            Learn about relevant Communication Flows.
                            Improve the Sense for Priorities and Risks.
                            Experience Planning and Delivery as an Organizational Exercise.
                            Collective experience / trial & error.
                            Linking of Strategy, Coordination, and Operations.
                            Transparency on Activities, Risks, and Opportunities (Basis for better Decision Making).
                            Framework for Self-Organisation (Needs & Self Efficacy).
                            Clarity about the whole (body of operations).
                        </Text>
                    }
                    { parameter.name === 'Performance & Improvements' &&
                        <Text>
                            Learn to ask the Right Questions (to improve your organisation).
                            (Measure the organizational health)
                            See where and how you can adjust your organizational setup. (aspirations)
                            (Understand organizational needs)
                            Involve Everyone in Embracing New Concepts.
                            (Engage in becoming better as a whole)
                            Everyone sees how the organization is operating and performing.
                            The intelligence of the organization becomes tangible.
                            Clarity about actions for improvements.
                            Certainty about the impact of change.
                            Passion for the organization.
                            (Sense of Responsibility for the Organization)
                        </Text>
                    }
                    { parameter.name === 'Collaboration Model' &&
                        <Text>
                            Improve continuously based on actual situations.
                            Continuous reevaluation of dependencies.
                            Actively shape / continuously / with everyone.
                            Curiosity / Responsiveness / Reactivity / Learning.
                            Transform &quot;staff&quot; into a learning organization.
                            Day to day operations / perpertuity
                            Strengthen improve
                            Unleash creativity to find best solutions.
                            Commitment / Consistent
                            Trust and self-confidence in how to behave and act.
                            Increasing Self-Organization based on organizational needs.
                            Culture as basis for gradually growing organizational intelligence.
                            Fusion of goals and people.
                            Collective Intelligence for the best.
                        </Text>
                    }
                    { parameter.name === 'Unity in Shared Goals' &&
                        <Text>
                            Unity in shared goals is a sign of <b>Alignment</b>, where everyone’s actions and efforts are focused on the same goals. This creates a sense of unity and synergy and translates into enhanced collaboration, minimized conflict, and maximized overall productivity. 
                            It is a key to <b>Motivation and Engagement</b>. It provides a sense of purpose and meaning, as employees see how their contributions contribute to the larger vision. This motivation drives higher levels of productivity and commitment to achieving the shared goals. 
                        </Text>
                    }
                    { parameter.name === 'Directness in Communication' &&
                        <Text>
                            Communication must be frank, sincere, pure to be <b>Efficient and Respectful</b>. It also must be emotional to transfer the passion for topics.
                            Direct communication can be translated into better <b>Conflict Mitigation</b>, as potential conflicts and misunderstandings can be addressed proactively and in a timely manner and no time or resources are wasted. It minimizes the chances of misinterpretation or miscommunication, fostering a more harmonious and productive work environment. 
                        </Text>
                    }
                    { parameter.name === 'Effectiveness in Decision Making' &&
                        <Text>
                            Balance timely and thorough decisions.
                            Efficient decision making is crucial for boosting your business as it enables faster problem-solving, optimal resource allocation, increased productivity, competitive advantage, better risk management, enhanced customer satisfaction, and fosters adaptability and innovation. By making efficient decisions, you can drive growth, profitability, and long-term success for your business. 
                        </Text>
                    }
                    { parameter.name === 'Transparency in Activities' &&
                        <Text>
                            Transparancy builds <b>Trust</b>.
                            Transparency on activities and opportunities creates a culture of <b>Openness and Accountability</b>, fosters trust among stakeholders, and positions your business as a reliable and reputable entity. It can lead to increased customer satisfaction, collaboration, and opportunities for growth, ultimately boosting your business&apos;s success in the long run.
                        </Text>
                    }
                    { parameter.name === 'Availability of Intuitive Tools' &&
                        <Text>
                            Technology helps to make sense of complexity.
                            Technology empowers organizations to harness data, improve collaboration, automate processes, foster learning, gain a competitive advantage, monitor performance, and enhance customer experience. These benefits contribute significantly to increasing organizational intelligence and driving success in today&apos;s dynamic business environment.
                        </Text>
                    }
                    { parameter.name === 'Accessibility of Knowledge' &&
                        <Text>
                            Access of knowledge is about creating learning processes that goes beyond information sharing.
                            Open structures support skill building and sometimes support or replace costly internal systems, Increased collaboration and harnessing of creativitiy are likely benefits of open collaboration. Access to open knowlegde clearly boosts diverse perspectives and provides an effective access to new development.
                        </Text>
                    }
                </Box>
                <Text textAlign={'justify'} fontSize={'xs'}>The overall OQ Score is determined the combination of both dimensions. For analysis of appropriate measured a detailed score drills into the intersection of both dimensions.</Text>
            </VStack>
        }
        <ButtonGroup variant={"outline"} size={"xs"} colorScheme='green' mt={3} mb={3}>
            <Tooltip label='Open overall score.'>
                <Button onClick={() => changeTab(0)} leftIcon={<ArrowLeftIcon />}>Overall Results</Button>
            </Tooltip>
            <Tooltip label='Open detailed scores for combined dimensions.'>
                <Button onClick={() => changeTab(3)} rightIcon={<ArrowRightIcon />}>Detailed Results</Button>
            </Tooltip>
        </ButtonGroup>
    </Box>         

}

OQScoreDimensionText.propTypes = {
    parameter: PropTypes.object,
    changeTab: PropTypes.func
}

export default OQScoreDimensionText;