import React from 'react';
import PropTypes from 'prop-types';
import PersonEmblem from '../emblem/PersonEmblem.js';
import { HStack, Text, VStack } from '@chakra-ui/layout';

const PersonListItem = ({ item, size, onClick }) => {
    //if (!key) key=item._id;
    return (
        <HStack w="full" onClick={ onClick } cursor="pointer" textAlign="center">
            <PersonEmblem item={item} displayName={false} size={size} isLinked={false} />
            <VStack>
                <Text>{item.name}</Text>
                <Text fontSize="xs">{item.title}</Text>
            </VStack>
        </HStack>
    )
}

PersonListItem.propTypes = {
    item: PropTypes.object.isRequired,
    size: PropTypes.number,
    onClick: PropTypes.func
}

export default PersonListItem;