import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ButtonGroup, Text, Tooltip, VStack } from '@chakra-ui/react';
import { ArrowRightIcon } from '@chakra-ui/icons';

const OQScoreText = ({ score, changeTab }) => {

    return <VStack textAlign={'center'} spacing={2}>
        <Text>Your Personal Overall OQ-Score:</Text>
        <Text fontSize={'xl'} fontWeight={'extrabold'}>{score}</Text>
        <Box fontSize={'xs'} textAlign={'justify'}>
            <Text>
                The overall OQ Score provides an understanding of where your organization stands in general. An average intelligent organization scores 100.
            </Text>
            <Text>
                The overall OQ Score does not provide reasons for scoring high or low, but it helps you follow up on your organization&apos;s overall performance.
            </Text>
            <Text>
                Implementing appropriate measures in various domains can help increase the overall score.
            </Text>
        </Box>
        <Box fontSize={'xs'} textAlign={'justify'}>
            { score < 75 &&
                <>
                    <Text fontWeight={'bold'}>Attention! Your OQ Score is pretty low.</Text>
                    <Text>It looks like you are lacking fundamentals, which would turn you into a more intelligent organization.</Text>
                </> }
            { score >= 75 && score < 85 &&
                <>
                    <Text fontWeight={'bold'}>Warning! Your OQ Score is significantly below the average.</Text>
                    <Text>Even though few things already work out, there is plenty of stuff which must be improved to make your organization acting more intelligently.</Text>
                </> }
            { score >= 85 && score < 95 &&
                <>
                    <Text fontWeight={'bold'}>Be careful! Your OQ Score is a bit below the average.</Text>
                    <Text>Even though you are not completely off, there are several things to be improved to become a rmore intelligent organization.</Text>
                </> }
            { score >= 95 && score <= 105 &&
                <>
                    <Text fontWeight={'bold'}>Your OQ Score is pretty much average.</Text>
                    <Text>You have developed some organizational intelligence, which is good. Anyhow, to become more successful you should continue to improve your organizational intelligence beyond an average score.</Text>
                </> }
            { score > 105 && score <= 115 &&
                <>
                    <Text fontWeight={'bold'}>Well done! Your OQ Score is above the average.</Text>
                    <Text>You are on the right track. Even though there is still plenty of room for improvements, you can consider several things already to be working out as they should.</Text>
                </> }
            { score > 115 && score <= 130 &&
                <>
                    <Text fontWeight={'bold'}>Congratulations! Your OQ Score is significantly over the average.</Text>
                    <Text>Your organizational intelligence in general looks pretty good. It is not perefect right now, as several aspects still can be improved, but you are moving in the right direction.</Text>
                </> }
            { score > 130 &&
                <>
                    <Text fontWeight={'bold'}>Impressive! Your OQ Score is far above the average.</Text>
                    <Text>You have implemented significant aspects, which turn your organization into an intelligent organization. That should be an incentive to improve even further. How far will you get?</Text>
                </> }
        </Box>
        <Text textAlign={'justify'} fontSize={'xs'}>The OQ Score is determined by two separate dimensions. To better understand your score, review the results of the dimensions individually.</Text>
        <ButtonGroup variant={"outline"} size={"xs"} colorScheme='green' mt={3} mb={3}>
            <Tooltip label='Open detailed scores from values dimension.'>
                <Button onClick={() => changeTab(1)} rightIcon={<ArrowRightIcon />}>Values Dimension</Button>
            </Tooltip>
            <Tooltip label='Open detailed scores from implementation dimension.'>
                <Button onClick={() => changeTab(2)} rightIcon={<ArrowRightIcon />}>Implementation Dimension</Button>
            </Tooltip>
        </ButtonGroup>
    </VStack>                                

}

OQScoreText.propTypes = {
    score: PropTypes.number,
    changeTab: PropTypes.func
}

export default OQScoreText;