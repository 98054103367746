import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAPI } from '../../hooks/APIService';
import Spinner from '../../components/spinner/Spinner.js';
import PersonListItem from '../../components/listitem/PersonListItem.js';
import { Heading, VStack } from '@chakra-ui/layout';
import TeamListItem from '../../components/listitem/TeamListItem';
import ProjectListItem from '../../components/listitem/ProjectListItem';

const SearchResults = () => {
    const navigate = useNavigate();
    const { searchItems } = useAPI(); 
    const [result, setResult] = useState(null);
    const params = useParams();

    useEffect(() => {
        loadResults();
    }, [params.query]);

    const loadResults = async() => {
        const data = await searchItems(params.query);
        setResult(data);
    }

    const openTeam = (id) => {
        navigate('/team/' + id);
    }

    const openPerson = (id) => {
        navigate('/person/' + id);
    }

    const openProject = (id) => {
        navigate('/project/' + id);
    }

    if (!result) {
        return <Spinner />
    }
    return (
        <VStack h="full" justify="start" spacing="4" mt="8">
            <Heading fontSize="md">Search Results ({result == undefined ? 0 : result.length})</Heading>
            <VStack>
            { result.map(item => {
                if (item.class == "team") return <TeamListItem item={item} key={item._id} onClick={() => { openTeam(item._id)} } />
            })}
            { result.map(item => {
                if (item.class == "person") return <PersonListItem item={item} key={item._id} onClick={() => { openPerson(item._id)} } />
            })}
            { result.map(item => {
                if (item.class == "project") return <ProjectListItem item={item} key={item._id} onClick={() => { openProject(item._id)} } />
            })}
            </VStack>
        </VStack>
    )
}

export default SearchResults;