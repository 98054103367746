import React, { useState, useContext } from 'react';
import { TeamContext } from './Team.js';
import PersonSelector from '../../components/selector/PersonSelector.js';
import { RiAddCircleLine, RiCloseLine } from 'react-icons/ri';
import { Divider, Heading, HStack, VStack, Wrap } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Button, IconButton, useToast } from '@chakra-ui/react';
import StickerLoader from '../../components/sticker/StickerLoader.js';
import ItemSelector from '../../components/selector/ItemSelector.js';
import RoleListing from '../role/RoleListing.js';
import ProjectSelector from '../../components/selector/ProjectSelector.js';

const TeamMembers = () => {
    const { team, updateTeamProperty } = useContext(TeamContext);
    const [ leader, setLeader ] = useState(team.leader ? team.leader : []);
    const [ membership, setMembership ] = useState(team.membership ? team.membership : []);
    const [ projects, setProjects ] = useState(team.projects ? team.projects : []);
    const [ selector, setSelector ] = useState(null);
    const [ leaderSelector, setLeaderSelector ] = useState(null);
    const [ projectSelector, setProjectSelector ] = useState(null);
    const toast = useToast();

    const resetSelector = () => {
        setSelector(null);
    }

    const addLeader = (item) => {
        console.log("add leader", item);
        const isLeader = leader.some(leader => leader._id == item._id);
        if (isLeader) {
            toast({
                title: 'Already leader.',
                description: item.name + " is already leader for the team.",
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
            return;
        }
        const update = [...leader, { _id: item._id }];
        updateTeamProperty("leader", update);
        setLeader(update);
        setLeaderSelector(null);
    }

    const removeLeader = (id) => {
        console.log("remove leader", id);
        if (!team.leader) return;
        const update = team.leader.filter(leader => leader._id !== id);
        updateTeamProperty("leader", update);
        setLeader(update);
    }

    const addMember = (person) => {
        console.log("addMember: " + JSON.stringify(person));
        const isMember = membership.some(member => member._id == person._id);
        if (isMember) {
            setSelector(null);
            return;
        }
        const update = [...membership, { _id: person._id, type: selector.reftype }];
        updateTeamProperty("membership", update);
        setMembership(update);
        setSelector(null);
    }

    const removeMember = (id) => {
        console.log("removeMember: " + id);
        const update = team.membership.filter(member => member._id !== id);
        updateTeamProperty("membership", update);
        setMembership(update);
    }

    const addProject = (project) => {
        console.log("add project", project);
        const isMember = projects.some(member => member._id == project._id);
        if (isMember) {
            setSelector(null);
            return;
        }
        const update = [...projects, { _id: project._id }];
        updateTeamProperty("projects", update);
        setProjects(update);
        setSelector(null);
    }

    const removeProject = (id) => {
        console.log("remove project", id);
        const update = team.project.filter(member => member._id !== id);
        updateTeamProperty("projects", update);
        setProjects(update);
    }

    return (
        <VStack spacing={4} w="full">
            <VStack w="full" align={"left"}>
                <HStack mb={2}>
                    <Heading fontSize="md">Leader(s)</Heading>
                    <Button size="xs" leftIcon={<RiAddCircleLine />} fontWeight="normal" variant="link" onClick={ () => setLeaderSelector(true) } >
                        add a leader
                    </Button>
                </HStack>
                { (leader.length > 0) &&
                    <Wrap spacing={2} overflow="visible" w="full">
                        {leader.map(leader => {
                            return <StickerLoader id={leader._id} key={"leader" + leader._id} element={<RoleListing team={team} person={leader} type={"leader"} />} size={3}>
                                <IconButton isRound icon={<RiCloseLine />} size="xs" variant="solid" onClick={ (e) => { removeLeader(leader._id); e.stopPropagation(); } } />
                            </StickerLoader>
                        })}
                    </Wrap>
                }
            </VStack>
            <Divider />
            <VStack w="full" align={"left"}>
                <HStack mb={2}>
                    <Heading fontSize="md">
                        { team.type === "primary" && "Team" }
                        { team.type === "workgroup" && "Workgroup" }
                        { team.type === "community" && "Community" }
                        &nbsp;Members
                    </Heading>
                    <Button size="xs" leftIcon={<RiAddCircleLine />} fontWeight="normal" variant="link" onClick={ () => setSelector({type: team.type, reftype: team.type})}>
                        add a team member
                    </Button>
                </HStack>
                <Wrap spacing={2} overflow="visible" w="full">
                    {membership.map(member => {
                        if (member.type != "contributor") {
                            return <StickerLoader id={member._id} key={"member" + member._id} size={3} element={<RoleListing team={team} person={member} type={"membership"} />}>
                                <IconButton isRound icon={<RiCloseLine />} size="xs" variant="solid" onClick={ (e) => { removeMember(member._id); e.stopPropagation(); } } />
                            </StickerLoader>
                        }
                    })}
                </Wrap>
            </VStack>
            {team.type === "primary" &&
                <>
                    <Divider />
                    <VStack w="full" align={"left"} pb={"1rem"}>
                        <HStack mb={2}>
                            <Heading fontSize="md">Contributors</Heading>
                            <Button size="xs" leftIcon={<RiAddCircleLine />} fontWeight="normal" variant="link" onClick={ () => setSelector({type: team.type, reftype: "contributor"})}>
                                add a contributor
                            </Button>
                        </HStack>
                        <Wrap spacing={2} overflow="visible" w="full">
                            {membership.map(member => {
                                if (member.type == "contributor" || member.type == undefined) {
                                    return <StickerLoader id={member._id} key={"member" + member._id} size={3} element={<RoleListing team={team} person={member} type={"membership"} />}>
                                        <IconButton isRound icon={<RiCloseLine />} size="xs" variant="solid" onClick={ (e) => { removeMember(member._id); e.stopPropagation(); } } />
                                    </StickerLoader>
                                }
                            })}
                        </Wrap>
                    </VStack>
                    <Divider bg={"black"} borderWidth={1} py={0.2} />
                    <VStack w="full" align={"left"}>
                        <HStack mb={2}>
                            <Heading fontSize="md">Projects</Heading>
                            <Button size="xs" leftIcon={<RiAddCircleLine />} fontWeight="normal" variant="link" onClick={ () => setProjectSelector(true)}>
                                assign a project
                            </Button>
                        </HStack>
                        <Wrap spacing={2} overflow="visible" w="full">
                            {projects.map(project => {
                                return <StickerLoader id={project._id} key={"project" + project._id} size={3}>
                                    <IconButton isRound icon={<RiCloseLine />} size="xs" variant="solid" onClick={ (e) => { removeProject(project._id); e.stopPropagation(); } } />
                                </StickerLoader>
                            })}
                        </Wrap>
                    </VStack>
                </>
            }
            { selector &&
                <Modal isOpen={selector} onClose={resetSelector}>
                    <ModalOverlay />
                    <ModalContent color="black">
                        <ModalHeader>Select a Person...</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <PersonSelector onSelect={(person) => addMember(person)} />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
            { leaderSelector &&
                <Modal isOpen={leaderSelector} onClose={() => setLeaderSelector(null)}>
                    <ModalOverlay />
                    <ModalContent color="black">
                        <ModalHeader>Select a Leader...</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <ItemSelector onSelect={ addLeader } />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
            { projectSelector &&
                <Modal isOpen={projectSelector} onClose={() => setProjectSelector(null)}>
                    <ModalOverlay />
                    <ModalContent color="black">
                        <ModalHeader>Select a Project...</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <ProjectSelector onSelect={ addProject } />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </VStack>
    )
}

export default TeamMembers;