import React, { useEffect, useState, createContext } from 'react';
import { useParams } from 'react-router';
import { useAPI } from '../../hooks/APIService';
import TeamMembers from './TeamMembers.js';
import Spinner from '../../components/spinner/Spinner.js';
import Card from '../../components/card/Card';
import TeamProfile from './TeamProfile';
import TeamObjectives from './TeamObjectives';
import TeamProcedures from './TeamProcedures';
import TeamRequests from './TeamRequests';
import TeamLeaders from './TeamLeaders';
import { getItemColor } from '../../theme';
import TeamCardHeader from './TeamCardHeader';
import { HStack } from '@chakra-ui/react';

export const TeamContext = createContext();

const Team = () => {
    const [team, setTeam] = useState(null);
    const params = useParams();
    const api = useAPI();

    useEffect(() => {
        loadTeam();
    }, []);

    const loadTeam = async() => {
        const team = await api.getTeamById(params.id);
        setTeam(team);
    }

    const updateTeamProperty = (property, value) => {
        console.log("update team property", team, property, value);
        var map = {};
        map[property] = value;
        api.patchTeam(team._id, map);
        team[property] = value;
        setTeam(team);
    }

    const deleteTeam = () => {
        console.log("delete team", team);
        api.deleteTeam(team._id);
        setTeam(null);
    }

    if (!team) {
        return <Spinner />
    }
    return(
        <HStack w="full" h="full" gap={"5%"} pl={5} pr={5} justify="space-around" align="center"
            bgImage="url('/background.svg')" bgSize="cover" bgPosition="center" >
            <TeamContext.Provider value={{ team, setTeam, updateTeamProperty, deleteTeam }}>
                <Card color={getItemColor(team)} key={team._id}>
                    <TeamCardHeader />
                    <TeamProfile />
                    <TeamLeaders />
                    <TeamProcedures />
                    <TeamObjectives />
                    <TeamRequests />
                </Card>
                <TeamMembers />
            </TeamContext.Provider>
        </HStack>)
}

export default Team;