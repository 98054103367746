import { React, useState } from "react";
import { useAPI } from "../../hooks/APIService";
import { Alert, AlertDescription, AlertIcon, Box, Button, ButtonGroup, Center, FormControl, FormErrorMessage, FormLabel, Heading, Input, Textarea } from "@chakra-ui/react";

const NewsEditor = () => {
    const { sendNews } = useAPI();
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [sent, setSent] = useState(false);
    const [count, setCount] = useState(0);
    const [error, setError] = useState(null);
    
    const handleSubjectChange = event => setSubject(event.target.value);
    const handleMessageChange = event => setMessage(event.target.value);

    const handleSubmit = event => {
        event.preventDefault();
        setSubmitted(true);
        if (!error || !error.subject || !error.message) {
            sendMessage(true);
        }
    }

    const handleSend = event => {
        event.preventDefault();
        setSubmitted(true);
        if (!error || !error.subject || !error.message) {
            sendMessage(false);
        }
    }

    const sendMessage = (dryRun) => {
        sendNews({
            subject: subject,
            message: message
        }, {dryRun: dryRun})
        .then(data => {
            setCount(data.count);
            if (!dryRun) setSent(true);
            setError(null);
        })
        .catch(error => {
            setCount(0);
            console.log('ERROR: ' + error);
            setError({general: error.message});
        });
    }

    const onReset = () => {
        setSubject("");
        setMessage("");
        setError(null);
        setSubmitted(false);
        setSent(false);
        setCount(0);
    }

    return <Center h="full" w="full" flexDirection="column">
        <Heading mb={8}>Compose a message for all users</Heading>
            <form name="composeNewsForm" className="ComposeNews Form" onSubmit={handleSubmit}>
                { submitted && error?.general &&
                    <Box my={4}>
                        <Alert status="error" borderRadius={4}>
                            <AlertIcon />
                            <AlertDescription>{error?.general}</AlertDescription>
                        </Alert>
                    </Box>
                }
                <FormControl id="name-input" mb="2" display="flex" isRequired isInvalid={error?.subject}>
                    <FormLabel fontSize="xs">Subject</FormLabel>
                    <Input placeholder="Subject" aria-label="Subject" size="sm" focusBorderColor="green.500" variant="flushed" value={subject} onChange={handleSubjectChange} />
                    <FormErrorMessage>{error?.subject}</FormErrorMessage>
                </FormControl>
                <FormControl id="email-input" mb="2" display="flex" isRequired isInvalid={error?.message}>
                    <FormLabel fontSize="xs">Message</FormLabel>
                    <Textarea placeholder="Message" aria-label="Message" size="sm" cols={80} focusBorderColor="green.500" variant="flushed" value={message} onChange={handleMessageChange} />
                    <FormErrorMessage>{error?.message}</FormErrorMessage>
                </FormControl>
                <ButtonGroup size="sm" mt={8} mb={8}>
                    <Button type="submit" isDisabled={!subject||!message}>Test Run</Button>
                    <Button type="button" onClick={handleSend} isDisabled={!subject||!message||sent}>Send News</Button>
                    <Button type="button" onClick={onReset} variant="outline">Reset</Button>
                </ButtonGroup>
                { submitted && count > 0 && !error &&
                    <Box my={4}>
                        <Alert status="success" borderRadius={4}>
                            <AlertIcon />
                            <AlertDescription>{count} mails successfully sent! {sent && "To send another message, reset the form."}</AlertDescription>
                        </Alert>
                    </Box>
                }
            </form>
        </Center>
}

export default NewsEditor;
