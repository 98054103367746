import { React, useState } from "react";
import { useAPI } from "../../hooks/APIService";
import { Alert, AlertDescription, AlertIcon, Box, Button, ButtonGroup, Heading, Text, VStack } from "@chakra-ui/react";
import UpdateEntryForm from "./UpdateEntryForm";

const FEATURE_TYPE = "Feature";
const BUGFIX_TYPE = "Bugfix";

const UpdateEditor = () => {
    const { sendUpdate } = useAPI();
    const [features, setFeatures] = useState([]);
    const [bugfixes, setBugfixes] = useState([]);
    const [type, setType] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [sent, setSent] = useState(false);
    const [count, setCount] = useState(0);
    const [error, setError] = useState(null);

    const onAdd = (subject, description) => {
        console.log("handle add", type, subject, description);
        if (type === FEATURE_TYPE) {
            const update = [...features];
            update.push({
                subject: subject,
                description: description
            })
            console.log("features", update);
            setFeatures(update);
        }
        else if (type === BUGFIX_TYPE) {
            const update = [...bugfixes];
            update.push({
                subject: subject,
                description: description
            })
            console.log("bugfixes", update);
            setBugfixes(update);
        }
        setType(null);
    }

    const onCancel = () => {
        setType(null);
    }

    const handleSubmit = event => {
        event.preventDefault();
        setSubmitted(true);
        if (!error) sendMessage(true);
    }

    const handleSend = event => {
        event.preventDefault();
        setSubmitted(true);
        if (!error) sendMessage(false);
    }

    const sendMessage = (dryRun) => {
        let message = {};
        if (features.length > 0) message.features = features;
        if (bugfixes.length > 0) message.bugfixes = bugfixes;
        sendUpdate(message, {dryRun: dryRun})
        .then(data => {
            setCount(data.count);
            if (!dryRun) setSent(true);
            setError(null);
        })
        .catch(error => {
            setCount(0);
            console.log('ERROR: ' + error);
            setError({general: error.message});
        });
    }

    const onReset = () => {
        setFeatures([]);
        setBugfixes([]);
        setType(null);
        setError(null);
        setSubmitted(false);
        setSent(false);
        setCount(0);
    }

    return <VStack p={4} height="full" width="full" alignItems="start">
        <Heading>Compose a product update for all users</Heading>
        { submitted && error?.general &&
            <Box my={4}>
                <Alert status="error" borderRadius={4}>
                    <AlertIcon />
                    <AlertDescription>{error?.general}</AlertDescription>
                </Alert>
            </Box>
        }
        <VStack alignItems="start" p={4}>
            <Heading size="sm">New/Updated Features</Heading>
            { features.map((feature, index) => {
                return <VStack key={"feature" + index} alignItems="start" p={2}>
                    <Heading size="xs">{feature.subject}</Heading>
                    <Text>{feature.description}</Text>
                </VStack>
            })}
            { type === FEATURE_TYPE && <UpdateEntryForm title={"Add " + type} onSubmit={onAdd} onCancel={onCancel} /> }
            { !type &&
                <Button type="button" size="xs" onClick={() => setType(FEATURE_TYPE)} isDisabled={type}>Add Feature</Button>
            }
        </VStack>
        <VStack alignItems="start" p={4}>
            <Heading size="sm">Bugfixes</Heading>
            { bugfixes.map((bugfix, index) => {
                return <VStack key={"bugfix" + index} alignItems="start" p={2}>
                    <Heading size="xs">{bugfix.subject}</Heading>
                    <Text>{bugfix.description}</Text>
                </VStack>
            })}
            { type === BUGFIX_TYPE && <UpdateEntryForm title={"Add " + type} onSubmit={onAdd} onCancel={onCancel} /> }
            { !type &&
                <Button type="button" size="xs" onClick={() => setType(BUGFIX_TYPE)} isDisabled={type}>Add Bugfix</Button>
            }
        </VStack>
        { !type &&
            <form name="sendUpdateForm" className="SendUpdate Form" onSubmit={handleSubmit}>
                <ButtonGroup size="sm" mt={8} mb={8}>
                    <Button type="submit" isDisabled={features.length === 0 || bugfixes.length === 0}>Test Run</Button>
                    <Button type="button" onClick={handleSend} isDisabled={features.length === 0 || bugfixes.length === 0 || sent}>Send News</Button>
                    <Button type="button" onClick={onReset} variant="outline">Reset</Button>
                </ButtonGroup>
            </form>
        }
        { submitted && count > 0 && !error &&
            <Box my={4}>
                <Alert status="success" borderRadius={4}>
                    <AlertIcon />
                    <AlertDescription>{count} mails successfully sent! {sent && "To send another message, reset the form."}</AlertDescription>
                </Alert>
            </Box>
        }
    </VStack>
}

export default UpdateEditor;