import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/layout';

const EmblemBadge = ({ color, top = true, left = true, fill = true, children }) => {

    var topPos = top ? 2 : null;
    var bottomPos = !top ? 2 : null;
    var leftPos = left ? 2 : null;
    var rightPos = !left ? 2 : null;
    var bg = fill ? color : "white";
    var border = fill ? "white" : color;

    return (
        <Box p="0 0" position="absolute" top={topPos} left={leftPos} right={rightPos} bottom={bottomPos} zIndex={0}
            w="auto" borderRadius="0.6rem" bg={bg} borderColor={border} borderWidth={1}>
            {children}
        </Box>
    )
}

EmblemBadge.propTypes = {
    color: PropTypes.string.isRequired,
    top: PropTypes.bool,
    left: PropTypes.bool,
    fill: PropTypes.bool,
    children: PropTypes.node
}

export default EmblemBadge;