import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { Box, Button } from '@chakra-ui/react';
import { CardTypes, RoleActivityCard } from './RoleActivityCard';
import { RiAddCircleLine } from 'react-icons/ri';
import { useAPI } from '../../hooks/APIService';

const RoleActivityList = ({ role, onUpdate }) => {
    const [ activities, setActivities ] = useState([]);
    const { patchRole } = useAPI();

    useEffect(() => {
        const sorted = role.activities ? role.activities : [];
        sorted.sort((a, b) => a.rank - b.rank);
        console.log("sorted", sorted);
        setActivities(sorted);
    }, []);

    const addActivity = async () => {
        console.log("add activity");
        const nextRank = activities.length > 0 ? activities[activities.length - 1].rank + 1 : 0;
        const update = [...activities, { description: "", rank: nextRank, key: nextRank }];
        await patchRole(role._id, { activities: update });
        setActivities(update);
        if (onUpdate) onUpdate(role, update);
    };

    const findItemIndex = (item) => {
        //const item = activities.filter((c) => c.id === id)[0];
        return activities.indexOf(item);
    };

    const moveItem = (item, atIndex) => {
        const index = findItemIndex(item);
        console.log("move item", item, index, atIndex);
        const update = [...activities];
        const rank = update[index].rank;
        update[index].rank = update[atIndex].rank;
        update[atIndex].rank = rank;
        update.sort((a, b) => a.rank - b.rank);
        console.log("new order", update);
        setActivities(update);
    };

    const removeActivity = async (item) => {
        const index = findItemIndex(item);
        console.log("remove item", item, index);
        const update = activities.filter((test) => test !== item);
        console.log("on remove", update);
        setActivities(update);
        await patchRole(role._id, { activities: update });
    }

    const saveChange = async () => {
        console.log("on change", activities);
        await patchRole(role._id, { activities: activities });
    }

    const [ , drop ] = useDrop(() => ({ accept: CardTypes.ACTIVITY }))

    return <Box ref={drop} w="full">
        {activities.map(activity => {
            return <RoleActivityCard key={activity.key} activity={activity} moveItem={moveItem} findItemIndex={findItemIndex} onChange={saveChange} onRemove={() => removeActivity(activity)} />
        })}
        <Button size="xs" leftIcon={<RiAddCircleLine />} fontWeight="normal" variant="link" onClick={addActivity}>
            add an activity
        </Button>
    </Box>
}

RoleActivityList.propTypes = {
    role: PropTypes.object.isRequired,
    onUpdate: PropTypes.func
}

export default RoleActivityList;