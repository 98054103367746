import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAPI } from '../../hooks/APIService';
import { Alert, AlertDescription, AlertIcon, Box, Button, HStack, Input, InputGroup, InputRightAddon, Text, VStack } from '@chakra-ui/react';
import { AddIcon, CheckIcon } from '@chakra-ui/icons';
import RoleEditor from './RoleEditor';
import RoleTag from '../../components/tag/RoleTag';

const RoleDialog = ({ type, onSelect }) => {
    const [ search, setSearch ] = useState("");
    const [ roles, setRoles ] = useState([]);
    const [ selected, setSelected ] = useState(null);
    const { getRoles, addRole } = useAPI();
    const [ error, setError ] = useState(null);

    useEffect(() => {
        loadRoles();
    }, []);

    const loadRoles = async() => {
        console.log("load roles", type);
        const roles = await getRoles({ type: type });
        roles.sort((a, b) => a.name.localeCompare(b.name));
        setRoles(roles);
    }

    const onOpen = (role) => {
        console.log("open", role);
        setSelected(role);
    }

    const handleDelete = async () => {
        await loadRoles();
        setSelected(null);
    }

    const createRole = () => {
        const role = { name: search, class: "role", type: type };
        addRole(role).then(data => {
            role._id = data;
            setSelected(role);
            setError(null);
            setSearch("");
        }).catch(error => {
            console.log('ERROR: ' + error);
            setError("error creating person in database: " + error);
        });
    }

    return <HStack w="full" align="start">
        <VStack align={"left"} pr={2} borderRight={"1px"}>
            <InputGroup size="sm" maxW="15rem">
                <Input placeholder="Search/Create a new role" value={search} autoFocus={true} variant="flushed" onChange={(e) => setSearch(e.target.value)} />
                { search.length > 0 && !roles.some(role => role.name.toLowerCase() === search.toLowerCase()) &&
                    <InputRightAddon onClick={createRole}>
                        <AddIcon />
                    </InputRightAddon>
                }
            </InputGroup>
            { error?.general &&
                <Box my={4}>
                    <Alert status="error" borderRadius={4}>
                        <AlertIcon />
                        <AlertDescription>{error?.general}</AlertDescription>
                    </Alert>
                </Box>
            }
            <VStack align="left" overflow={scroll}>
                { roles.map(role => {
                    // show if no search specified and name contains search string
                    if ((search.length == 0 || role.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)) {
                        return <RoleTag role={role} key={role._id} onClick={onOpen} />
                    }
                })}
            </VStack>
        </VStack>
        <VStack w="70%" h="full" alignContent={"start"}>
            { !selected &&
                <Text fontSize="sm" color="gray">select a role from the left or create a new role</Text>
            }
            { selected &&
                <>
                    <Button size="xs" leftIcon={<CheckIcon />} variant={'outline'} onClick={() => onSelect(selected)}>select for current person</Button>
                    <RoleEditor role={selected} afterDelete={handleDelete} />
                </>
            }
        </VStack>
    </HStack>;
}

RoleDialog.propTypes = {
    type: PropTypes.string.isRequired,
    onSelect: PropTypes.func
}

export default RoleDialog;
