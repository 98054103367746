import React from 'react';
import PropTypes from 'prop-types';
import { Text } from "@chakra-ui/react";

const Paragraphs = ({ text, color = "black", italic = false }) => {

    return (
        <>
            { text.split(/\r?\n/).map( (line, index) => {
                return (
                    <Text key={ "line" + index } bgColor="transparent" minHeight="1rem" pt={0}
                        color={color} fontStyle={italic} lineHeight="1rem" mt={0}>
                        {line}
                    </Text>
                )
            })}
        </>
    )

}

Paragraphs.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
    italic: PropTypes.bool
}

export default Paragraphs;