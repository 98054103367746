import React, { useContext, useRef, useState } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/layout';
import { getItemColor, getTextColor } from '../../theme';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import EditableText from '../../components/editable/EditableText';
import { PersonContext } from './Person';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import OptionSelector from '../../components/selector/OptionSelector';

const PersonCardHeader = () => {
    const {person, updatePersonProperty, isCurrent, deletePerson} = useContext(PersonContext);
    const [type, setType] = useState(person.type);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const { logout } = useAuth0();
    const navigate = useNavigate();

    const onTypeSelect = (type) => {
        console.log("type select", type);
        updatePersonProperty("type", type);
        setType(type);
    }

    const onDelete = () => {
        console.log("delete person", person);
        deletePerson();
        onClose();
        if (isCurrent()) logout({ returnTo: window.location.origin });
        else if (navigate.length > 0) navigate(-1);
        else navigate("/");
    }

    const color = getItemColor(person);
    const textColor = getTextColor(person);

    return <HStack justify="center" alignContent="center" p={1}>
        <Box w="5%"></Box>
        <VStack gap={0} spacing={1} width="90%" color={textColor}>
            <EditableText item={person} property="name" textAlign="center" useTheme={true} />
            <OptionSelector options={["permanent", "temporary"]} onSelect={onTypeSelect}>
                <Text fontSize="xs">- {type?.toUpperCase()} -</Text>
            </OptionSelector>
        </VStack>
        <Tooltip hasArrow label={"Delete Person permanently" } fontSize="xs" placement="top-start" bg={color} color={textColor}>
            <Box w="5%">
                <IconButton minW="1.5rem" icon={<RiDeleteBin2Fill />} variant="link" size="md" onClick={onOpen} color={textColor} />
            </Box>
        </Tooltip>
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='md' fontWeight='bold'>
                        Delete Person
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Text>You will remove this person <u>permanently</u> from this organization. This cannot be undone afterwards.</Text>
                        <Text>Are you sure?</Text>
                    </AlertDialogBody>
                    <AlertDialogFooter justifyContent="center">
                        <Button ref={cancelRef} onClick={onClose}>Cancel</Button>
                        <Button colorScheme='red' onClick={onDelete} ml={3}>Delete</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </HStack>
}

export default PersonCardHeader;