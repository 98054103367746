import { React } from 'react';
import { useContext, useEffect, useState } from "react";
import { PersonContext } from "./Person";
import Icon from "@chakra-ui/icon";
import { HStack, Text } from "@chakra-ui/layout";
import { MdSupervisorAccount } from 'react-icons/md';
import { useAPI } from '../../hooks/APIService';
import PersonEmblem from '../../components/emblem/PersonEmblem';

const PersonLine = () => {
    const {person} = useContext(PersonContext);
    const [inCharge, setInCharge] = useState(null);
    const {getPersons} = useAPI();

    useEffect(() => {
        loadLineReports();
    }, []);

    const loadLineReports = async () => {
        const list = await getPersons({line: person._id});
        setInCharge(list);
    }

    const color = inCharge?.length > 0 ? null : "gray.200";
    const title = inCharge?.length > 0 ? "In Charge Of" : "No Line Responsibility";

    return (
        <HStack justifyItems="start" alignItems="start" spacing="4" width="full">
            <HStack spacing="1" color={color}>
                <Icon as={MdSupervisorAccount} />
                <Text fontSize="xs">{title}</Text>
            </HStack>
            { (inCharge?.length > 0) &&
                <HStack spacing="1">
                    { inCharge.map( (item) => {
                        return <PersonEmblem key={item._id} item={item} size={2} displayName={true}/>
                    })}
                </HStack>
            }
        </HStack>
    );
}

export default PersonLine;