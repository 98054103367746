import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '@chakra-ui/layout';
import Icon from '@chakra-ui/icon';

const MenuButton = ({ icon, label, action = null, disabled = false, size = 5 }) => {
    disabled = (action == null) ? true : disabled;
    const color = disabled ? "#ccc" : "black"; 
    //const index = Math.floor(Math.random() * 8);
    return <Box as="button" onClick={action} disabled={disabled} color={color} minW={10} _hover={() => { if (!disabled) return { color: "white", bg: color } }}>
        <Icon as={icon} boxSize={size} />
        <Text fontSize="xx-small" lineHeight="1">{label}</Text>
    </Box>
}

MenuButton.propTypes = {
    icon: PropTypes.func,
    label: PropTypes.string,
    action: PropTypes.func,
    disabled: PropTypes.bool,
    size: PropTypes.number
}

export default MenuButton;