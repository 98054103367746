import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme.js';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import WallImage from './containers/wall/WallImage';
import OrganizationForm from './containers/organizations/OrganizationForm.js';
import Auth0ProviderWithHistory from './components/auth/Auth0ProviderWithHistory.js';
import OQScanner from './containers/oqscanner/OQScanner.js';
import OQScannerCodes from './containers/oqscanner/OQScannerCodes.js';
import OQScannerLicenses from './containers/oqscanner/OQScannerLicenses.js';

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

console.log("strict mode", StrictMode);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Auth0ProviderWithHistory>
            <Routes>
              <Route exact path="/signup" element={<OrganizationForm/>} />
              <Route exact path="/oq-scanner/:code?" element={<OQScanner/>} />
              <Route exact path="/oq-scanner/codes" element={<OQScannerCodes/>} />
              <Route exact path="/oq-scanner/licenses" element={<OQScannerLicenses/>} />
              <Route exact path="/wall" element={<WallImage/>} />
              <Route path="*" element={<App />} />
            </Routes>
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </DndProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
