import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Text } from '@chakra-ui/react';

const OQScannerIntroduction = ({ onProceed }) => {

    return <Stack spacing={"2rem"}>
        <Stack spacing={5} textAlign={"justify"}>
            <Text>
                Organizational Intelligence (OQ) refers to the capabilities of an organization to gather, analyze, and utilize information effectively to make informed decisions and achieve its goals.
                It encompasses the ability to effectively disseminate information throughout the organization, ensuring that relevant insights reach the right people at the right time. This requires establishing efficient communication channels, fostering a culture of knowledge sharing, and promoting collaboration among employees.
                It is a crucial aspect of thriving in today&apos;s rapidly evolving business landscape, enabling organizations to adapt, compete, and succeed.
            </Text>
            <Text>
                With this OQ Scanner you can test, how much your organization complies with the need to maximize organizational intelligence.
                We will provide you with different statements about your organization.
                You will have to select those statements, which are describing the status of your organization best.
            </Text>
            <Text>
                Based on your answers you will get a summary of the organizational intelligence of your organization.
                It will tell you your OQ - a relative value showing your organizations&apos; intelligence compared to other organizations.
                Furthermore you will receive insights about what can be done, to increase the organizational intelligence in your organization.
            </Text>
        </Stack>
        <Button onClick={onProceed}>Get Started</Button>
    </Stack>
    
}

OQScannerIntroduction.propTypes = {
    onProceed: PropTypes.func.isRequired
}

export default OQScannerIntroduction;