import React from 'react';
import PropTypes from 'prop-types';
import { getCssColor, getItemColor } from '../../theme';
import { Box } from '@chakra-ui/layout';
import RoundedBox from '../shapes/RoundedBox';

const EmptyMiniCard = ({ size = 6, children, onClick }) => {

    const color = getItemColor(null);

    return <Box cursor="pointer" onClick={ () => { if (onClick) onClick() } }>
        <RoundedBox size={size} radius="10%" color={color} cssColor={getCssColor(null)} thickness={size * 0.025}>
            {children}
        </RoundedBox>
    </Box>
}

EmptyMiniCard.propTypes = {
    size: PropTypes.number,
    onClick: PropTypes.func,
    children: PropTypes.node
}

export default EmptyMiniCard;