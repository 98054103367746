import React, { useState } from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import MenuSection from './MenuSection.js';
import MenuButton from './MenuButton.js';
import { MdGroupAdd, MdPersonAdd } from 'react-icons/md';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import PersonForm from './PersonForm.js';
import TeamForm from './TeamForm.js';
import ProjectForm from './ProjectForm.js';
import { HiViewGridAdd } from 'react-icons/hi';

const ItemActions = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ content, setContent ] = useState();

    const handlePerson = () => {
        setContent( {
            title: "Add Person",
            body: <PersonForm onClose={onClose} />
        });
        onOpen();
    }

    const handleTeam = () => {
        setContent( {
            title: "Add Team",
            body: <TeamForm onClose={onClose} />
        });
        onOpen();
    }

    const handleProject = () => {
        setContent( {
            title: "Add Project",
            body: <ProjectForm onClose={onClose} />
        });
        onOpen();
    }

    return(
        <MenuSection>
            <MenuButton icon={MdPersonAdd} label="Add Person" action={handlePerson} />
            <MenuButton icon={MdGroupAdd} label="Add Team" action={handleTeam} />
            <MenuButton icon={HiViewGridAdd} label="Add Project" action={handleProject} />
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent color="black">
                    <ModalHeader>{content?.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        { content?.body }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </MenuSection>
    )
}

export default ItemActions;