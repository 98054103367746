import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertIcon, Box, Button, ButtonGroup, Text, Tooltip, VStack } from '@chakra-ui/react';
import { ChatIcon } from '@chakra-ui/icons';

const OQScoreRecommendations = () => {

    const requestRecommendationsQuote = () => {
        console.log("request a quote for recommendations");
        location.href = "mailto:service@leapfroq.com"
            + "?subject="
            + "OQ-Scanner: Quote for Recommendations License"
            + "&body="
            + "Hi,%0D%0A%0D%0AI am interested in recommended measures for the top areas for improvements found by OQ-Scanner.%0D%0APlease send me a non-binding offer.%0D%0A%0D%0AThank you.";
    }

    return <VStack alignItems={'flex-start'} spacing={5} pb={5}>
        <Text>Recommended Measures</Text>
        <Alert status='warning' fontSize={'xs'}>
            <AlertIcon />
            <Box>
                <Text>Your personal license does not include recommendations.</Text>
                <Text>If you would like to see recommendation for the determined areas for improvements, please request an extended license.</Text>
            </Box>
        </Alert>
        <ButtonGroup variant={"outline"} size={"xs"} colorScheme='orange' mt={3} mb={3}>
            <Tooltip label='Request a quote for extending your license to include recommendations.'>
                <Button onClick={requestRecommendationsQuote} leftIcon={<ChatIcon />}>Request Quote</Button>
            </Tooltip>
        </ButtonGroup>

    </VStack>

}

OQScoreRecommendations.propTypes = {
    option: PropTypes.object
}

export default OQScoreRecommendations;