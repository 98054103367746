// API Data Service - making use of the remote API
import { useAuth0 } from '@auth0/auth0-react';
//import { useEffect, useState } from 'react';

export function useAPI() {
    const API_PATH = process.env.REACT_APP_PROXY + process.env.REACT_APP_API_PATH;
    const { getAccessTokenSilently } = useAuth0();

    const getData = async (url = '') => {
        //console.log("HTTP GET Request", url);
        var token = null;
        try {
            token = await getAccessTokenSilently();
        } catch (error) {
            console.log("no access token", error)
        } 
        // Default options are marked with *
        const header = {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }
        if (token) header.headers.Authorization = `Bearer ${token}`;
        const response = await fetch(url, header);
        //console.log('HTTP GET Response:', response.status, response.statusText);
        if (!response.ok) {
            try {
                const obj = await response.json();
                if (obj?.message) console.log("Server Error Message:", obj.message);
                if (obj?.error) console.log("Server Error:", obj.error);
            } catch (error) {
                console.log("Server Error: failed to read message", error);
            }
            throw new Error('Network error: [' + response.status + '] ' + response.statusText);
        }
        return await response.json(); // parses JSON response into native JavaScript objects
    }

    const postData = async (url = '', data = {}) => {
        console.log("HTTP POST Request", url, data);
        var token = null;
        try {
            token = await getAccessTokenSilently();
        } catch (error) {
            console.log("no access token", error)
        }
        // Default options are marked with *
        const header = {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        };
        if (token) header.headers.Authorization = `Bearer ${token}`;
        const response = await fetch(url, header);
        console.log('HTTP POST Response:', response.status, response.statusText);
        if (!response.ok) {
            try {
                const obj = await response.json();
                if (obj?.message) console.log("Server Error Message:", obj.message);
                if (obj?.error) console.log("Server Error:", obj.error);
            } catch (error) {
                console.log("Server Error: failed to read message");
            }
            throw new Error('Network error: [' + response.status + '] ' + response.statusText);
        }
        return await response.json(); // parses JSON response into native JavaScript objects
    }

    const postForm = async (url = '', formData = null) => {
        console.log("HTTP POST Request (Form) ", url, formData);
        // Default options are marked with *
        var token = null;
        try {
            token = await getAccessTokenSilently();
        } catch (error) {
            console.log("no access token", error)
        }
        const header = {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Accept': 'multipart/form-data'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: formData // body data type must match "Content-Type" header
        };
        if (token) header.headers.Authorization = `Bearer ${token}`;
        const response = await fetch(url, header);
        console.log('HTTP POST Response (File):', response.status, response.statusText);
        if (!response.ok) {
            try {
                const obj = await response.json();
                if (obj?.message) console.log("Server Error Message:", obj.message);
                if (obj?.error) console.log("Server Error:", obj.error);
            } catch (error) {
                console.log("Server Error: failed to read message");
            }
            throw new Error('Network error: [' + response.status + '] ' + response.statusText);
        }
        return await response.json(); // parses JSON response into native JavaScript objects
    }

    const putData = async (url = '', data = {}) => {
        console.log("HTTP PUT Request", url, data);
        var token = null;
        try {
            token = await getAccessTokenSilently();
        } catch (error) {
            console.log("no access token", error)
        }
        // Default options are marked with *
        const header = {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        };
        if (token) header.headers.Authorization = `Bearer ${token}`;
        const response = await fetch(url, header);
        console.log('HTTP PUT Response:', response.status, response.statusText);
        if (!response.ok) {
            try {
                const obj = await response.json();
                if (obj?.message) console.log("Server Error Message:", obj.message);
                if (obj?.error) console.log("Server Error:", obj.error);
            } catch (error) {
                console.log("Server Error: failed to read message");
            }
            throw new Error('Network error: [' + response.status + '] ' + response.statusText);
        }
        //return await response.json(); // parses JSON response into native JavaScript objects
    }

    const patchData = async (url = '', data = {}) => {
        console.log("HTTP PATCH Request", url, data);
        var token = null;
        try {
            token = await getAccessTokenSilently();
        } catch (error) {
            console.log("no access token", error)
        }
        // Default options are marked with *
        const header = {
            method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        };
        if (token) header.headers.Authorization = `Bearer ${token}`;
        const response = await fetch(url, header);
        console.log('HTTP PATCH Response:', response.status, response.statusText);
        if (!response.ok) {
            try {
                const obj = await response.json();
                if (obj?.message) console.log("Server Error Message:", obj.message);
                if (obj?.error) console.log("Server Error:", obj.error);
            } catch (error) {
                console.log("Server Error: failed to read message");
            }
            throw new Error('Network error: [' + response.status + '] ' + response.statusText);
        }
        //return await response.json(); // parses JSON response into native JavaScript objects
    }

    const deleteData = async (url = '') => {
        console.log("HTTP DELETE Request", url);
        var token = null;
        try {
            token = await getAccessTokenSilently();
        } catch (error) {
            console.log("no access token", error)
        }
        // Default options are marked with *
        const header = {
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        };
        if (token) header.headers.Authorization = `Bearer ${token}`;
        const response = await fetch(url, header);
        console.log('HTTP DELETE Response:', response.status, response.statusText);
        if (!response.ok) {
            try {
                const obj = await response.json();
                if (obj?.message) console.log("Server Error Message:", obj.message);
                if (obj?.error) console.log("Server Error:", obj.error);
            } catch (error) {
                console.log("Server Error: failed to read message");
            }
            throw new Error('Network error: [' + response.status + '] ' + response.statusText);
        }
        //return await response.json(); // parses JSON response into native JavaScript objects
    }

    const getRandomItems = async (count) => {
        return await getData(API_PATH + '/?random=' + count);
    }

    const getItemById = async (id) => {
        return await getData(API_PATH + "/" + id);
    }

    const getTeamById = async (id) => {
        return await getData(API_PATH + "/team/" + id);
    }

    const getPersonById = async (id) => {
        return await getData(API_PATH + "/person/" + id);
    }

    const getProjectById = async (id) => {
        return await getData(API_PATH + "/project/" + id);
    }

    const getRoleById = async (id) => {
        return await getData(API_PATH + "/role/" + id);
    }

    const searchItems = async (query, itemClass = null, type = null) => {
        var uri = API_PATH + "/search?q=" + query;
        if (itemClass) uri += "&c=" + itemClass;
        if (type) uri += "&t=" + type;
        return await getData(uri);
    }

    const getTeams = async (filter) => {
        if (!filter) return await getData(API_PATH + "/team/");
        else return await getData(API_PATH + "/team/?filter=" + JSON.stringify(filter));
    }

    const getPersons = async (filter) => {
        if (!filter) return await getData(API_PATH + "/person/");
        else return await getData(API_PATH + "/person/?filter=" + JSON.stringify(filter));
    }

    const getProjects = async (filter) => {
        if (!filter) return await getData(API_PATH + "/project/");
        else return await getData(API_PATH + "/project/?filter=" + JSON.stringify(filter));
    }

    const getRoles = async (filter) => {
        if (!filter) return await getData(API_PATH + "/role/");
        else return await getData(API_PATH + "/role/?filter=" + JSON.stringify(filter));
    }

    const getOrganizationNetwork = async () => {
        return await getData(API_PATH + "/network/");    
    }

    const getReport = async (type) => {
        return await getData(API_PATH + "/report/" + type);    
    }

    const addTeam = async (item) => {
        return await postData(API_PATH + "/team", item);
    }

    const addPerson = async (item) => {
        return await postData(API_PATH + "/person", item);
    }

    const addProject = async (item) => {
        return await postData(API_PATH + "/project", item);
    }

    const addRole = async (item) => {
        return await postData(API_PATH + "/role", item);
    }

    const addPicture = async (id, file, fileName) => {
        console.log("upload picture for " + id);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        console.log(formData);
        return await postForm(API_PATH + "/image/" + id, formData);
    }

    const updateTeam = async (item) => {
        await putData(API_PATH + "/team/" + item._id, item);
    }

    const updatePerson = async (item) => {
        await putData(API_PATH + "/person/" + item._id, item);
    }

    const updateProject = async (item) => {
        await putData(API_PATH + "/project/" + item._id, item);
    }

    const updateRole = async (item) => {
        await putData(API_PATH + "/role/" + item._id, item);
    }

    const patchPerson = async (id, values) => {
        await patchData(API_PATH + "/person/" + id, values);
    }

    const patchTeam = async (id, values) => {
        await patchData(API_PATH + "/team/" + id, values);
    }

    const patchProject = async (id, values) => {
        await patchProject(API_PATH + "/project/" + id, values);
    }

    const patchRole = async (id, values) => {
        await patchData(API_PATH + "/role/" + id, values);
    }

    const deleteTeam = async (id) => {
        await deleteData(API_PATH + "/team/" + id);
    }

    const deletePerson = async (id) => {
        await deleteData(API_PATH + "/person/" + id);
    }

    const deleteProject = async (id) => {
        await deleteData(API_PATH + "/project/" + id);
    }

    const deleteRole = async (id) => {
        await deleteData(API_PATH + "/role/" + id);
    }

    const existsUser = async (email) => {
        return await getData(API_PATH + "/user/" + email);
    }

    const getOrganizations = async () => {
        return await getData(API_PATH + "/organizations/");
    }

    const switchOrganization = async (name) => {
        return await putData(API_PATH + "/organization/" + name);
    }

    const createOrganization = async (name) => {
        return await postData(API_PATH + "/organization/" + name);
    }

    const createTicket = async (ticket) => {
        return await postData(API_PATH + "/ticket", ticket)
    }

    // available options:
    //   dryRun::boolean will send the notification only to the current user
    const sendUpdate = async (updates, options = {}) => {
        let url = API_PATH + "/notifications/update";
        if (options.dryRun) url += "?dryRun=true";
        return await postData(url, updates);
    }

    // available options:
    //   dryRun::boolean will send the notification only to the current user
    const sendNews = async (news, options = {}) => {
        let url = API_PATH + "/notifications/news";
        if (options.dryRun) url += "?dryRun=true";
        console.log("send news", url, news);
        return await postData(url, news);
    }

    const getConfig = async () => {
        return await getData(API_PATH + "/config/");
    }

    const getDomains = async () => {
        return await getData(API_PATH + "/oqtest/domains");
    }

    const getValues = async () => {
        return await getData(API_PATH + "/oqtest/values");
    }

    const getStatements = async () => {
        return await getData(API_PATH + "/oqtest/statements");
    }

    const getOQScannerLicense = async (code, email) => {
        return await getData(API_PATH + "/oqtest/license/" + email + "/" + code);
    }

    const getAnswers = async (license, email) => {
        console.log("### get answers", license, email);
        return await getData(API_PATH + "/oqtest/answers/" + license + "/" + email);
    }

    const addStatement = async (statement) => {
        return await postData(API_PATH + "/oqtest/statements", statement);
    }

    const addLicense = async (email, license = { users: 1 }) => {
        console.log("create license", email, license);
        return await postData(API_PATH + "/oqtest/license/" + email, license);
    }

    const addAnswer = async (license, email, answer) => {
        return await postData(API_PATH + "/oqtest/answers/" + license + "/" + email, answer);
    }

    const addScore = async (license, email, score) => {
        return await postData(API_PATH + "/oqtest/score/" + license + "/" + email, score);
    }

    const patchStatementText = async (id, text) => {
        await patchData(API_PATH + "/oqtest/statements/" + id + "/text", { text: text });
    }

    const deleteAnswers = async (license, email) => {
        return await deleteData(API_PATH + "/oqtest/answers/" + license + "/" + email);
    }

    return {
        getRandomItems,
        getItemById,
        getTeamById,
        getPersonById,
        getProjectById,
        getRoleById,
        searchItems,
        getTeams,
        getPersons,
        getProjects,
        getRoles,
        getOrganizationNetwork,
        getReport,
        addTeam,
        addPerson,
        addPicture,
        addProject,
        addRole,
        updateTeam,
        updatePerson,
        updateProject,
        updateRole,
        patchTeam,
        patchPerson,
        patchProject,
        patchRole,
        deleteTeam,
        deletePerson,
        deleteProject,
        deleteRole,
        getOrganizations,
        switchOrganization,
        existsUser,
        createOrganization,
        createTicket,
        sendUpdate,
        sendNews,
        getConfig,
        getDomains,
        getValues,
        getStatements,
        getOQScannerLicense,
        getAnswers,
        addStatement,
        addLicense,
        addAnswer,
        addScore,
        patchStatementText,
        deleteAnswers
    };
}
