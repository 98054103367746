import { React, useEffect, useRef, useState } from 'react';
import { useContext } from "react";
import { PersonContext } from "./Person";
import Icon from "@chakra-ui/icon";
import { Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import { RiEmotionHappyLine } from 'react-icons/ri';
import { IconButton, useToast } from '@chakra-ui/react';
import TalentTag from '../../components/tag/TalentTag';
import { MdAddCircleOutline } from 'react-icons/md';

const PersonInterests = () => {
    const {person, updatePersonProperty, isCurrentUser} = useContext(PersonContext);
    const [ interests, setInterests ] = useState(person.interests === undefined ? [] : person.interests);
    const [ newItem , setNewItem ] = useState(false);
    const toast = useToast();
    const inputRef = useRef();

    useEffect(() => {
        if (inputRef.current) inputRef.current.focus();
    }, [newItem]);

    const onSave = (label) => {
        if (!isCurrentUser) {
            showError("You can add interests only for your own profile.");
            return;
        }

        const index = interests?.findIndex((element) => element.label.toLowerCase() === label.toLowerCase());
        if (index === undefined || index < 0) {
            interests.push({ label: label });
            updatePersonProperty("interests", interests);
            setInterests(interests);
        }
        setNewItem(false);
    }

    const onRemove = (label) => {
        if (!isCurrentUser) {
            showError("You can remove interests only from your own profile.");
            return;
        }
        const index = interests?.findIndex((element) => element.label.toLowerCase() === label.toLowerCase());
        if (index >= 0) {
            console.log("remove interest", label);
            var update = [...interests];
            update.splice(index, 1);
            setInterests(update);
        }
    }

    const showError = (text) => {
        toast({
            title: 'Error',
            description: text,
            status: 'error',
            duration: 5000,
            isClosable: true,
        })
    }

    return (
        <VStack justifyItems="start" alignItems="start" spacing="0" width="full">
            <HStack spacing="1" width="full">
                <Icon as={RiEmotionHappyLine} />
                <Text fontSize="xs" width="full">Personal Interests</Text>
                { isCurrentUser &&
                    <IconButton icon={<MdAddCircleOutline />} variant="ghost" cursor="pointer" size="xs" onClick={() => setNewItem(true)} />
                }
            </HStack>
            <Flex direction="row" gap="2" wrap="wrap" pt={2}>
                { (!interests || interests.length === 0) &&
                    <Text color="gray.300" fontSize="xs" fontStyle="italic">no interests specified</Text>
                }
                { interests?.map( (item, index) => {
                    const ra = (isCurrentUser) ? onRemove : null;
                    return <TalentTag talent={item} key={"interest_" + index} onRemove={ra} />
                })}
                { newItem &&
                    <TalentTag key={"interest_new"} edit onSave={onSave} onExit={() => setNewItem(false)} />
                }
            </Flex>
        </VStack>
    );
}

export default PersonInterests;