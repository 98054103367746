import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '@chakra-ui/react';

const ReportFrame = ({ title = null, subtitle = null, children }) => {

    return (
        <Box textAlign="center">
            <Text fontSize="1em" fontWeight="bold">{title}</Text>
            <Text fontSize="0.7em">{subtitle}</Text>
            {children}
        </Box>
    );

}

ReportFrame.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.element
}

export default ReportFrame;