import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HStack, IconButton, Text } from '@chakra-ui/react';
import { getIndexedColor } from '../../theme';
import { RiCloseLine } from 'react-icons/ri';
import { ChevronRightIcon } from '@chakra-ui/icons';

const RoleTag = ({ role, onClick, onRemove }) => {
    const [ showDelete, setShowDelete ] = useState(false);

    const handleClick = (event) => {
        console.log("clicked", role);
        if (onClick) {
            event.stopPropagation();
            onClick(role);
        }
    }

    const handleRemove = (event) => {
        if (onRemove) {
            event.stopPropagation();
            onRemove(role);
        }
    }

    const getColorCode = (name) => {
        if (!name) return 0;
        let c = 0;
        for (let i = 0; i < name.length; i++) {
            c += name.charCodeAt(i);
        }
        return c % 8;
    }

    const color = getColorCode(role?.name);

    return (
        <HStack spacing="0" onClick={handleClick} cursor={"pointer"} onMouseOver={() => setShowDelete(true)} onMouseOut={() => setShowDelete(false)}>
            <ChevronRightIcon color={getIndexedColor(color)} />
            { role && <Text fontSize="xs">{role.name}</Text> }
            { onRemove && showDelete &&
                <IconButton size="s" m={0} p={0} icon={<RiCloseLine />} variant={'unstyled'} pl={1} cursor="pointer" onClick={handleRemove} /> }
        </HStack>
    );

}

RoleTag.propTypes = {
    role: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    onRemove: PropTypes.func
}

export default RoleTag;