import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure, VStack } from "@chakra-ui/react";
import { MdEdit, MdEmail, MdMessage, MdPhone } from 'react-icons/md';
import { getItemColor } from '../../theme';
import { RiGlobalLine, RiQuestionLine } from 'react-icons/ri';
import { BsMicrosoftTeams } from 'react-icons/bs';

const def = {
    email: {
        icon: MdEmail,
        prefix: "mailto:",
        title: "Send an Email to %n"
    },
    message: {
        icon: MdMessage,
        title: "Send message to %n"
    },
    chat: {
        icon: BsMicrosoftTeams,
        prefix: "https://teams.microsoft.com/l/chat/0/0?users=",
        title: "Chat with %n"
    },
    phone: {
        icon: MdPhone,
        prefix: "tel:",
        title: "Call %n on Phone"
    },
    web: {
        icon: RiGlobalLine,
        title: "Open %s in a new Browser Tab"
    }
}

const ItemContact = ({ item, onChange }) => {
    const [ values, setValues ] = useState(item.contact ? item.contact : []);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    useEffect(() => {
        const index = values.findIndex((element) => element.type === "email");
        if (index < 0 && item.email && item.email.trim().length > 0) {
            const list = [{ type: "email", address: item.email }, ...values];
            setValues(list);
        }
    }, [values]);

    const getLabel = (contact) => {
        const d = def[contact.type];
        if (!d) return "Contact: " + contact.address;
        let title = d.title;
        title = title.replace("%n", item.name);
        title = title.replace("%s", contact.address);
        return title;
    }

    const getIcon = (contact) => {
        const d = def[contact.type];
        if (!d) return RiQuestionLine;
        return d.icon;
    }

    const getAddress = (type) => {
        let address = undefined;
        values.map((element) => {
            if (element.type === type) {
                address = element.address;
            }
        })
        if (!address && type === "email" && item.class === "person") return item.email;
        return address;
    }

    const open = (contact) => {
        const d = def[contact.type];
        const prefix = d.prefix;
        if (prefix) window.open(prefix + contact.address);
        else window.open(contact.address);
    }

    const handleChange = (event) => {
        const type = event.target.name;
        const address = event.target.value.trim();
        const list = [...values];
        const index = list.findIndex(element => element.type === type);
        if (index < 0) {
            if (address.length > 0) {
                console.log("add contact", type, address);
                list.push({ type: type, address: address });
            }
            else {
                console.log("skip contact", type, address);
            }
        }
        else {
            if (address.length > 0) {
                console.log("update contact", type, address);
                list[index].address = address;
            }
            else {
                console.log("remove contact", type, address);
                list.splice(index, 1);
            }
        }
        console.log("changed contacts", list);
        setValues(list);
    }

    const onSave = () => {
        console.log("save contact data");
        onChange([...values]);
        onClose();
    }

    const color = getItemColor(item);

    return (
        <HStack w="full">
            <HStack w="full" justify="space-around">
                { values.map((contact, index) => {
                    return (
                        <Tooltip key={"contact" + index} hasArrow label={getLabel(contact)} fontSize="xs" placement="top-start" bg={color}>
                            <Box onClick={() => open(contact)} p={1}>
                                <Icon as={getIcon(contact)} boxSize={5} />
                            </Box>
                        </Tooltip>
                    )
                })}
                { values.length === 0 &&
                    <Text fontStyle="italic" color="gray.500" fontSize={"xs"}>no contact data</Text>
                }
            </HStack>
            <IconButton icon={<MdEdit />} variant="ghost" cursor="pointer" size="xs" onClick={onOpen}/>
            <Modal isOpen={isOpen} onClose={onClose} size="sm" initialFocusRef={cancelRef}>
                <ModalOverlay />
                <ModalContent color="black">
                    <ModalHeader fontSize="1rem">Contact Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody> 
                        <VStack width="full" gap={2} spacing={1}>
                            <VStack width="full" spacing={0}>
                                <InputGroup size="sm">
                                    <InputLeftElement><Icon as={def.email.icon} /></InputLeftElement>
                                    <Input name="email" type="email" value={getAddress("email")} placeholder="E-Mail Address" onChange={handleChange} />
                                </InputGroup>
                                <Text fontSize="xs" color="gray.400">* changing the email address will change also the login</Text>
                            </VStack>
                            <InputGroup size="sm">
                                <InputLeftElement><Icon as={def.chat.icon} /></InputLeftElement>
                                <Input name="chat" type="email" value={getAddress("chat")} placeholder="E-Mail Address (for MS Teams)" onChange={handleChange} />
                            </InputGroup>
                            { item.class === "person" &&
                                <InputGroup size="sm">
                                    <InputLeftElement><Icon as={def.phone.icon} /></InputLeftElement>
                                    <Input name="phone" type="tel" value={getAddress("phone")} placeholder="Phone Number" onChange={handleChange} />
                                </InputGroup>
                            }
                            <InputGroup size="sm">
                                <InputLeftElement><Icon as={def.web.icon} /></InputLeftElement>
                                <Input name="web" type="url" value={getAddress("web")} placeholder="Website (e.g. Confluence)" onChange={handleChange} />
                            </InputGroup>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button ref={cancelRef} onClick={onClose}>Cancel</Button>
                        <Button colorScheme='green' onClick={onSave} ml={3}>Save</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </HStack>
    );

}

ItemContact.propTypes = {
    item: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

export default ItemContact;