import React from 'react';
import { PropTypes } from 'prop-types';
import { Center } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import TeamInfoBadge from '../../components/emblem/TeamInfoBadge';
import Board from './Board';
import Circle from '../../components/shapes/Circle';
import PersonMiniCard from '../../components/card/PersonMiniCard';
import TeamMiniCard from '../../components/card/TeamMiniCard';
import TeamSizeBadge from '../../components/emblem/TeamSizeBadge';
import ProjectMiniCard from '../../components/card/ProjectMiniCard';
import ProjectInfoBadge from '../../components/emblem/ProjectInfoBadge';

const BoardItem = ( { item, size = Board.GRID_SIZE, onSelect = null, initial = false }) => {

    return <AnimatePresence mode="wait">
        <motion.div key={"display" + item?._id}
            initial={initial ? { scaleX: 0, scaleY: 1, opacity: 0 } : null}
            animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
            exit={{ scaleX: 0, scaleY: 1, opacity: 0 }}
            transition={{ duration: 0.5 }} style={{ height: "100%" }}>
            { !item &&
                <Center>
                    <Circle size={size-3} color="white" shadowColor="#eee" />
                </Center>
            }
            { item?.class === "person" &&
                <PersonMiniCard item={item} size={size*0.8} onClick={onSelect} />
            }
            { item?.class === "team" &&
                <TeamMiniCard item={item} size={size*0.9} onClick={onSelect}>
                    <TeamInfoBadge item={item} />
                    <TeamSizeBadge item={item} />
                </TeamMiniCard>
            }
            { item?.class === "project" &&
                <ProjectMiniCard item={item} size={size*0.9} onClick={onSelect} >
                    <ProjectInfoBadge item={item} />
                </ProjectMiniCard>
            }
        </motion.div>
    </AnimatePresence>

}

BoardItem.propTypes = {
    item: PropTypes.object,
    size: PropTypes.number,
    onSelect: PropTypes.func,
    initial: PropTypes.bool
}

export default BoardItem;