import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuSection from './MenuSection.js';
import MenuButton from './MenuButton.js';
import { MdArrowBack, MdRefresh } from 'react-icons/md';

const PageActions = () => {
    const navigate = useNavigate();
    const goback = () => navigate(-1);
    const reload = () => navigate(0);
    return (
        <MenuSection>
            <MenuButton icon={MdArrowBack} label="Go Back" disabled={(history.length === 0)} action={goback} />
            <MenuButton icon={MdRefresh} label="Reload" action={reload} />
        </MenuSection>
    );
}

export default PageActions;