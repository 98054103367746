import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../logo.svg';
import { useCurrentUser, useOrganizations } from '../../hooks/UserService';
import { HStack, Box, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { getItemColor } from '../../theme';
import { Icon } from '@chakra-ui/react';
import { RiArrowDownSLine } from 'react-icons/ri';
import PersonEmblem from '../../components/emblem/PersonEmblem';
import OptionSelector from '../../components/selector/OptionSelector';
import { useAPI } from '../../hooks/APIService';

const CurrentUser = () => {
    const navigate = useNavigate();
    const [ user, error, isLoading ] = useCurrentUser();
    const [ organizations, current ] = useOrganizations();
    const { switchOrganization } = useAPI();

    const openPerson = () => navigate('/person/' + user._id);

    const onOrganizationSelect = (name) => {
        console.log("select organization", name);
        const index = organizations.findIndex(org => org == name);
        if (index < 0) {
            // TODO : show error to user
            console.log("Invalid organization: " + name + "\nOnly following organizations are available to you: " + organizations);
            return;
        }
        switchOrganization(name);
        navigate('/');
        window.location.reload();
    }

    return (
        <HStack spacing={2} alignItems="center" cursor="pointer">
            { user &&
                <PersonEmblem item={user} size={3.2} />
            }
            { (!user || isLoading || error) &&
                <Image boxSize={8} src={Logo} alt="League of Teams Logo" />
            }
            <Box align="left" lineHeight="1rem">
                { user &&
                    <Box onClick={ openPerson } textAlign="left" _hover={{ color: getItemColor(user) }}>
                        <Text fontSize={"0.8rem"}>Welcome,</Text>
                        <Text fontSize={"1rem"}>{user.name}</Text>
                    </Box>
                }
                { (!user || isLoading || error) &&
                    <Text>LEAGUE OF TEAMS</Text>
                }
                { organizations.length > 1 &&
                    <OptionSelector options={organizations} onSelect={onOrganizationSelect}>
                        <HStack>
                            <Text fontSize={"0.6rem"}>({current})</Text>
                            <Icon boxSize={4} as={RiArrowDownSLine} />
                        </HStack>
                    </OptionSelector>
                }
                { organizations.length <= 1 &&
                    <Text fontSize={"0.6rem"}>({current})</Text>
                }
            </Box>
        </HStack>
    )
}

export default withAuthenticationRequired(CurrentUser);