import { Box } from '@chakra-ui/layout';
import React from 'react';
import { useNavigate } from 'react-router';
import SearchInput from '../../components/search/SearchInput.js';

const SearchAction = () => {
    const navigate = useNavigate();
    const search = (query) => {
        if (query && query.length > 0) navigate('/search/' + query);
    }
    return (
        <Box flex="auto">
            <SearchInput onSearch={search} />
        </Box>
    )
}

export default SearchAction;