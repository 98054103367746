import React, { useContext, useEffect, useState } from 'react';
import { HStack, Text } from '@chakra-ui/layout';
import Icon from '@chakra-ui/icon';
import { useAPI } from '../../hooks/APIService';
import { PersonContext } from './Person';
import TeamEmblem from '../../components/emblem/TeamEmblem';
import { RiTeamFill } from 'react-icons/ri';

const PersonLeadership = () => {
    const { person } = useContext(PersonContext);
    const [ leadership, setLeadership ] = useState([]);
    const { getTeams } = useAPI();

    useEffect(() => {
        loadLeadership();
    }, []);

    const loadLeadership = async() => {
        try {
            const leadership = await getTeams({"leader._id": person._id});
            setLeadership(leadership);
        }
        catch (e) {
            console.log("error retrieving leadership", e);
        }
    }

    const color = leadership?.length > 0 ? null : "gray.200";
    const title = leadership?.length > 0 ? "Team Leader for" : "No Team Responsibility";

    return (
        <HStack justifyItems="start" alignItems="start" spacing="4" width="full">
            <HStack spacing="1" color={color}>
                <Icon as={RiTeamFill} />
                <Text fontSize="xs">{title}</Text>
            </HStack>
            { (leadership?.length > 0) &&
                <HStack spacing="1">
                    { leadership.map( (item) => {
                        return <TeamEmblem key={item._id} item={item} size={2} displayName={true}/>
                    })}
                </HStack>
            }
        </HStack>
    );
}

export default PersonLeadership;