import React, { useEffect, useState } from 'react';
import { useAPI } from '../../hooks/APIService';
import { Alert, AlertDescription, AlertIcon, Box, Button, ButtonGroup, Center, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, Input, Spinner } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';

const OrganizationForm = () => {
    const { createOrganization, existsUser } = useAPI();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const { user, isLoading } = useAuth0();

    useEffect(() =>  {
        setEmail(user?.email ? user?.email : "");
    }, [isLoading]);

    function validateEmail(email) {
        const res = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return res.test(String(email).toLowerCase());
    }

    const handleNameChange = event => {
        const name = event.target.value.replace(/[^a-zA-Z0-9-_]/g, "");
        setName(name);
    }
    const handleEmailChange = async event => {
        const valid = validateEmail(event.target.value);
        console.log("valid email", valid);
        if (!valid) setError({ email: "invalid email address" });
        else setError(null);
        setEmail(event.target.value);
    }
    
    const handleSubmit = async event => {
        event.preventDefault();
        if (!error || !error.subject || !error.message) {
            console.log("exists user", email);
            const exists = await existsUser(email);
            console.log("exists user", email, exists);
            // TODO : send existing user
            createOrganization(name).then(() => {
                setError(null);
                setSubmitted(true);
            })
            .catch(error => {
                setError(error);
                console.log('ERROR: ' + error);
            });
        }
    }

    const onReset = event => {
        event.preventDefault();
        setName("");
        setSubmitted(false);
        setError(null);
    }

    if (isLoading) return <Center><Spinner /></Center>

    return <Center h="full" w="full" flexDirection="column">
        <Heading mb={8}>Create a new Organization</Heading>
        <form name="createOrganizationForm" className="OrganizationForm Form" onSubmit={handleSubmit}>
            { submitted && error &&
                <Box my={4}>
                    <Alert status="error" borderRadius={4}>
                        <AlertIcon />
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                </Box>
            }
            <FormControl id="name-input" mb="5" display="grid" w="24rem" isRequired isInvalid={error?.subject}>
                <FormLabel fontSize="xs" p={0} m={0}>Organization</FormLabel>
                <Input placeholder="Organization Name" aria-label="Organization Name" size="sm" focusBorderColor="green.500" variant="flushed" value={name} onChange={handleNameChange} />
                <FormHelperText fontSize="xs" fontStyle={"italic"}>only characters, numbers, minus, and underscore allowed</FormHelperText>
            </FormControl>
            <FormControl id="email-input" mb="5" display="grid" w="24rem" isRequired isInvalid={error?.email}>
                <FormLabel fontSize="xs" p={0} m={0}>Administrator E-Mail</FormLabel>
                <Input placeholder="Administrator E-Mail" aria-label="Administrator E-Mail" size="sm" focusBorderColor="green.500" variant="flushed" value={email} onChange={handleEmailChange} />
                <FormErrorMessage fontSize="xs">{error?.email}</FormErrorMessage>
            </FormControl>
            <ButtonGroup size="sm" mt={8} mb={8}>
                <Button type="submit" isDisabled={!name}>Create</Button>
                <Button type="button" onClick={onReset} variant="outline">Reset</Button>
            </ButtonGroup>
            { submitted && !error &&
                <Box my={4}>
                    <Alert status="success" borderRadius={4}>
                        <AlertIcon />
                        <AlertDescription>Organization succesfully created!</AlertDescription>
                    </Alert>
                </Box>
            }
        </form>
    </Center>
}

export default OrganizationForm;