import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//import { useAPI } from '../../hooks/APIService';
import EditableText from '../../components/editable/EditableText';
import EditableTextarea from '../../components/editable/EditableTextarea';
import { Box, Container, Divider, HStack, Icon, IconButton, Text, Tooltip, VStack, Wrap } from '@chakra-ui/react';
import { RiAwardLine, RiDeleteBin2Fill, RiFileTextLine, RiPriceTag2Fill, RiUserShared2Line } from 'react-icons/ri';
import { RxEyeClosed, RxEyeOpen } from 'react-icons/rx';
import { BiTask } from 'react-icons/bi';
import { useAPI } from '../../hooks/APIService';
import StickerLoader from '../../components/sticker/StickerLoader';
import { useNavigate } from 'react-router-dom';
import NotImplementedLabel from '../../components/notimplemented/NotImplementedLabel';
import RoleActivityList from './RoleActivityList';

const RoleEditor = ({ role, afterDelete }) => {
    const [ owner, setOwner ] = useState(new Set());
    const { getTeams, deleteRole } = useAPI();
    const [ userView, setUserView ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (role.type === "leader") loadLeader();
        else loadMembership();
    }, [role]);

    const loadLeader = async () => {
        // TODO : very inefficent - to be replaced with a dedicated call to API
        const teams = await getTeams({"leader.roles._id": role._id});
        const set = new Set();
        teams.map((team) => {
            team.leader.map((link) => {
                const hasRole = link.roles?.find((item) => item._id === role._id);
                if (hasRole) set.add(link._id);
            })
        })
        setOwner(set);
    }

    const loadMembership = async () => {
        // TODO : very inefficent - to be replaced with a dedicated call to API
        const teams = await getTeams({"membership.roles._id": role._id});
        const set = new Set();
        teams.map((team) => {
            team.membership.map((link) => {
                const hasRole = link.roles?.find((item) => item._id === role._id);
                if (hasRole) set.add(link._id);
            })
        })
        setOwner(set);
    }

    const handleSelect = (item) => {
        navigate('/' + item.class + '/' + item._id);
    }

    const handleDelete = async () => {
        if (owner.size > 0) return;
        await deleteRole(role._id);
        if (afterDelete) afterDelete();
    }

    return <VStack alignItems="start" spacing={2} lineHeight="1rem" width="full">
        <Box w="full" alignItems={'end'}>
            { owner.size === 0 &&
                <HStack justifyContent={'end'}>
                    <Text align={"right"} fontSize={"xs"} color="gray.200">no persons found with this role</Text>
                    <IconButton size="md" variant="link" icon={<RiDeleteBin2Fill />} onClick={handleDelete} cursor={"pointer"} />
                </HStack>
            }
            { owner.size > 0 &&
                <HStack justifyContent={'end'}>
                    <Text align={"right"} fontSize={"xs"}>{owner.size} person(s) with this role</Text>
                    { !userView &&
                        <IconButton size="md" variant="link" icon={<RxEyeOpen />} onClick={() => setUserView(true)} cursor={"pointer"} /> }
                    { userView &&
                        <IconButton size="md" variant="link" icon={<RxEyeClosed />} onClick={() => setUserView(false)} cursor={"pointer"} /> }
                </HStack>
            }
        </Box>
        <HStack width="full">
            <Tooltip hasArrow label="Role Name" fontSize="xs" placement="top-start">
                <Container p="0" m="0" w="auto">
                    <Icon as={RiPriceTag2Fill} />
                </Container>
            </Tooltip>
            <EditableText item={role} key={role._id} property={"name"} placeholder='enter a name'/>
        </HStack>
        <Divider />
        { userView &&
            <VStack alignItems="start" pt="0.5rem">
                <HStack width="full">
                    <Tooltip hasArrow label="Role Name" fontSize="xs" placement="top-start">
                        <Container p="0" m="0" w="auto">
                            <Icon as={RiUserShared2Line} />
                        </Container>
                    </Tooltip>
                    <Text fontSize="xs">Persons with Role</Text>
                </HStack>
                <Wrap spacing={2} overflow="visible" w="full">
                    { Array.from(owner).map(item => {
                        return <StickerLoader id={item} key={item} size={2} onClick={handleSelect}/>
                    })}
                </Wrap>
            </VStack>
        }
        { !userView && <>
            <VStack w="full" align={"start"} pt="0.5rem">
                <HStack width="full">
                    <Tooltip hasArrow label="A short description of the key features of the role" fontSize="xs" placement="top-start">
                        <Container p="0" m="0" w="auto">
                            <Icon as={RiFileTextLine} />
                        </Container>
                    </Tooltip>
                    <Text fontSize="xs">Summary</Text>
                </HStack>
                <EditableTextarea item={role} key={role._id} property={"description"} placeholder='add a description' fontSize='xs' minHeight='4rem'/>
            </VStack>
            <Divider />
            <VStack w="full" align={"start"} pt="1rem">
                <HStack width="full">
                    <Tooltip hasArrow label="List of activities related to this role" fontSize="xs" placement="top-start">
                        <Container p="0" m="0" w="auto">
                            <Icon as={BiTask} />
                        </Container>
                    </Tooltip>
                    <Text fontSize="xs">Related Activities</Text>
                </HStack>
                <RoleActivityList role={role} />
            </VStack>
            <Divider />
            <VStack w="full" align={"start"} pt="1rem">
                <HStack width="full">
                    <Tooltip hasArrow label="Desireable talents for the role" fontSize="xs" placement="top-start">
                        <Container p="0" m="0" w="auto">
                            <Icon as={RiAwardLine} />
                        </Container>
                    </Tooltip>
                    <Text fontSize="xs">Desired Talents</Text>
                </HStack>
                <NotImplementedLabel />
            </VStack>
        </>}
    </VStack>
}

RoleEditor.propTypes = {
    role: PropTypes.object.isRequired,
    afterDelete: PropTypes.func
}

export default RoleEditor;