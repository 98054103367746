import { Center } from '@chakra-ui/react';
import React from 'react';
import NotImplementedSection from '../../components/notimplemented/NotImplementedSection';

const Configuration = () => {
    return (
        <Center h="full" w="full">
            <NotImplementedSection name="Help" />
        </Center>
    );
}

export default Configuration;
