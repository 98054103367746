import React from 'react';
import PropTypes from 'prop-types';
import { Image, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

const OQScoreDetails = ({ domainDimension, valueDimension, score, debug }) => {

    if (!domainDimension || !valueDimension) {
        return null;
    }

    return <TableContainer width="100%" whiteSpace={'normal'} p={0}>
        <Table variant="simple" size="sm" style={{tableLayout: 'fixed'}} width={"100%"}>
            { debug &&
                <TableCaption placement='top'>
                    Intermediate Score and Confidence of OQ-Scanner
                    <br/>
                    <Text fontSize={'2xs'}>
                        OQ-Score: {score.getOQScore()}
                        /
                        Error: {score.getError().toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        /
                        Choice: +{score.getChoiceCountBySentiment(true)}/-{score.getChoiceCountBySentiment(false)}
                        /
                        Tree Count: {score.treeCount}
                    </Text>
                    <Text fontSize={'2xs'}>Tree: {score.options.map((option, index) => <span key={index}>{option.id} [{option.getScore().toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}]&nbsp;</span>)} </Text>
                </TableCaption>
            }
            <Thead><Tr>
                <Th style={{wordWrap: 'break-word'}} p={0}></Th>
                { domainDimension.parameters.map((domainDim, index) => {
                    return <Th key={index} style={{wordWrap: 'break-word'}} fontSize={'2xs'} textTransform={'none'} p={0} verticalAlign={"top"} textAlign={"center"}>
                        <Image margin={'0 auto'} boxSize={6} src={'../icons/' + domainDim.name + '.png'} alt={domainDim.name} />
                        {domainDim.name}
                        { debug &&
                            <>
                                <br/>
                                {domainDim.getScore().toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                &nbsp;
                                +{domainDim.getChoiceCountBySentiment(true)}/-{domainDim.getChoiceCountBySentiment(false)}
                            </>
                        }
                    </Th>
                })}
            </Tr></Thead>
            <Tbody>
                { valueDimension.parameters.map((valueDim, index) => {
                    const result = <Td fontSize={'2xs'} style={{wordWrap: 'break-word'}} p={0}>
                        <Image boxSize={6} src={'../icons/' + valueDim.name + '.png'} alt={valueDim.name} />
                        {valueDim.name}
                        { debug &&
                            <>
                                <br/>
                                {valueDim.getScore().toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                &nbsp;
                                +{valueDim.getChoiceCountBySentiment(true)}/-{valueDim.getChoiceCountBySentiment(false)}
                            </>
                        }
                    </Td>;
                    const optResult = domainDimension.parameters.map((domainParam, index) => {
                        const option = domainParam.findDimensionOption(valueDim);
                        var betterIds = [];
                        option.better.forEach(element => { betterIds.push(element.id) });
                        var worseIds = [];
                        option.worse.forEach(element => { worseIds.push(element.id) });
                        return <Td key={index} fontSize={'2xs'} style={{textAlign: '-webkit-center'}} p={0} textAlign="center">
                            { debug &&
                                <>
                                    [{option.id}]
                                    &nbsp;
                                    {option.answers.length}
                                    &nbsp;
                                    +{option.getChoiceCountBySentiment(true)}/-{option.getChoiceCountBySentiment(false)}
                                    <br/>
                                    +{betterIds.toString()}
                                    &nbsp;
                                    -{worseIds.toString()}
                                    <br/>
                                    {option.getScore().toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    &nbsp;
                                    {option.rawScore.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    <br/>
                                </>
                            }
                            <svg height="50" width="50">
                                <circle cx="25" cy="25" r="24" fill="#99bca8" />
                                <circle cx="25" cy="25" r={(option.getScore() + 1) * 12} fill="#4b707a" />
                            </svg>
                            <Text>{option.getOQScore()}</Text>
                            { debug && option.error != 0 && <Text color={'red'}>error: {option.error.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>}
                        </Td>
                    })
                    return <Tr key={index}>{result}{optResult}</Tr>;
                })}
            </Tbody>
        </Table>
    </TableContainer>

}

OQScoreDetails.propTypes = {
    domainDimension: PropTypes.object,
    valueDimension: PropTypes.object,
    score: PropTypes.object,
    debug: PropTypes.bool
}

export default OQScoreDetails;