import React from 'react';
import PropTypes from 'prop-types';
import TeamEmblem from '../emblem/TeamEmblem.js';
import { HStack, Text, VStack } from '@chakra-ui/layout';

const TeamListItem = ({ item, size, onClick }) => {
    return (
        <HStack w="full" onClick={ onClick } cursor="pointer">
            <TeamEmblem item={item} displayName={false} size={size} isLinked={false} />
            <VStack>
                <Text>{item.name}</Text>
                {item.role && <Text fontSize="xs">{item.role}</Text>}
            </VStack>
        </HStack>
    )
}

TeamListItem.propTypes = {
    item: PropTypes.object.isRequired,
    size: PropTypes.number,
    onClick: PropTypes.func
}

export default TeamListItem;