import React, { useCallback, useEffect, useState } from 'react';
import { Center, HStack, Heading, Image, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import OQScannerIntroduction from './OQScannerIntroduction';
import OQScannerRegistration from "./OQScannerRegistration";
import OQScannerExecution from './OQScannerExecution';
import OQScannerSummary from './OQScannerSummary';
import OQDimension, { OQDimParameter, OQOption, OQScore, OQStatement } from './OQScannerObjects';
import { useAPI } from '../../hooks/APIService';
import { useParams, useSearchParams } from 'react-router-dom';

const STATE = {
    Introduction: 1,
    Registration: 2,
    Test: 3,
    Summary: 4
}

const OQScanner = () => {
    const [ state, setState ] = useState(STATE.Introduction);
    const [ domainDimension, setDomainDimension ] = useState();
    const [ valueDimension, setValueDimension] = useState();
    const [ score, setScore ] = useState();
    const [ user, setUser ] = useState();
    const [ loading, isLoading ] = useState(true);
    const { getDomains, getValues, getStatements } = useAPI();
    const [ searchParams ] = useSearchParams();
    const code = useParams().code;
    const debug = searchParams.get("__debug") !== null;
    const editable = searchParams.get("__edit") !== null;

    const initializeTest = useCallback(async () => {
        console.log("initialize test");
        await initializeObjects();
        isLoading(false);
    }, []);

    useEffect(() => {
        initializeTest();
    }, [initializeTest]);

    const initializeObjects = async () => {
        console.log("initialize objects");
        
        const domains = await getDomains();
        const dimDomain = new OQDimension("Domain");
        domains.map((domain) => {
            new OQDimParameter(dimDomain, domain._id, domain.text);
        })

        const values = await getValues();
        const dimValue = new OQDimension("Value");
        values.map((value) => {
            new OQDimParameter(dimValue, value._id, value.text);
        })

        const score = new OQScore();
        var id = 1;
        for (var a = 0, b; a < dimDomain.parameters.length; ++a) {
            for (b = 0; b < dimValue.parameters.length; ++b) {
                score.addOption(new OQOption(id, dimDomain.parameters[a], dimValue.parameters[b]));
                id++;
            }
        }

        const statements = await getStatements();
        statements.map((statement) => {
            for (var i = 0; i < score.options.length; ++i) {
                if (score.options[i].domainParam.id == statement.domain && score.options[i].valueParam.id == statement.value) {
                    new OQStatement(score.options[i], statement._id, statement.positive, statement.text); 
                }
            }
        })

        setDomainDimension(dimDomain);
        setValueDimension(dimValue);
        setScore(score);
    }

    const onProceed = (next) => {
        setState(next);
    }

    return <Stack direction="column" align={"center"} width="100%">
        <Center width="80%" mt="2rem">
            <Stack spacing={"3rem"} width="100%" alignItems={'center'}>
                <Stack>
                    <Heading as="h1" size="3xl" fontWeight={"bold"}>OQ-Scanner</Heading>
                    <Heading as="h2" size="md">How intelligent is your organization?</Heading>
                </Stack>
                { loading &&
                    <VStack spacing={1} pt={20} pb={20}>
                        <Spinner thickness='4px' speed='0.85s' emptyColor='green.500' color='petrol.500' size='xl' />
                        <Text fontSize={"sm"}>loading...</Text>
                    </VStack>
                }
                { !loading && state === STATE.Introduction && <OQScannerIntroduction onProceed={() => onProceed(STATE.Registration)} /> }
                { !loading && state === STATE.Registration && <OQScannerRegistration codeParam={code} score={score} setUser={setUser} onProceed={() => onProceed(STATE.Test)} /> }
                { !loading && state === STATE.Test && <OQScannerExecution domainDimension={domainDimension} valueDimension={valueDimension} score={score} user={user} onProceed={() => onProceed(STATE.Summary)} debug={debug} editable={editable} /> }
                { !loading && state === STATE.Summary && <OQScannerSummary domainDimension={domainDimension} valueDimension={valueDimension} score={score} user={user} onProceed={() => onProceed(STATE.Test)} debug={debug} /> }
            </Stack>
        </Center>
        <HStack pt={3} pb={1}>
            <Text fontSize={'xs'}>powered by</Text>
            <a href="https://leapfroq.com/" target='_empty'><Image src='../leapfroq.png' w={100} /></a>
        </HStack>
    </Stack>

}

export default OQScanner;