import React from 'react';
import { Box, Button, Icon, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { MdSupportAgent } from 'react-icons/md';
import SupportForm from './SupportForm';

const Support = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return <>
        <Box pos="absolute" bottom={5} right={5}>
            <Button colorScheme="green" borderRadius="50%" w={12} h={12} onClick={onOpen}>
                <Icon as={MdSupportAgent} color="white" boxSize={8} />
            </Button>
        </Box>
        { isOpen &&
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent color="black" minW="40rem">
                    <ModalHeader>Create Ticket</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontSize="sm">
                            We are here to support you. Therefore we aim for continuous improvements of the user experience.
                            Your feedback is essential for us to understand how LEAGUE OF TEAMS is working for you.
                        </Text>
                        <Text fontSize="sm" mb={8}>
                            Please be as precise as possible when sending a ticket to us.
                            We are interested to hear about the details, as this will help us to understand better your needs und urgencies.
                        </Text>
                        <SupportForm onClose={onClose} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        }
    </>
}

export default Support;