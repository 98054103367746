import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Center } from '@chakra-ui/layout';
import { getCssIndexedColor } from '../../theme';

const WallImage = () => {
    const containerRef = useRef();
    const canvasRef = useRef();
    var simulation = null;

    const params = {
        dim: [ 2000, 1000 ],
        count: { small: 40, medium: 40, large: 20 },
        size: { small: 30, medium: 50, large: 70 },
        minDist: 10,
        distance: { small: 60, medium: 40, large: 20},
        boundary: { small: 0, medium: 50, large: 100},
        colorMap: [0, 1, 3, 4, 6],
    }

    useEffect(() => {
        console.log("load network");
        initItems();
        return () => simulation?.stop()
    }, []);

    const initItems = async() => {
        const nodes = d3.range(params.count.large + params.count.medium + params.count.small).map((index) => {
            const item = { id: index };
            item.x = Math.floor(Math.random() * params.dim[0] * 0.9) + (params.dim[0] * 0.05);
            item.y = Math.floor(Math.random() * params.dim[1] * 0.9) + (params.dim[1] * 0.05);
            item.color = Math.floor(Math.random() * 5);
            if (index < params.count.large) {
                item.size = params.size.large;
                //item.distance = item.size / 2 + Math.floor(Math.random() * params.distance.large) + params.minDist;
                item.distance = item.size / 2 + params.minDist + Math.abs(d3.randomNormal(0, params.distance.large)());
                item.boundary = params.boundary.large;
            }
            else if (index < params.count.large + params.count.medium) {
                item.size = params.size.medium;
                //item.distance = item.size / 2 + Math.floor(Math.random() * params.distance.medium) + params.minDist;
                item.distance = item.size / 2 + params.minDist + Math.abs(d3.randomNormal(0, params.distance.medium)());
                item.boundary = params.boundary.medium;
            }
            else {
                item.size = params.size.small;
                //item.distance = item.size / 2 + Math.floor(Math.random() * params.distance.small) + params.minDist;
                item.distance = item.size / 2 + params.minDist + Math.abs(d3.randomNormal(0, params.distance.small)());
                item.boundary = params.boundary.small;
            }
            return item;
        });
        simulation = drawOrgChart(nodes);
    }

    const drawOrgChart = (nodes) => {
        /*
        const containerRect = containerRef.current.getBoundingClientRect();
        const width = containerRect.width;
        const height = containerRect.height;
        */

        const svg = d3.select(canvasRef.current)
            //.attr("width", width).attr("height", height)
            .attr("viewBox", [0, 0, params.dim[0], params.dim[1]].join(" "));
        svg.selectAll("g").remove();

        /*
        var link = svg.append("g").attr("class", "links").selectAll("path")
            .data(dataset.links)
            .enter()
            .append("path")
            .attr("stroke", d => linkColorScale(d.type))
            .attr("stroke-width", d => strokeScale(d.type))
            .attr("stroke-dasharray", d => strokeDashScale(d.type))
            .attr("fill", "none");
        */
        
        var node = svg.append("g").attr("class", "nodes").selectAll("circle")
            .data(nodes)
            .enter()
            .append("g")

        node.append("circle")
            .attr("r", d => d.size / 2)
            //.attr("fill", "white")
            .attr("fill", d => getCssIndexedColor(params.colorMap[d.color]))
            .attr("fill-opacity", 0.05)
            .attr("stroke", d => getCssIndexedColor(params.colorMap[d.color]))
            .attr("stroke-width", 3)
            .attr("stroke-opacity", 1)
            //.attr("cx", d => d.x)
            //.attr("cy", d => d.y)
            //.attr("transform", d => "translate(" + d.x + "," + d.y + ")");

        svg.append("g").append("rect")
            .attr("width", params.dim[0])
            .attr("height", params.dim[1])
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1)

        const simulation = d3.forceSimulation()
            //.force("link", d3.forceLink().id(d => d._id))
            .force("charge", d3.forceManyBody()
                .strength(60)
            )
            .force("center", d3.forceCenter(params.dim[0] / 2, params.dim[1] / 2))
            .force("collision", d3.forceCollide().radius(d => d.distance))

        simulation.nodes(nodes).on("tick", () => {
            /*
            link
                .attr("d", d => lineGenerator([[d.source.x, d.source.y], [d.target.x, d.target.y]]));
            */
            node
                .attr("transform", d => "translate(" +
                    (d.x = Math.max(d.size + d.boundary, Math.min(d.x, params.dim[0] - d.size - d.boundary))) + "," +
                    (d.y = Math.max(d.size + d.boundary, Math.min(d.y, params.dim[1] - d.size - d.boundary))) +")")
                //.attr("cx", d => d.x)
                //.attr("cy", d => d.y);
            /*
            image
                .attr("x", d => d.x)
                .attr("y", d => d.y);
            */
        });
        simulation.force("link"); //.links(dataset.links);

        return simulation;
    }

    return (
        <Center h="full" w="full" ref={containerRef}>
            <svg width={"100%"} height={"100%"} ref={canvasRef} />
        </Center>
    )
}

export default WallImage;