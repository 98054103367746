import React, { useEffect, useState } from 'react';
import { Text, VStack } from '@chakra-ui/react';

const OQScannerCodes = () => {
    const [codes, setCodes] = useState([])

    useEffect(() => {
        generateCodes();
    }, []);

    const generateCodes = () => {
        var array = [];
        for (var i = 0; i < 20; i++) {
            const code = generateCode(6);
            array.push(code);
        }
        console.log("codes", codes);
        setCodes(array);
    }

    const generateCode = (length) => {
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var code = '';
        for (var i = length; i > 0; --i) code += chars[Math.floor(Math.random() * chars.length)];    
        return code;
    }

    return <VStack alignItems={'flex-start'} spacing={2} p={5} width="40">
        { codes.map((code, index) => {
            return <Text key={index}>{code}</Text>
        })}
    </VStack>

}

OQScannerCodes.propTypes = {
}

export default OQScannerCodes;