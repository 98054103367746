import React from 'react';
import PropTypes from 'prop-types';
import { Center } from '@chakra-ui/react';

const RoundedBox = ({ size = 6, thickness = 0.5, aspectRatio = 1, color, cssColor, shadowColor = "#ccc", radius = "20%", children }) => {

    return <Center m="auto" h={size * aspectRatio + "rem"} w={size + "rem"} bg="white" borderRadius={radius} borderColor={color}
                borderWidth={thickness +"rem"}  _hover={{ "boxShadow": "0 0 20px 5px " + cssColor }} boxShadow={"0 0 5px 2px " + shadowColor}>
            { children }
        </Center>

}

RoundedBox.propTypes = {
    size: PropTypes.number,
    thickness: PropTypes.number,
    aspectRatio: PropTypes.number,
    color: PropTypes.string,
    cssColor: PropTypes.string,
    shadowColor: PropTypes.string,
    radius: PropTypes.string,
    children: PropTypes.node
}

export default RoundedBox;