import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RiSearch2Line } from "react-icons/ri";
import { Input, InputGroup, InputRightAddon } from '@chakra-ui/input';
import Icon from '@chakra-ui/icon';

const SearchInput = ({ onSearch, autoFocus = false }) => {
    const [query, setQuery] = useState(null);
    const isEnter = (e) => {
        if (e.key == "Enter") onSearch(query);
    }
    const search = () => {
        onSearch(query);
    }
    return (
        <InputGroup size="sm" maxW="15rem">
            <Input placeholder="Search..." onKeyDown={ isEnter } autoFocus={autoFocus} onChange={ (e) => setQuery(e.target.value) } />
            <InputRightAddon onClick={search}>
                <Icon as={RiSearch2Line} />
            </InputRightAddon>
        </InputGroup>
    )
}

SearchInput.propTypes = {
    onSearch: PropTypes.func,
    autoFocus: PropTypes.bool
}

export default SearchInput;