import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DistributionReport from './DistributionReport';
import ReportFrame from './ReportFrame';
import { useAPI } from '../../hooks/APIService';
import { getCssIndexedColor } from '../../theme';

const TeamSizeReport = ({ width = 400, height = 300 }) => {
    const [dataset, setDataset] = useState();
    const api = useAPI();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async() => {
        const data = await api.getReport("teamsize");
        setDataset(data);
    }

    const xLabel = "Team Members";
    const yLabel = "Teams";

    const threshold = {
        range: [5, 8],
        color: getCssIndexedColor(4)
    }

    return (
        <ReportFrame title="Team Size" subtitle="by Number of Teams">
            { dataset &&
                <DistributionReport dataset={dataset} width={width} height={height} xLabel={xLabel} yLabel={yLabel} threshold={threshold} end={10} color={getCssIndexedColor(1)} />
            }
        </ReportFrame>
    );
}

TeamSizeReport.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}

export default TeamSizeReport;