import React from 'react';
import PropTypes from 'prop-types';
import EmblemBadge from './EmblemBadge';
import { HStack, Text } from '@chakra-ui/layout';
import { getItemColor, getTextColor } from '../../theme';
import { RiUser3Line } from 'react-icons/ri';
import { Icon, Tooltip } from '@chakra-ui/react';

const TeamSizeBadge = ({ item }) => {
    return (
        <EmblemBadge color={getItemColor(item)} left={false}>
            <Tooltip hasArrow label={(item.membership ? item.membership.length : "0") + " members"} fontSize="xs" placement="top-end" bg={getItemColor(item)} color={getTextColor(item)}>
                <HStack p="0 0.2rem" spacing="0" color={getTextColor(item)} alignItems="center">
                    <Icon as={RiUser3Line} h="0.75rem" w="0.75rem" />
                    <Text fontSize="0.75rem" pl="0.2rem" lineHeight={1.3}>{item?.membership ? item.membership.length : "x"}</Text>
                </HStack>
            </Tooltip>
        </EmblemBadge>
    )
}

TeamSizeBadge.propTypes = {
    item: PropTypes.object
}

export default TeamSizeBadge;