import React, { useEffect, useState, createContext } from 'react';
import { useParams } from 'react-router';
import { useAPI } from '../../hooks/APIService';
import Spinner from '../../components/spinner/Spinner.js';
import Card from '../../components/card/Card';
import { HStack } from '@chakra-ui/layout';
import { getItemColor } from '../../theme';
import ProjectCardHeader from './ProjectCardHeader';
import ProjectMemberships from './ProjectMemberships.js';
import ProjectProfile from './ProjectProfile';

export const ProjectContext = createContext();

const Project = () => {
    const [project, setProject] = useState(null);
    const params = useParams();
    const api = useAPI();

    useEffect(() => {
        loadProject();
    }, []);

    const loadProject = async() =>  {
        console.log("load project", params.id);
        const project = await api.getProjectById(params.id);
        setProject(project);
    }

    const updateProjectProperty = (property, value) => {
        console.log("update project property", project, property, value);
        var map = {};
        map[property] = value;
        api.patchProject(project._id, map);
        project[property] = value;
        setProject(project);
    }

    const deleteProject = () => {
        console.log("delete project", project);
        api.deleteProject(project._id);
        setProject(null);
    }

    if (!project) {
        return <Spinner />
    }

    return (
        <HStack w="full" h="full" gap={"5%"} pl={5} pr={5} justify="space-around" align="center"
            bgImage="url('/background.svg')" bgSize="cover" bgPosition="center" >
            <ProjectContext.Provider value={{ project, setProject, updateProjectProperty, deleteProject }}>
                <Card color={getItemColor(project)} key={project._id}>
                    <ProjectCardHeader />
                    <ProjectProfile />
                </Card>
                <ProjectMemberships key={project._id + "x"} />
            </ProjectContext.Provider>
        </HStack>
    )
}

export default Project;