import React from 'react';
import { Center, Flex } from '@chakra-ui/layout';
import TeamSizeReport from './TeamSizeReport';
import LineManagerReport from './LineManagerReport';
import TeamMembershipReport from './TeamMembershipReport';

const Reports = () => {
    return (
        <Center h="full">
            <Flex direction="row">
                <TeamSizeReport width={400} height={200} />
                <LineManagerReport width={400} height={200} />
                <TeamMembershipReport width={400} height={200} />
            </Flex>
        </Center>
    );
}

export default Reports;