import React from 'react';
import PropTypes from 'prop-types';
import EmblemBadge from './EmblemBadge';
import { RiStarLine, RiToolsFill } from 'react-icons/ri';
import { GiThreeFriends } from 'react-icons/gi';
import Icon from '@chakra-ui/icon';
import { Center } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';
import { getItemColor, getTextColor } from '../../theme';

const teamLabels = new Map([
    [ "primary", "Team" ],
    [ "workgroup", "Workgroup" ],
    [ "community",  "Community" ]    
]);

const TeamInfoBadge = ({ item }) => {

    return (
        <EmblemBadge color={getItemColor(item)} >
            <Tooltip hasArrow label={teamLabels.get(item.type)} fontSize="xs" placement="top-end" bg={getItemColor(item)} color={getTextColor(item)}>
                <Center color={getTextColor(item)} alignItems="center" p="0.1rem">
                    { item.type == "primary" && <Icon as={RiStarLine} h="0.75rem" w="0.75rem" /> }
                    { item.type == "workgroup" && <Icon as={RiToolsFill} h="0.75rem" w="0.75rem" /> }
                    { item.type == "community" && <Icon as={GiThreeFriends} h="0.75rem" w="0.75rem" /> }
                </Center>
            </Tooltip>
        </EmblemBadge>
    )
}

TeamInfoBadge.propTypes = {
    item: PropTypes.object
}

export default TeamInfoBadge;