import React from 'react';
import { useNavigate } from "react-router-dom";
import MenuButton from './MenuButton.js';
import { FiActivity } from 'react-icons/fi';
import { AiOutlineBarChart } from 'react-icons/ai';
import MenuSection from './MenuSection.js';
import { GiOrganigram } from 'react-icons/gi';
import { CgMenuGridO } from 'react-icons/cg';

const Navigation = () => {
    const navigate = useNavigate();
    const openDashboard = () => navigate('/');
    const openOrgChart = () => navigate('/chart');
    const openReporting = () => navigate('/reports');
    const openHealthCheck = () => navigate('/health');

    return (
        <MenuSection spacing={3} >
            <MenuButton icon={CgMenuGridO} label="Board" action={ openDashboard } size={8} />
            <MenuButton icon={GiOrganigram} label="Organization" action={ openOrgChart } size={8} />
            <MenuButton icon={AiOutlineBarChart} label="Reports" action={ openReporting } size={8} />
            <MenuButton icon={FiActivity} label="Health" disabled={true} action={ openHealthCheck } size={8} />
        </MenuSection>
    )
}

export default Navigation;