import Icon from '@chakra-ui/icon';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import React, { useContext } from 'react';
import { GrTarget } from 'react-icons/gr';
import NotImplementedLabel from '../../components/notimplemented/NotImplementedLabel';
import { TeamContext } from './Team';

const TeamObjectives = () => {
    const {team} = useContext(TeamContext);

    return (
        <VStack justifyItems="start" alignItems="start" spacing="0" width="full">
            <HStack spacing="1">
                <Icon as={GrTarget} />
                <Text fontSize="xs" fontWeight="bold">Objectives & Key Results</Text>
            </HStack>
            <HStack spacing="1" pt={2} pb={1}>
                <NotImplementedLabel />
                { team.objectives?.map( (item, index) => {
                    return <Text key={"okr_" + index}>{item.name}</Text>
                })}
            </HStack>
        </VStack>
    );
}

export default TeamObjectives;