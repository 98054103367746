import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertDescription, AlertIcon, Box, Button, HStack, IconButton, Image, List, ListIcon, ListItem, Progress, Stack, Text } from '@chakra-ui/react';
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { OQAnswer } from './OQScannerObjects';
import { useAPI } from '../../hooks/APIService';
import OQScoreDetails from './OQScoreDetails';
import OQScannerStatement from './OQScannerStatement';

const OQScannerExecution = ({ domainDimension, valueDimension, score, user, onProceed, debug, editable }) => {
    const [statements, setStatements] = useState(null);
    const [best, setBest] = useState(null);
    const [least, setLeast] = useState(null);
    const [error, setError] = useState(null);
    const { addAnswer, addScore } = useAPI();

    useEffect(() => {
        if (isDone()) onProceed();
        else nextOptions();
    }, []);

    const nextOptions = () => {
        setBest(null);
        setLeast(null);

        // find next option by rating
        var max = 0;
        var select = [];
        for (var i = 0; i < score.options.length; i++) {
            for (var j = i + 1; j < score.options.length; j++) {
                const rating = score.options[i].getRating(score.options[j]);
                if (rating > max) {
                    select = [[score.options[i], score.options[j]]];
                    max = rating;
                }
                else if (rating === max) select.push([score.options[i], score.options[j]]);
            }
        }

        var choiceOpts = [];
        var index = Math.floor(Math.random() * select.length);
        choiceOpts.push(select[index][0]);
        choiceOpts.push(select[index][1]);

        const dim = choiceOpts[0].valueParam === choiceOpts[1].valueParam ? choiceOpts[0].valueParam : choiceOpts[0].domainParam;

        console.log("next dimension to test", dim.name);

        max = 0;
        select = [];
        for (i = 0; i < score.options.length; i++) {
            const test = score.options[i];
            if (test.valueParam === dim || test.domainParam === dim) {
                const rating = choiceOpts[0].getRating(test) + choiceOpts[1].getRating(test);
                if (rating > max) {
                    select = [test];
                    max = rating;
                }
                else if (rating === max) {
                    select.push(test);
                }
            }
        }
        index = Math.floor(Math.random() * select.length);
        choiceOpts.push(select[index]);

        // determine sentiment and statement for the selected options
        const next = [];
        var sentiment = Math.random() > (choiceOpts[0].score + 1) / 2;
        var statement = findStatement(choiceOpts[0], sentiment);
        next.push(statement);

        sentiment = Math.random() > (choiceOpts[1].score + 1) / 2;
        statement = findStatement(choiceOpts[1], sentiment);
        next.push(statement);

        // ensure alternating sentiment for last element
        if (next[0].positive === next[1].positive) sentiment = !next[0].positive;
        else sentiment = Math.random() > (choiceOpts[2].score + 1) / 2;
        statement = findStatement(choiceOpts[2], sentiment);
        next.push(statement);

        shuffle(next);

        console.log("set next statements", next);

        setStatements(next);
    }

    function shuffle(array) {
        var currentIndex = array.length, randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex > 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
      
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
      
        return array;
    }

    const isDone = () => {
        return score.answers.length >= score.expectedAnswers;
    }

    const findStatement = (option, positive) => {
        var found = [];
        var count = 99;
        for (var i = 0; i < option.statements.length; ++i) {
            if (option.statements[i].positive === positive) {
                var c = option.statements[i].getCount();
                if (c < count) {
                    count = c;
                    found = [];
                }
                if (c === count) {
                    found.push(option.statements[i]);
                }
            }
        }

        /* used for initial statement generation only
        for (i = found.length; i < 3; i++) {
            const statement = new OQStatement(option, null, positive);
            statement.text = statement.description + " // " + (i + 1);
            console.log("add statement to db", statement.text);
            addStatement({ domain: option.domainParam.id, value: option.valueParam.id, positive: positive, text: statement.text, generated: true }).then((data) => {
                statement.id = data;
            });
            found.push(statement);
        }
        */

        var index = Math.floor(Math.random() * found.length);
        return found[index];
    }

    const getDimension = () => {
        if (!statements || statements.length < 2) return null;
        if (statements[0].option.valueParam === statements[1].option.valueParam)
            return statements[0].option.valueParam;
        else if (statements[0].option.domainParam === statements[1].option.domainParam)
            return statements[0].option.domainParam;
        return null;
    }

    const onSelected = (statement, sentiment) => {
        console.log("selected", statement, sentiment);
        if (sentiment) {
            setBest(statement);
            if (least === statement) setLeast(null);
        }
        else {
            setLeast(statement);
            if (best === statement) setBest(null);
        }
    }

    const onFinalized = () => {
        // TODO : store score
        addScore(user.license, user.email, score.getDBScore()).then(() => {
            onProceed();
        }).catch(error => {
            console.log('ERROR: ' + error);
            setError("Failed to store the score. Please try again or contact the technical support at support@leapfroq.com");
        });
    }

    const onContinue = () => {
        console.log("continue");
        if (!best || !least) return;
        var neutral = null;
        for (var i = 0; i < statements.length; i++) {
            if (statements[i] !== best && statements[i] !== least) neutral = statements[i];
        }
        if (neutral === null) return;
        const answer = new OQAnswer(best, neutral, least);
        addAnswer(user.license, user.email, answer.getDBObject()).then(data => {
            answer.id = data;
            score.addAnswer(answer);
            score.updateScore();
            setError(null);
            if (!isDone()) nextOptions();
            else onFinalized();
        }).catch(error => {
            console.log('ERROR: ' + error);
            setError("Failed to store the answer. Please try again or contact the technical support at support@leapfroq.com");
        });
    }

    return <Stack spacing={"2rem"}>
        <Text>
            Read the following statements carefully and then decide which of the statements you...
        </Text>
        <List p={0} m={0} spacing={0}>
            <ListItem><ListIcon as={CheckIcon} boxSize={5}/>agree with <b>the most</b></ListItem>
            <ListItem><ListIcon as={SmallCloseIcon} boxSize={5}/>agree with <b>the least</b></ListItem>
        </List>
        <HStack>
            <Text>{score.getActualAnswerCount() + 1}/{score.getExpectedAnswerCount()}</Text>
            <Text> - Theme:</Text>
            <Image boxSize={5} src={'../icons/' + getDimension()?.name + '.png'} alt={getDimension()?.name} />
            <Text><b>{getDimension()?.name}</b></Text>
        </HStack>
        <Progress value={score.getActualAnswerCount() / score.getExpectedAnswerCount() * 100} />
        { error &&
            <Box my={4}>
                <Alert status="error" borderRadius={4}>
                    <AlertIcon />
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            </Box>
        }
        <Stack spacing={5}>
            { statements?.map((statement, index) => {
                return <HStack key={index}>
                    <IconButton isRound={true} variant={best === statement ? 'solid' : 'outline'} colorScheme={best === statement ? 'green' : 'gray'} aria-label='most' icon={ <CheckIcon /> } onClick={ () => onSelected(statement, true) } />
                    <IconButton isRound={true} variant={least === statement ? 'solid' : 'outline'}  colorScheme={least === statement ? 'red' : 'gray'} aria-label='least' icon={ <SmallCloseIcon /> } onClick={ () => onSelected(statement, false) } />
                    <OQScannerStatement statement={statement} stmttext={statement.text} debug={debug} editable={editable} />
                </HStack>
            })}
        </Stack>
        <Button isDisabled={!best || !least} onClick={onContinue}>Next</Button>
        { debug &&
            <OQScoreDetails domainDimension={domainDimension} valueDimension={valueDimension} score={score} debug={debug} />
        }
    </Stack>
    
}

OQScannerExecution.propTypes = {
    domainDimension: PropTypes.object.isRequired,
    valueDimension: PropTypes.object.isRequired,
    score: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onProceed: PropTypes.func.isRequired,
    debug: PropTypes.bool,
    editable: PropTypes.bool
}

export default OQScannerExecution;