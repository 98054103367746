import React, { useState, useContext, useEffect } from 'react';
import TeamSelector from '../../components/selector/TeamSelector.js';
import { RiAddCircleLine, RiCloseLine } from 'react-icons/ri';
import { Heading, HStack, VStack, Wrap } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Button, IconButton, useToast } from '@chakra-ui/react';
import { useAPI } from '../../hooks/APIService.js';
import TeamSticker from '../../components/sticker/TeamSticker.js';
import { ProjectContext } from './Project.js';

const ProjectMemberships = () => {
    const { project } = useContext(ProjectContext);
    const [ teams, setTeams ] = useState(null);
    const { getTeams, patchTeam } = useAPI();
    const [ selector, setSelector ] = useState(null);
    const toast = useToast();

    useEffect(() => {
        loadTeams();
    }, []);

    const loadTeams = async() => {
        console.log("load teams");
        try {
            const data = await getTeams({ "projects._id": project._id });
            setTeams(data);
            console.log("teams loaded", data);
        }
        catch (e) {
            console.log("error retrieving memberships", e);
        }
    }

    const updateTeamProperty = (team, property, value) => {
        console.log("update team property", team, property, value);
        var map = {};
        map[property] = value;
        patchTeam(team._id, map);
        team[property] = value;
    }

    const addTeam = (team) => {
        console.log("add team", team);
        const isMember = teams.some(link => link._id == team._id);
        if (isMember) {
            toast({
                title: 'Warning',
                description: "Team " + team.name + " is already working on project " + project.name,
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
            setSelector(null)
            return;
        }
        if (!team.projects) team.projects = [];
        team.projects.push({ _id: project._id, type: selector.type });
        updateTeamProperty(team, "projects", team.projects);
        const refresh = [...teams, team];
        setTeams(refresh);
        setSelector(null);
    }

    const removeTeam = (team) => {
        console.log("remove team", team);
        const update = team.projects.filter(link => link._id !== project._id);
        updateTeamProperty(team, "projects", update);
        const refresh = teams.filter(link => link._id !== team.id);
        setTeams(refresh);
    }

    const resetSelector = () => {
        setSelector(null);
    }

    return (
        <VStack spacing={4} minWidth="40%" maxWidth="60%">
            <VStack w="full" align={"left"}>
                <HStack mb={2}>
                    <Heading fontSize="md">Teams</Heading>
                    <Button size="xs" leftIcon={<RiAddCircleLine />} fontWeight="normal" variant="link" onClick={ () => setSelector({type: "delivery", reftype: "primary"})}>
                        add a team
                    </Button>
                </HStack>
                <Wrap spacing={2} overflow="visible" w="full">
                { teams?.map(team => {
                    return <TeamSticker item={team} key={"team" + team._id} size={3}>
                        <VStack>
                            <IconButton isRound icon={<RiCloseLine />} size="xs" variant="link" onClick={ (e) => { removeTeam(team); e.stopPropagation(); } } />
                        </VStack>
                    </TeamSticker>
                })}
                </Wrap>
            </VStack>
            { selector &&
                <Modal isOpen={selector} onClose={resetSelector}>
                    <ModalOverlay />
                    <ModalContent color="black">
                        <ModalHeader>Select a Team...</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <TeamSelector onSelect={(team) => addTeam(team)} type={ selector.reftype } />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </VStack>
    )
}

export default ProjectMemberships;