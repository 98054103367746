import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { getCssColor, getItemColor, getTextColor } from '../../theme';
import { Box, Center, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import RoundedBox from '../shapes/RoundedBox';

const ProjectMiniCard = ({ item, size = 6, children, onClick, isLinked = true }) => {
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
    const navigate = useNavigate();

    const openItem = () => {
        navigate('/' + item.class + '/' + item._id);
    }

    const color = getItemColor(item);
    const textColor = getTextColor(item);

    return <Box position="relative" cursor="pointer" onClick={ () => { if (onClick) onClick(item); else if (isLinked) openItem(); } }>
        { item &&
            <RoundedBox size={size} radius="0" aspectRatio={.7} color={color} cssColor={getCssColor(item)} shadowColor='white' thickness={size * 0.01} >
                { item.picture &&
                    <Image src={IMAGE_URL + item.picture} w="95%" h="95%" borderRadius="0"/>
                }
                { !item.picture &&
                    <Center h="95%" w="95%" bg={color} borderRadius="0">
                        <Text fontSize={(size / 8) + "rem"} color={textColor} textAlign="center">{item.name}</Text>
                    </Center>
                }
            </RoundedBox>
        }
        {children}
    </Box>
}

ProjectMiniCard.propTypes = {
    item: PropTypes.object.isRequired,
    size: PropTypes.number,
    onClick: PropTypes.func,
    isLinked: PropTypes.bool,
    children: PropTypes.node
}

export default ProjectMiniCard;