import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from "@chakra-ui/layout";

const MenuSection = ({ children, spacing = 1 }) => {
    return <Stack spacing={spacing} direction="row" align="center">
        {children}
    </Stack>
}

MenuSection.propTypes = {
    children: PropTypes.node,
    spacing: PropTypes.number
}

export default MenuSection;