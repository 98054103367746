import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Flex } from '@chakra-ui/layout';

const Card = ({ color, children }) => {

    return <Flex direction="column" borderRadius="2rem" gap={0} boxShadow="0 0 5px 5px #ccc" bg="white" w="48rem">
        { React.Children.map(children, (element, index) => {
            if (index === 0)
                return (
                    <Box bg={color} p="0 0.5rem 0 0.5rem" borderLeftWidth="3px" borderRightWidth="3px" borderTopWidth="3px" borderTopRadius="2rem" borderColor={color} key={"section" + index}>
                        {element}
                    </Box>
                )
            else if (index === children.length - 1)
                return (
                    <Box p="0 0.5rem 0.5rem 0.5rem" borderLeftWidth="3px" borderRightWidth="3px" borderBottomWidth="3px" borderBottomRadius="2rem" borderColor={color} key={"section" + index}>
                        { (index > 1) && <Divider /> }
                        <Box p="0.2rem 0 0.2rem 0">{element}</Box>
                    </Box>
                )
            else
                return (
                    <Box p="0 0.5rem 0 0.5rem" borderLeftWidth="3px" borderRightWidth="3px" borderColor={color} key={"section" + index}>
                        { (index > 1) && <Divider /> }
                        <Box p="0.5rem 0 0.5rem 0">{element}</Box>
                    </Box>
                )
        })}
    </Flex>
}

Card.propTypes = {
    color: PropTypes.string.isRequired,
    children: PropTypes.node
}

export default Card;