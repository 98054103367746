import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@chakra-ui/react';
import { getIndexedColorScheme } from '../../theme';

const CapacityBadge = ({ person, teamLink, onClick }) => {

    const colorScheme = getIndexedColorScheme(person.style);

    return <Button size="xs" variant="solid" colorScheme={colorScheme} fontWeight="light" onClick={onClick}>
        {(person.capacity >= 0 && teamLink.capacity >= 0) && (Math.round(teamLink.capacity / person.capacity * 100) + "%")}
        {(person.capacity === undefined && teamLink.capacity >= 0) && (teamLink.capacity + "h")}
        {(person.capacity >= 0 && teamLink.capacity === undefined) && "0%"}
        {(person.capacity === undefined && teamLink.capacity === undefined) && "n/a"}
    </Button>

}

CapacityBadge.propTypes = {
    person: PropTypes.object.isRequired,
    teamLink: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
}

export default CapacityBadge;