import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HStack } from '@chakra-ui/react';
import OQScoreDimensionChart from './OQScoreDimensionChart';
import OQScoreDimensionText from './OQScoreDimensionText';

const OQScoreDimension = ({ dimension, referenceScore, changeTab }) => {
    const [ parameter, setParameter ] = useState(null);

    const selectParameter = (parameter) => {
        console.log("select parameter", parameter);
        setParameter(parameter);
    }

    return <HStack alignItems={'flex-start'} spacing={10}>
        <OQScoreDimensionChart dimension={dimension} referenceScore={referenceScore} selectParameter={selectParameter} />
        <OQScoreDimensionText parameter={parameter} changeTab={changeTab} />
    </HStack>

}

OQScoreDimension.propTypes = {
    dimension: PropTypes.object,
    referenceScore: PropTypes.number,
    changeTab: PropTypes.func
}

export default OQScoreDimension;