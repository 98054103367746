import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { RiFocusLine, RiPriceTag2Fill, RiTeamFill } from 'react-icons/ri';
import { Container, Divider, Center, Flex, HStack, VStack } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import Icon from '@chakra-ui/icon';
import ImageCropResizeUpload from '../../components/upload/ImageCropResizeUpload';
import EditableText from '../../components/editable/EditableText';
import { Tooltip } from '@chakra-ui/react';
import { getItemColor } from '../../theme';
import { TeamContext } from './Team';
import EditableTextarea from '../../components/editable/EditableTextarea';
import ItemContact from '../person/ItemContact';

const TeamProfile = () => {
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
    const { team, updateTeamProperty } = useContext(TeamContext);
    const [imageUrl, setImageUrl] = useState(team.picture);

    const onImageUploaded = (imageUrl) => {
        setImageUrl(imageUrl + "?" + Math.random());
    }

    const updateContact = async (values) => {
        await updateTeamProperty("contact", values);
    }

    const color = getItemColor(team);

    return (
        <Flex pt="0.5rem" pb="0.5rem" direction="row" gridGap="2em" width="full">
            <Center width="auto">
                <ImageCropResizeUpload id={team._id} onImageUploaded={onImageUploaded}>
                    { imageUrl &&
                        <Image minW="9rem" h="9rem" borderRadius="10%" src={IMAGE_URL + imageUrl} />
                    }
                    { !imageUrl &&
                        <Icon as={RiTeamFill} boxSize={36} color="gray.200" />
                    }
                </ImageCropResizeUpload>
            </Center>
            <VStack alignItems="start" spacing="0" lineHeight="1rem" width="full">
                <HStack width="full" alignItems="start" pb={1}>
                    <Tooltip hasArrow label="Title / Role" fontSize="xs" placement="top-start" bg={color}>
                        <Container p="0" m="0" w="auto">
                            <Icon as={RiPriceTag2Fill} />
                        </Container>
                    </Tooltip>
                    <EditableText item={team} property="title" fontSize="sm" placeholder="no title specified" />
                </HStack>
                <HStack width="full" alignItems="start" pb={1} minH="6rem">
                    <Tooltip hasArrow label="Purpose" fontSize="xs" placement="top-start" bg={color}>
                        <Container p="0" m="0" w="auto">
                            <Icon as={RiFocusLine} />
                        </Container>
                    </Tooltip>
                    <EditableTextarea item={team} property="purpose" fontSize="xs" placeholder="no purpose specified" />
                </HStack>
                <Divider colorScheme="black" />
                <ItemContact item={team} onChange={updateContact} />
                <Divider colorScheme="black" />
            </VStack>
        </Flex>
    )
}

TeamProfile.propTypes = {
    team: PropTypes.object
}

export default TeamProfile;