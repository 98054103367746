import { React, useState } from "react";
import PropTypes from 'prop-types';
import { Box, Button, ButtonGroup, FormControl, FormLabel, Heading, Input, Textarea } from "@chakra-ui/react";

const UpdateEntryForm = ({ title, onSubmit, onCancel }) => {
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");

    const handleSubjectChange = event => setSubject(event.target.value);
    const handleDescriptionChange = event => setDescription(event.target.value);

    const handleAdd = (event) => {
        event.preventDefault();
        onSubmit(subject, description);
        handleCancel();
    }

    const handleCancel = () => {
        setSubject("");
        setDescription("");
        onCancel();
    }

    return <Box pt={4}>
        <form name="updateEntryForm" className="UpdateEntry Form" onSubmit={handleAdd}>
            { title && <Heading size="xs">{title}</Heading> }
            <FormControl id="name-input" mb="2" display="flex" isRequired>
                <FormLabel fontSize="xs">Subject</FormLabel>
                <Input placeholder="Subject" aria-label="Subject" size="sm" focusBorderColor="green.500" variant="flushed" value={subject} onChange={handleSubjectChange} />
            </FormControl>
            <FormControl id="email-input" mb="2" display="flex" isRequired>
                <FormLabel fontSize="xs">Description</FormLabel>
                <Textarea placeholder="Description" aria-label="Description" size="sm" cols={80} focusBorderColor="green.500" variant="flushed" value={description} onChange={handleDescriptionChange} />
            </FormControl>
            <ButtonGroup size="sm" mt={8} mb={8}>
                <Button type="submit" isDisabled={subject.length === 0 || description.length === 0}>Add</Button>
                <Button type="button" onClick={handleCancel} variant="outline">Cancel</Button>
            </ButtonGroup>
        </form>
    </Box>
}

UpdateEntryForm.propTypes = {
    title: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func
}

export default UpdateEntryForm;