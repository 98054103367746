import React, { useContext, useState } from 'react';
import { MdWork } from 'react-icons/md';
import { BiCalendarStar, BiCalendarX } from 'react-icons/bi';
import { Container, Divider, Box, Flex, HStack, VStack } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { RiImage2Line } from 'react-icons/ri';
import Icon from '@chakra-ui/icon';
import { Tooltip } from '@chakra-ui/tooltip';
import ImageCropResizeUpload from '../../components/upload/ImageCropResizeUpload.js';
import { getItemColor, getTextColor } from '../../theme.js';
import EditableText from '../../components/editable/EditableText.js';
import "react-datepicker/dist/react-datepicker.css";
import EditableDate from '../../components/editable/EditableDate.js';
import { ProjectContext } from './Project.js';
import { RxFileText } from 'react-icons/rx';
import ItemContact from '../person/ItemContact.js';
import EditableTextarea from '../../components/editable/EditableTextarea.js';

const ProjectProfile = () => {
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
    const { project, updateProjectProperty } = useContext(ProjectContext);
    const [imageUrl, setImageUrl] = useState(project.picture);

    const onImageUploaded = (imageUrl) => {
        setImageUrl(imageUrl);
    }

    const updateContact = async (values) => {
        await updateProjectProperty("contact", values);
    }

    const color = getItemColor(project);
    const textColor = getTextColor(project);

    return (
        <Flex pt="0.5rem" pb="0rem" direction="row" gridGap="2rem" width="full">
            <Box width="auto">
                <ImageCropResizeUpload id={project._id} onImageUploaded={onImageUploaded}>
                    { imageUrl &&
                        <Image minW="9rem" h="6rem" src={IMAGE_URL + imageUrl} />
                    }
                    { !imageUrl &&
                        <Icon as={RiImage2Line} boxSize={36} color="gray.200" />
                    }
                </ImageCropResizeUpload>
            </Box>
            <VStack alignItems="start" spacing="0" lineHeight="1rem" width="full">
                <HStack>
                    <Tooltip hasArrow label="Client" fontSize="xs" placement="top-start" bg={color}>
                        <Container p="0" m="0" w="auto">
                            <Icon as={MdWork} />
                        </Container>
                    </Tooltip>
                    <EditableText item={project} property="client" />
                </HStack>
                <HStack width="full" alignItems="start" pb={1} minH="6rem">
                    <Tooltip hasArrow label="Purpose" fontSize="xs" placement="top-start" bg={color}>
                        <Container p="0" m="0" w="auto">
                            <Icon as={RxFileText} />
                        </Container>
                    </Tooltip>
                    <EditableTextarea item={project} property="description" fontSize="xs" placeholder="no project description" />
                </HStack>
                <HStack p="0.5rem 0 0.5rem 0" fontSize="xs">
                    <HStack>
                        <Tooltip hasArrow label="Start Date" fontSize="xs" placement="top-start" key={project.startDate} bg={color}>
                            <Container p="0" m="0" w="auto">
                                <Icon as={BiCalendarStar} boxSize={4} color={project.startDate ? "currentColor" : "gray.200"} />
                            </Container>
                        </Tooltip>
                        <EditableDate item={project} property="startDate" fontSize="xs" placeholder="-" color={new Date(project.startDate) > new Date() ? "green" : textColor}/>
                    </HStack>
                    <HStack>
                        <Tooltip hasArrow label="End Date" fontSize="xs" placement="top-start" key={project.endDate} bg={color}>
                            <Container p="0" m="0" w="auto">
                                <Icon as={BiCalendarX} boxSize={4} color={project.endDate ? "currentColor" : "gray.200"} />
                            </Container>
                        </Tooltip>
                        <EditableDate item={project} property="endDate" fontSize="xs" placeholder="-" color={new Date(project.endDate) < new Date() ? "red" : textColor}/>
                    </HStack>
                </HStack>
                <Divider colorScheme="black" />
                <ItemContact item={project} onChange={updateContact} />
                <Divider colorScheme="black" />
            </VStack>
        </Flex>
    )
}

export default ProjectProfile;