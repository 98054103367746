import Icon from '@chakra-ui/icon';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import React, { useContext } from 'react';
import { MdPlaylistAdd } from 'react-icons/md';
import { TeamContext } from './Team';

const TeamRequests = () => {
    const {team} = useContext(TeamContext);

    return (
        <VStack justifyItems="start" alignItems="start" spacing="0" width="full">
            <HStack spacing="1">
                <Icon as={MdPlaylistAdd} />
                <Text fontSize="xs" fontWeight="bold">Wanted</Text>
            </HStack>
            <HStack spacing="1">
                { team.needs == undefined &&
                    <Text fontSize="xs" fontStyle="italic">no additional needs</Text>
                }
                <Text fontSize="xs">{team.needs}</Text>
            </HStack>
        </VStack>
    );
}

export default TeamRequests;