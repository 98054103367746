import React, { useEffect, useState } from 'react';
import { Box, Flex, VStack, HStack, Text, Center } from '@chakra-ui/layout';
import { ArrowRightIcon } from '@chakra-ui/icons';
import { PropTypes } from 'prop-types';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import TalentTag from '../../components/tag/TalentTag';
import PersonMiniCard from '../../components/card/PersonMiniCard';
import { useAPI } from '../../hooks/APIService';
import TeamMiniCard from '../../components/card/TeamMiniCard';

const PersonDisplay = ({ item, size = 6, onSelect }) => {
    const navigate = useNavigate();
    const [ primary, setPrimary ] = useState(null);
    const { getTeams } = useAPI();

    useEffect(() => {
        loadPrimaryTeam()
    }, []);

    const loadPrimaryTeam = async () => {
        const teams = await getTeams({ "membership._id": item._id });
        console.log("primary team", teams);
        if (teams?.length > 0) {
            setPrimary(teams[0]);
        }
    }

    const openItem = () => {
        navigate('/' + item.class + '/' + item._id);
    }

    return(
        <Box w="full" h="full" p="2%" borderRadius="5%" borderWidth={1} boxShadow="-10px 0px 10px -7px #ccc, 10px 0px 10px -7px #ccc, 5px 5px 15px 5px rgba(0,0,0,0)">
            <VStack alignItems="center" color="black" h="full" w="full" spacing={"3%"} m="0 auto">
                <HStack spacing={"5%"} alignItems="stretch" w="full">
                    <PersonMiniCard item={item} size={size * 1.2} />
                    { primary && <>
                        <Center w="full"><ArrowRightIcon boxSize={3} /></Center>
                        <Center>
                            <TeamMiniCard item={primary} size={size} showBadge={false} onClick={onSelect} />
                        </Center>
                    </> }
                </HStack>
                <VStack spacing={0} h="full" w="full">
                    <Text fontSize="sm">{item.name}</Text>
                    <Text fontSize="xs" fontWeight="bold" lineHeight="1rem">{item.title}</Text>
                    <Text fontSize="xs">{item.location}</Text>
                    <Flex direction="row" gap="2" wrap="wrap" pt={2} w="full" overflow="clip" justifyContent={"center"}>
                        {item.talents?.map((talent, index) => {
                            if (!talent.proposed) return <TalentTag key={index} talent={talent} level={true} />
                        })}
                    </Flex>
                </VStack>
                <Button variant="link" size="xs" lineHeight="1rem" onClick={openItem}>open profile</Button>
            </VStack>
        </Box>
    )
}

PersonDisplay.propTypes = {
    item: PropTypes.object,
    size: PropTypes.number,
    onSelect: PropTypes.func
}

export default PersonDisplay;