import React from 'react';
import PropTypes from 'prop-types';
import { Button, HStack, Input, useNumberInput } from '@chakra-ui/react';

const CapacityInput = ({ max = 40, current = 0, handleChange }) => {
    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
        useNumberInput({
            step: 0.5,
            defaultValue: current,
            min: 0,
            max: max,
            precision: 1,
            onChange: (valueAsString, valueAsNumber) => handleValueChange(valueAsNumber)
        })

    const handleValueChange = (value) => {
        console.log("value", value);
        handleChange(value);
    }

    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    return <HStack maxW={24}>
        <Button {...inc} size="xs" variant="outline">+</Button>
        <Input {...input} size="sm" focusBorderColor="green.500" variant="flushed" textAlign="right"/>
        <Button {...dec} size="xs" variant="outline">-</Button>
    </HStack>

}

CapacityInput.propTypes = {
    max: PropTypes.number,
    current: PropTypes.number,
    handleChange: PropTypes.func
}

export default CapacityInput;