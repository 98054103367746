import React, { useContext, useRef, useState } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/layout';
import { getItemColor, getTextColor } from '../../theme';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import EditableText from '../../components/editable/EditableText';
import { TeamContext } from './Team';
import { useNavigate } from 'react-router-dom';
import OptionSelector from '../../components/selector/OptionSelector';

const TeamCardHeader = () => {
    const {team, updateTeamProperty, deleteTeam} = useContext(TeamContext);
    const [type, setType] = useState(team.type);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const navigate = useNavigate();

    const onTypeSelect = (type) => {
        console.log("type select", type);
        updateTeamProperty(team, "type", type);
        setType(type);
    }

    const onDelete = () => {
        console.log("delete team", team);
        deleteTeam();
        onClose();
        if (navigate.length > 0) navigate(-1);
        else navigate("/");
    }

    const color = getItemColor(team);
    const textColor = getTextColor(team);

    return <HStack justify="center" alignContent="center" p={1}>
        <Box w="5%"></Box>
        <VStack gap={0} spacing={1} width="90%" color={textColor}>
            <EditableText item={team} property="name" textAlign="center" useTheme fontWeight="bold" />
            <OptionSelector options={["primary", "workgroup", "community"]} onSelect={onTypeSelect}>
                <Text fontSize="xs">- {type.toUpperCase()} -</Text>
            </OptionSelector>
        </VStack>
        <Tooltip hasArrow label={ (team.membership?.length > 0) ? "Remove members first before you can delete the team" : "Delete Team permanently" } fontSize="xs" placement="top-start" bg={color} color={textColor}>
            <Box>
                <IconButton w="5%" icon={<RiDeleteBin2Fill />} variant="link" size="md" onClick={onOpen} color={textColor} isDisabled={ (team.membership?.length > 0) } />
            </Box>
        </Tooltip>
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='md' fontWeight='bold'>
                        Delete Team
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Text>You will remove this team <u>permanently</u> from this organization. This cannot be undone afterwards.</Text>
                        <Text>Are you sure?</Text>
                    </AlertDialogBody>
                    <AlertDialogFooter justifyContent="center">
                        <Button ref={cancelRef} onClick={onClose}>Cancel</Button>
                        <Button colorScheme='red' onClick={onDelete} ml={3}>Delete</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </HStack>
}

export default TeamCardHeader;