import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertDescription, AlertIcon, Box, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, HStack, IconButton, Input, PinInput, PinInputField, Stack, Text } from '@chakra-ui/react';
import { useAPI } from '../../hooks/APIService';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FaUserCheck } from 'react-icons/fa';

const OQScannerRegistration = ({ codeParam = "", score, setUser, onProceed }) => {
    const [ code, setCode ] = useState(codeParam);
    const [ email, setEmail ] = useState("");
    const [ emailSubmitted, setEmailSubmitted ] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const { getOQScannerLicense, getAnswers } = useAPI();

    const loadScan = async () => {
        console.log("load scan", code, email);

        getOQScannerLicense(code, email).then(license => {
            console.log("license", license);
            setUser({license: license._id, email: email});
            console.log("load answers", license);
            getAnswers(license._id, email).then(answers => {
                console.log("answers loaded", answers);
                score.addAnswers(answers);
                score.updateScore();
                onProceed();
            }).catch(error => {
                console.log('ERROR: ' + error);
                setError({general: "Internal Error: Failed to retrieve data."});
            });
        }).catch(error => {
            console.log('ERROR: ' + error);
            setError({general: "No license found for the given code and email."});
        });

        /*
        const license = await getOQScannerLicense(code, email);
        if (!license) {
            console.log('ERROR: ' + error);
            setError({general: "OQ-Scan not found. Invalid code or email address."});
            return;
        }
        const answers = await getAnswers(license, email);
        score.addAnswers(answers);
        onProceed();
        */
    }

    const handleCodeChange = code => setCode(code);
    const handleEmailChange = event => setEmail(event.target.value);

    const submitEmail = () => {
        validateEmail();
        if (!error || !error.email) {
            setEmailSubmitted(true);
        }
    }

    const handleSubmit = async event => {
        event.preventDefault();
        validateEmail();
        setSubmitted(true);
        if (!error || !error.email) {
            await loadScan();
        }
    }

    const validateEmail = () => {
        if (!email) return "Email must be specified";
        //const at = email.indexOf('@');
        const at = 5;
        const dot = email.lastIndexOf('.');
        const postfix = email.length - dot;
        console.log("validate email", email.length, at, dot, postfix);
        if (at <= 0 || at > dot || postfix > 4 || postfix < 3) setError({ email: "No valid email address" });
        else setError({ email: null });
    }

    return <Stack spacing={"2rem"}>
        <Text>Please authenticate yourself to start the OQ Scanner</Text>
            <Stack spacing={"5"}>
                { submitted && error?.general &&
                    <Box my={4}>
                        <Alert status="error" borderRadius={4}>
                            <AlertIcon />
                            <AlertDescription>{error?.general}</AlertDescription>
                        </Alert>
                    </Box>
                }
                { !emailSubmitted &&
                    <>
                        <FormControl id="email-input" mb="2" display="block" isRequired isInvalid={error?.email}>
                            <FormLabel fontSize="xs">Email</FormLabel>
                            <Input placeholder="Your Email Address" aria-label="Email" size="sm" focusBorderColor="green.500" variant="flushed" value={email} onChange={handleEmailChange} onBlur={validateEmail} />
                            <FormHelperText>Please provide your email address to sign-up.</FormHelperText>
                            <FormErrorMessage>{error?.email}</FormErrorMessage>
                        </FormControl>
                        <Button onClick={submitEmail}>Submit Email</Button>
                    </>
                }
                { emailSubmitted &&
                    <>
                        <HStack>
                            <IconButton icon={<ArrowBackIcon />} variant={"unstyled"} onClick={() => setEmailSubmitted(false)} />
                            <FaUserCheck color={"#4B707A"} />
                            <Text fontSize="sm" fontWeight={"semibold"} color={"#4B707A"}>{email}</Text>
                        </HStack>

                        <Text fontSize="xs">Code</Text>
                        <HStack>
                            <PinInput type='alphanumeric' size='sm' defaultValue={codeParam} autoFocus={true} onComplete={(code) => handleCodeChange(code)} variant="filled">
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                            </PinInput>
                        </HStack>
                        <Text fontSize="sm" color='gray'>Please enter the code delivered with your registration.</Text>
                        <Button type="submit" isDisabled={!code||!email} onClick={handleSubmit}>Start OQ-Scanner</Button>
                    </>
                }
            </Stack>
    </Stack>
    
}

OQScannerRegistration.propTypes = {
    codeParam: PropTypes.string,
    score: PropTypes.object,
    setUser: PropTypes.func.isRequired,
    onProceed: PropTypes.func.isRequired
}

export default OQScannerRegistration;