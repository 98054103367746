import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from "d3";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import StickerLoader from '../../components/sticker/StickerLoader';

const DistributionReport = ({ dataset, width = 400, height = 300, xLabel = null, yLabel = null, threshold, start = 0, end = 0, color = "teal" }) => {
    const canvasRef = React.createRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ selected, setSelected ] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        drawReport()
    }, []);

    const showItems = (items) => {
        setSelected(items);
        onOpen();
    }

    const onSelect = (item) => {
        navigate('/' + item.class + '/' + item._id);
    }

    const drawReport = () => {
        const margin = {
            top: 30,
            bottom: 60,
            left: 60,
            right: 30
        }

        // create the SVG canvas
        const svg = d3.select(canvasRef.current).classed("report", true).attr("id", "TimeSizeReport");

        // create the display
        svg.selectAll("g").remove();
        const display = svg.append("g").classed("display", true).attr("transform", `translate(${margin.left}, ${margin.top})`);
        const chartWidth = width - margin.left - margin.right;
        const chartHeight = height - margin.top - margin.bottom;

        const xScale = d3.scaleBand()
            .domain(Array.from({length: Math.max(end, d3.max(dataset, d => d._id)) + 1 - start}, (v, i) => i + start))
            .range([0, chartWidth]);
        const yScale = d3.scaleLinear()
            .domain([0, d3.max(dataset, d => d.count)])
            .range([chartHeight, 0]);

        display.append("g").classed("threshold", true).append("rect")
            .attr("x", xScale(threshold.range[0]))
            .attr("y", 0)
            .attr("width", xScale(threshold.range[1]) + xScale.bandwidth() - xScale(threshold.range[0]))
            .attr("height", chartHeight)
            .attr("fill", threshold.color)
            .attr("opacity", 0.3);

        const yGridlines = d3.axisLeft().ticks(5).scale(yScale).tickSizeInner(-chartWidth-5).tickSizeOuter(0).tickFormat("");
        display.append("g").classed("gridline", true).attr('transform', `translate(-5,0)`).call(yGridlines).attr("color", "#cccccc");
        const xAxis = d3.axisBottom().scale(xScale).tickSizeOuter(0);
        display.append("g").classed("x axis", true).attr('transform', `translate(0,${chartHeight+5})`).call(xAxis);
        const yAxis = d3.axisLeft().ticks(5).scale(yScale).tickSizeOuter(0);
        display.append("g").classed("y axis", true).attr('transform', `translate(-5,0)`).call(yAxis);

        display.select('.x.axis')
            .append('text')
            .attr('x', chartWidth/2)
            .attr('y', 40)
            .attr('fill', '#000')
            .style('font-size', '1.2em')
            .style('text-anchor', 'middle')
            .text(xLabel);
        display.select('.y.axis')
            .append('text')
            .attr('x', 0)
            .attr('y', 0)
            .attr('transform', `translate(-30, ${chartHeight/2}) rotate(-90)`)
            .attr('fill', '#000')
            .style('font-size', '1.2em')
            .style('text-anchor', 'middle')
            .text(yLabel);

        display.selectAll(".bar").data(dataset).enter().append("rect").classed("bar", true)
            .attr("x", d => xScale(d._id) + xScale.bandwidth() * 0.15)
            .attr("y", d => yScale(d.count))
            .attr("width", xScale.bandwidth() * 0.7)
            .attr("height", d => (chartHeight - yScale(d.count)))
            .attr("fill", color)
            .on("click", (event, d) => showItems(d.items) )
            .style("cursor", "pointer");

        display.selectAll(".bar-label").data(dataset).enter().append("text").classed("bar-label", true)
            .text(d => d.count)
            .attr("x", d => xScale(d._id) + xScale.bandwidth() * 0.5)
            .attr("dx", 0)
            .attr("y", d => yScale(d.count))
            .attr("dy", -5)
            .style('font-size', '0.7em')
            .style('text-anchor', 'middle');
    }

    return (<>
        <svg ref={canvasRef} width={width} height={height} />
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent color="black">
                <ModalHeader>Selected...</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack alignItems="start">
                        { selected?.length == 0 && <Text>No items found.</Text> }
                        { selected?.map(item => {
                            return <StickerLoader id={item} key={item} size={2} onClick={onSelect}/>
                        })}
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>)
}

DistributionReport.propTypes = {
    dataset: PropTypes.arrayOf(PropTypes.object).isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    xLabel: PropTypes.string,
    yLabel: PropTypes.string,
    threshold: PropTypes.object.isRequired,
    start: PropTypes.number,
    end: PropTypes.number,
    color: PropTypes.string
}

export default DistributionReport;