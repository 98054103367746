import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme ({
    shadows: {
        outline: "0px 0px 2px 0px rgba(0,0,0,0.5) inset"
    },
    colors: {
        gray: {
            50: "#EBEBEB",
            100: "#E0E0E0",
            200: "#CCCCCC",
            300: "#B8B8B8",
            400: "#A3A3A3",
            500: "#898989",
            600: "#7A7A7A",
            700: "#666666",
            800: "#525252",
            900: "#3D3D3D",
        },
        yellow: {
            500: "#F9D663",
            800: "#F9D663"
        },
        orange: {
            500: "#F38F22",
            600: "#F4A54D",
            700: "#F4BD7B",
            800: "#F6D6AC",
            900: "#FAEAD6"
        },
        melon: {
            500: "#F7BBB3",
            800: "#F7BBB3"
        },
        coral: {
            500: "#EB5C61",
            800: "#EB5C61"
        },
        green: {
            500: "#99BCA8",
            600: "#AECBB7",
            700: "#C1D7C9",
            800: "#D6E5DE",
            900: "#E9F1EE"
        },
        mint: {
            500: "#1D9798",
            800: "#1D9798"
        },
        petrol: {
            500: "#4B707A",
            800: "#4B707A"
        },
        purple: {
            500: "#373154",
            800: "#373154"
        }
    },
    fonts: {
        body: "'Munken Sans', sans-serif",
        heading: "'Munken Sans', sans-serif",
        mono: "Menlo, monospace",
    }
});

const accent = [
    "yellow",
    "orange",
    "melon",
    "coral",
    "green",
    "mint",
    "petrol",
    "purple"
]

const text = [
    "black",
    "black",
    "black",
    "black",
    "black",
    "white",
    "white",
    "white"
]

export const getIndexedColorScheme = (col_index) => {
    return accent[col_index];
}

export const getIndexedColor = (col_index) => {
    return accent[col_index] + ".500";
}

export const getIndexedTextColor = (col_index) => {
    return text[col_index];
}

export const getItemColor = (item, shade = 500) => {
    if (!item) return "gray." + shade;
    const col_index = item.style == undefined ? parseInt(item._id.slice(-1), 16) % 8 : item.style;
    return accent[col_index] + "." + shade;
}

export const getTextColor = (item) => {
    if (!item) return "gray.500";
    const col_index = item?.style == undefined ? parseInt(item._id.slice(-1), 16) % 8 : item.style;
    return text[col_index];
}

export const getCssColor = (item, shade = 500) => {
    var color = getItemColor(item, shade);
    color = color.replace('.', '-');
    return "var(--chakra-colors-" + color + ")";
}

export const getCssIndexedColor = (index, shade = 500) => {
    const color = accent[index] + "-" + shade;
    return "var(--chakra-colors-" + color + ")";
}