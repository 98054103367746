import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAPI } from '../../hooks/APIService.js';
import { Input } from '@chakra-ui/input';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import { Select, Textarea } from '@chakra-ui/react';

const SupportForm = ({ onClose }) => {
    const { createTicket } = useAPI();
    const [type, setType] = useState(null);
    const [subject, setSubject] = useState(null);
    const [description, setDescription] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const handleTypeChange = event => setType(event.target.value);
    const handleSubjectChange = event => setSubject(event.target.value);
    const handleDescriptionChange = event => setDescription(event.target.value);
    const handleSubmit = async event => {
        event.preventDefault();
        setSubmitted(true);
        console.log("error test", error);
        if (!error) {
            await sendTicket();
        }
    }

    const sendTicket = async () => {
        const ticket = {
            type: type,
            subject: subject,
            description: description
        }
        console.log("create ticket", ticket);
        await createTicket(ticket).then(() => {
            onClose();
        }).catch(error => {
            console.log('ERROR: ' + error);
            setError("error creating ticket: " + error);
        });
    }

    return (
        <form name="createTicketForm" className="SupportForm Form" onSubmit={handleSubmit}>
            { submitted && error &&
                <Box my={4}>
                    <Alert status="error" borderRadius={4}>
                        <AlertIcon />
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                </Box>
            }
            <FormControl id="type-select" mb="2" display="flex" gridGap="2" isRequired>
                <FormLabel fontSize="xs" minW={24}>Request Type</FormLabel>
                <Select size="sm" colorScheme="green" placeholder='What kind of request do you want to send?' focusBorderColor="green.500" variant="flushed" value={type} onChange={handleTypeChange}>
                    <option value='issue'>Report an issue</option>
                    <option value='feature'>Request a feature</option>
                    <option value='question'>Ask a question</option>
                </Select>
            </FormControl>
            <FormControl id="subject-input" mb="2" display="flex" isRequired>
                <FormLabel fontSize="xs" minW={28}>Subject</FormLabel>
                <Input placeholder="Subject" aria-label="Subject" size="sm" focusBorderColor="green.500" variant="flushed" value={subject} onChange={handleSubjectChange} />
            </FormControl>
            <FormControl id="description-input" mb="2" display="flex">
                <FormLabel fontSize="xs" minW={28}>Description</FormLabel>
                <Textarea placeholder="Provide a description of your request..." aria-label="Description" size="sm" focusBorderColor="green.500" variant="flushed" value={description} onChange={handleDescriptionChange} />
            </FormControl>
            <ButtonGroup size="sm">
                <Button type="submit" isDisabled={!type||!subject}>Send Ticket</Button>
                <Button type="button" onClick={onClose} variant="outline">Cancel</Button>
            </ButtonGroup>
        </form>
    )
}

SupportForm.propTypes = {
    onClose: PropTypes.func
}

export default SupportForm;