import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useAPI } from '../../hooks/APIService';
import { Center, Flex, IconButton, Text, useToast } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import { getTextColor } from '../../theme';
import "react-datepicker/dist/react-datepicker.css";
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from 'react-icons/bi';

const EditableDate = ({ item, property, textAlign = "left", useTheme = false, fontWeight = "normal", fontSize = "md", placeholder = "not specified" }) => {
    const [ date, setDate ] = useState(item[property] ? new Date(item[property]) : null);
    const { patchPerson, patchTeam, patchRole, patchProject } = useAPI();
    const toast = useToast();

    const onDateSelect = (date) => {
        var map = {};
        map[property] = date;
        switch (item.class) {
            case "person": patchPerson(item._id, map); break;
            case "team": patchTeam(item._id, map); break;
            case "project": patchProject(item._id, map); break;
            case "role": patchRole(item._id, map); break;
            default:
                toast({
                    title: 'Invalid object class ' + item.class + '.',
                    description: property.charAt(0).toUpperCase() + property.slice(1) + " updated failed.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
        }
        toast({
            title: 'Saved changes.',
            description: property.charAt(0).toUpperCase() + property.slice(1) + " updated successfully.",
            status: 'success',
            duration: 5000,
            isClosable: true,
        })
        setDate(date);
        item[property] = date;
    }

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const textColor = useTheme ? getTextColor(item) : "black";

    return (
        <>
            <style>
                {`#date-picker-${property} {
                    width: 5rem;
                    font-size: var(--chakra-fontSizes-${fontSize});
                    font-weight: var(--chakra-fontWeight-${fontWeight});
                    text-align: ${textAlign};
                    color: ${textColor};
                    cursor: pointer;
                }`}
            </style>
            <ReactDatePicker selected={date} placeholderText={placeholder} onChange={date => onDateSelect(date)}
                dateFormat="yyyy-MMM-dd" id={"date-picker-" + property}
                renderCustomHeader={({
                    date,
                    decreaseYear,
                    increaseYear,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                    prevYearButtonDisabled,
                    nextYearButtonDisabled,
                }) => (
                    <Flex direction="row" gridGap="0">
                        <IconButton icon={<BiChevronsLeft />} variant="link" onClick={decreaseYear} isDisabled={prevYearButtonDisabled} size="xs" />
                        <IconButton icon={<BiChevronLeft />} variant="link" onClick={decreaseMonth} isDisabled={prevMonthButtonDisabled} size="xs" />
                        <Center width="full">
                            <Text>{months[getMonth(date)]} {getYear(date)}</Text>
                        </Center>
                        <IconButton icon={<BiChevronRight />} variant="link" onClick={increaseMonth} isDisabled={nextMonthButtonDisabled} size="xs" />
                        <IconButton icon={<BiChevronsRight />} variant="link" onClick={increaseYear} isDisabled={nextYearButtonDisabled} size="xs" />
                    </Flex>
                  )}/>
        </>
    )
}

EditableDate.propTypes = {
    item: PropTypes.object.isRequired,
    property: PropTypes.string.isRequired,
    textAlign: PropTypes.string,
    useTheme: PropTypes.bool,
    fontWeight: PropTypes.string,
    placeholder: PropTypes.string,
    fontSize: PropTypes.string
}

export default EditableDate;