import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { HStack, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react';
import { ChevronRightIcon, SmallAddIcon } from '@chakra-ui/icons';
import RoleDialog from './RoleDialog';
import { useAPI } from '../../hooks/APIService';
import RoleTag from '../../components/tag/RoleTag';
import RoleEditor from './RoleEditor';

const RoleListing = ({ team, person, type }) => {
    const [ loading, setLoading ] = useState(false);
    const [ roles, setRoles ] = useState([]);
    const [ select, setSelect ] = useState(false);
    const [ edit, setEdit ] = useState(null);
    const { getRoleById, patchTeam } = useAPI();
    const toast = useToast();

    useEffect(() => {
        if (!loading) loadRoles();
    }, []);

    const loadRoles = async () => {
        setLoading(true);
        const rel = team[type].find(rel => rel._id === person._id);
        if (rel.roles?.length > 0) {
            const entities = [];
            rel.roles?.map((item) => {
                getRoleById(item._id).then(data => {
                    entities.push(data);
                    setRoles(entities);
                });
            })
        }
        setLoading(false);
    }

    const selectRole = (role) => {
        const hasRole = roles?.some(item => item._id == role._id);
        if (hasRole) {
            toast({
                title: 'Role already selected.',
                description: person.name + " has already the role " + role.name + ".",
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
            return;
        }
        // build list for role update
        const update = [];
        roles.map(item => update.push({_id: item._id}));
        update.push({_id: role._id});
        // find correct relation and update content
        const rel = team[type].find(rel => rel._id === person._id);
        rel.roles = update;
        // patch team property
        var map = {};
        map[type] = team[type];
        patchTeam(team._id, map);
        // update state
        const refresh = [...roles, role];
        setRoles(refresh);
        setSelect(null);
    }

    const removeRole = (role) => {
        // find correct relation and update content
        const update = [];
        const rel = team[type].find(rel => rel._id === person._id);
        rel.roles.map(item => {
            if (item._id !== role._id) update.push(item);
        })
        rel.roles = update;
        // patch team property
        var map = {};
        map[type] = team[type];
        patchTeam(team._id, map);
        // update state
        const refresh = [];
        roles.map(item => {
            if (item._id !== role._id) refresh.push(item);
        })
        setRoles(refresh);
    }

    if (team.type !== "primary") return;

    return (
        <HStack spacing={3}>
            { roles.length == 0 &&
                <HStack spacing={1} color={"gray.200"} onClick={(e) => { setSelect(true); e.stopPropagation(); }}>
                    <ChevronRightIcon />
                    <Text fontSize="xs">no role specified</Text>
                </HStack>
            }
            { roles.map(role => {
                return <RoleTag role={role} key={role._id} onClick={() => setEdit(role)} onRemove={() => removeRole(role)} />;
            })}
            { roles.length > 0 &&
                <IconButton size="xs" icon={<SmallAddIcon />} variant={"ghost"} borderRadius="50%" onClick={(e) => { setSelect(true); e.stopPropagation(); }} />
            }
            <Modal isOpen={select} onClose={() => setSelect(false)} scrollBehavior='inside' size="3xl" closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent color="black">
                    <ModalHeader>Select/Create a { type === "leader" ? "Leader" : "Team Member" } Role</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <RoleDialog onSelect={(role) => selectRole(role)} type={type} />
                    </ModalBody>
                </ModalContent>
            </Modal>
            { edit &&
                <Modal isOpen={edit} onClose={() => setEdit(null)} scrollBehavior='inside' size="2xl" closeOnOverlayClick={false}>
                    <ModalOverlay />
                    <ModalContent color="black">
                        <ModalHeader>View/Edit Role</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <RoleEditor role={edit} />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </HStack>
    )
}

RoleListing.propTypes = {
    team: PropTypes.object.isRequired,
    person: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['leader', 'membership'])
}

export default RoleListing;