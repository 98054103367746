import React, { useContext } from 'react';
import { ChartContext, MODE } from './OrganizationChart';
import { Box, HStack, StackDivider, VStack } from '@chakra-ui/layout';
import { Checkbox, Radio, RadioGroup, Switch, Text } from '@chakra-ui/react';
//import { useNavigate } from 'react-router-dom';
import PersonMiniCard from '../../components/card/PersonMiniCard';
import TeamMiniCard from '../../components/card/TeamMiniCard';
import EmptyMiniCard from '../../components/card/EmptyMiniCard';
import ProjectMiniCard from '../../components/card/ProjectMiniCard';

const OrganizationFilter = () => {
    const { chartType, setChartType, selected, labelNodes, addLabelNodes, include, setInclude } = useContext(ChartContext);

    const handleChartTypeChange = (value) => {
        // need to maintain the original object link to match properly
        // TODO : find better solution
        if (value == MODE.Hierarchy) setChartType(MODE.Hierarchy);
        if (value == MODE.Teams) setChartType(MODE.Teams);
        if (value == MODE.Network) setChartType(MODE.Network);
    }

    const handleLabelsDisplay = (event) => {
        addLabelNodes(event.target.checked);
    }

    const handleTeamTypeChange = (event) => {
        const copy = { ...include };
        copy.team[event.target.value] = event.target.checked;
        setInclude(copy)
        console.log("team type", event.target.value, event.target.checked);
    }

    const handleProjectTypeChange = (event) => {
        const copy = { ...include };
        if (!copy.project) copy.project = {};
        copy.project[event.target.value] = event.target.checked;
        setInclude(copy)
        console.log("project type", event.target.value, event.target.checked);
    }

    const handleRelationTypeChange = (event) => {
        const copy = { ...include };
        copy.relation[event.target.value] = event.target.checked;
        setInclude(copy)
        console.log("relation type", event.target.value, event.target.checked);
    }

    /*
    const handleLeaderTypeChange = (event) => {
        console.log("leader type", event.target.value, event.target.checked);
    }
    */

    return <VStack h="full" minW="10rem" align="left" p={3} divider={<StackDivider borderColor='gray.200' />} fontSize="xs">
        <Box>
            { (!selected || !selected.item) &&
                <EmptyMiniCard size={8}><Text fontSize="xs" align="center" p="1rem">select a person or team to see the profile</Text></EmptyMiniCard>
            }
            { selected && selected.item?.class === "person" &&
                <PersonMiniCard size={8} item={selected.item}>
                    <Text fontSize="xs" align="center">{selected.item.name}</Text>
                    { !selected.roles &&
                        <Text align="center" fontWeight="bold">no role specified</Text>
                    }
                    { selected.roles?.map((role) => {
                        return <Text key={role._id} align="center" fontWeight="bold">{role.name}</Text>
                    })}
                    <Text fontSize="xs" align="center">click for details</Text>
                </PersonMiniCard>
            }
            { selected && selected.item?.class === "project" &&
                <ProjectMiniCard size={8} item={selected.item}>
                    <Text fontSize="xs" align="center">{selected.item.name}</Text>
                    <Text fontSize="xs" align="center">click for details</Text>
                </ProjectMiniCard>
            }
            { selected && selected.item?.class === "team" &&
                <TeamMiniCard size={8} item={selected.item}>
                    <Text fontSize="xs" align="center">{selected.item.name}</Text>
                    <Text align="center" fontWeight="bold">{selected.item.leader ? selected.item.leader.length : 0} leader, {selected.item.membership ? selected.item.membership.length : 0} member</Text>
                    <Text align="center" fontWeight="bold">{selected.item.projects ? selected.item.projects.length : 0} projects</Text>
                </TeamMiniCard>
            }
        </Box>
        <Box>
            <Text fontSize="xs">Display Mode</Text>
            { console.log("chart type", chartType === MODE.Hierarchy) }
            <RadioGroup size="sm" onChange={handleChartTypeChange} value={chartType}>
                <VStack align={"left"} spacing={0}>
                    <Radio value={MODE.Hierarchy}><Text fontSize="xs">Organization</Text></Radio>
                    <Radio value={MODE.Teams}><Text fontSize="xs">Teams</Text></Radio>
                    <Radio value={MODE.Network}><Text fontSize="xs">People</Text></Radio>
                </VStack>
            </RadioGroup>
        </Box>
        <HStack>
            <Text fontSize="xs">Show Labels</Text>
            <Switch size="sm" isChecked={labelNodes} onChange={handleLabelsDisplay} />
        </HStack>
        <Box>
            <Text fontSize="xs">Teams</Text>
            <VStack align="left" spacing="0">
                <Checkbox size="sm" isChecked={include.team.primary} value="primary" onChange={handleTeamTypeChange}><Text fontSize="xs">Primary Team</Text></Checkbox>
                <Checkbox size="sm" isChecked={include.team.workgroup} value="workgroup" onChange={handleTeamTypeChange}><Text fontSize="xs">Workgroup</Text></Checkbox>
                <Checkbox size="sm" isChecked={include.team.community} value="community" onChange={handleTeamTypeChange}><Text fontSize="xs">Community</Text></Checkbox>
            </VStack>
        </Box>
        <Box>
            <Text fontSize="xs">Projects</Text>
            <VStack align="left" spacing="0">
                <Checkbox size="sm" isChecked={include.project?.deliverable} value="deliverable" onChange={handleProjectTypeChange}><Text fontSize="xs">Deliverable</Text></Checkbox>
                <Checkbox size="sm" isChecked={include.project?.service} value="service" onChange={handleProjectTypeChange}><Text fontSize="xs">Service</Text></Checkbox>
            </VStack>
        </Box>
        { chartType != MODE.Hierarchy &&
            <Box>
                <Text fontSize="xs">Relations</Text>
                <VStack align="left" spacing="0">
                    { chartType != MODE.Teams &&
                        <>
                        <Checkbox size="sm" isChecked={include.relation.primary} value="primary" onChange={handleRelationTypeChange}><Text fontSize="xs">Primary Member</Text></Checkbox>
                        <Checkbox size="sm" isChecked={include.relation.contributor} value="contributor" onChange={handleRelationTypeChange}><Text fontSize="xs">Contributor</Text></Checkbox>
                        </>
                    }
                    <Checkbox size="sm" isChecked={include.relation.leader} value="leader" onChange={handleRelationTypeChange}><Text fontSize="xs">Leader</Text></Checkbox>
                    <Checkbox size="sm" isChecked={include.relation.line} value="line" onChange={handleRelationTypeChange}><Text fontSize="xs">Line Manager</Text></Checkbox>
                </VStack>
            </Box>
        }
    </VStack>

}

export default OrganizationFilter;