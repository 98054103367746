import React from 'react';
import PageActions from './PageActions.js';
import CurrentUser from './CurrentUser.js';
import Navigation from './Navigation.js';
import SearchAction from './SearchAction.js';
import ItemActions from './ItemActions.js';
import BaseActions from './BaseActions.js';
import { HStack } from '@chakra-ui/layout';

const Menu = () => {
    return (
        <HStack w="full" p={2} spacing={8} align="center" justify="space-between" whiteSpace="nowrap" zIndex="1">
            <PageActions />
            <CurrentUser />
            <Navigation />
            <SearchAction />
            <ItemActions />
            <BaseActions />
        </HStack>
    );
}

export default Menu;