import { React } from 'react';
import { useContext } from "react";
import { PersonContext } from "./Person";
import Icon from "@chakra-ui/icon";
import { HStack, Text, VStack } from "@chakra-ui/layout";
import { GiStairsGoal } from 'react-icons/gi';
import NotImplementedLabel from '../../components/notimplemented/NotImplementedLabel';

const PersonObjectives = () => {
    const {person} = useContext(PersonContext);

    return (
        <VStack justifyItems="start" alignItems="start" spacing="0" width="full">
            <HStack spacing="1">
                <Icon as={GiStairsGoal} />
                <Text fontSize="xs">Personal Objectives & Key Results</Text>
            </HStack>
            <HStack spacing="1" pt={2} pb={1}>
                { person.objectives?.map( (item, index) => {
                    return <Text key={"okr_" + index}>{item.name}</Text>
                })}
                <NotImplementedLabel />
            </HStack>
        </VStack>
    );
}

export default PersonObjectives;