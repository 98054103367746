import React, { useContext, useState } from 'react';
import { PersonContext } from './Person.js';
import { MdWork, MdHome, MdLocationOn } from 'react-icons/md';
import { BiCalendarStar, BiCalendarX } from 'react-icons/bi';
import { Box, Container, Divider, Flex, HStack, VStack } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { RiUser6Fill } from 'react-icons/ri';
import Icon from '@chakra-ui/icon';
import { Tooltip } from '@chakra-ui/tooltip';
import ImageCropResizeUpload from '../../components/upload/ImageCropResizeUpload.js';
import { getTextColor } from '../../theme.js';
import EditableText from '../../components/editable/EditableText.js';
import "react-datepicker/dist/react-datepicker.css";
import EditableDate from '../../components/editable/EditableDate.js';
import PersonLineManager from './PersonLineManager.js';
import ItemContact from './ItemContact.js';

const PersonProfile = () => {
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
    const { person, updatePersonProperty } = useContext(PersonContext);
    const [imageUrl, setImageUrl] = useState(person.picture);

    const onImageUploaded = (imageUrl) => {
        setImageUrl(imageUrl);
    }

    const updateContact = async (values) => {
        const index = values.findIndex(element => element.type === "email");
        if (index >= 0) {
            const email = values[index];
            if (email.address !== person.email) await updatePersonProperty("email", email.address);
            values.splice(index, 1);
        }
        await updatePersonProperty("contact", values);
    }

    //const color = getItemColor(person);
    const textColor = getTextColor(person);

    return (
        <Flex pt="0.5rem" pb="0rem" direction="row" gridGap="2rem" width="full">
            <Box width="auto">
                <ImageCropResizeUpload id={person._id} onImageUploaded={onImageUploaded}>
                    { imageUrl &&
                        <Image minW="9rem" h="9rem" borderRadius="30%" src={IMAGE_URL + imageUrl} />
                    }
                    { !imageUrl &&
                        <Icon as={RiUser6Fill} boxSize={36} color="gray.200" />
                    }
                </ImageCropResizeUpload>
            </Box>
            <VStack alignItems="start" spacing="0" lineHeight="1rem" width="full">
                <HStack>
                    <Tooltip hasArrow label="Role or Title" fontSize="xs" placement="top-start" color={textColor}>
                        <Container p="0" m="0" w="auto">
                            <Icon as={MdWork} />
                        </Container>
                    </Tooltip>
                    <EditableText item={person} property="title" />
                </HStack>
                <HStack>
                    <Tooltip hasArrow label="Location" fontSize="xs" placement="top-start" color={textColor}>
                        <Container p="0" m="0" w="auto">
                            <Icon as={MdHome} />
                        </Container>
                    </Tooltip>
                    <EditableText item={person} property="location" fontSize="xs" />
                </HStack>
                <HStack>
                    <Tooltip hasArrow label="Office / Workplace" fontSize="xs" placement="top-start" color={textColor}>
                        <Container p="0" m="0" w="auto">
                            <Icon as={MdLocationOn} />
                        </Container>
                    </Tooltip>
                    <EditableText item={person} property="workplace" fontSize="xs" />
                </HStack>
                <HStack p="0.5rem 0 0.5rem 0" fontSize="xs">
                    <HStack>
                        <Tooltip hasArrow label="Entry Date" fontSize="xs" placement="top-start" key={person.entryDate} color={new Date(person.entryDate) > new Date() ? "green" : textColor}>
                            <Container p="0" m="0" w="auto">
                                <Icon as={BiCalendarStar} boxSize={4} color={person.entryDate ? "currentColor" : "gray.200"} />
                            </Container>
                        </Tooltip>
                        <EditableDate item={person} property="entryDate" fontSize="xs" placeholder="-"/>
                    </HStack>
                    <HStack>
                        <Tooltip hasArrow label="Exit Date" fontSize="xs" placement="top-start" key={person.exitDate} color={new Date(person.exitDate) < new Date() ? "red" : textColor}>
                            <Container p="0" m="0" w="auto">
                                <Icon as={BiCalendarX} boxSize={4} color={person.exitDate ? "currentColor" : "gray.200"} />
                            </Container>
                        </Tooltip>
                        <EditableDate item={person} property="exitDate" fontSize="xs" placeholder="-"/>
                    </HStack>
                </HStack>
                <Divider colorScheme="black" />
                <ItemContact item={person} onChange={updateContact} />
                <Divider colorScheme="black" />
                <PersonLineManager />
            </VStack>
        </Flex>
    )
}

export default PersonProfile;