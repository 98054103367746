import React from 'react';
import PropTypes from 'prop-types';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { useNavigate } from 'react-router-dom';
import { getItemColor } from '../../theme.js';
import ProjectEmblem from '../emblem/ProjectEmblem.js';

const ProjectSticker = ({ item, size = 2, element, children}) => {
    const navigate = useNavigate();

    const openItem = () => {
        navigate('/' + item.class + '/' + item._id);
    }

    const color = getItemColor(item);
    var bgColor = "";
    switch (item.type) {
        case "deliverable": bgColor = "white"; break;
        case "service": bgColor = "#eee"; break;
    }

    console.log("project sticker");

    return (
        <HStack w={size + 15 + "rem"} p={"0.2rem"} onClick={openItem} cursor="pointer" bg={bgColor} borderColor={color} borderWidth={2}>
            <ProjectEmblem item={item} displayName={false} size={size} isLinked={false} />
            <VStack w="full" align={"left"} verticalAlign={"center"} spacing={0}>
                <Text fontSize="xs" fontWeight="bold">{item.name}</Text>
                {element}
            </VStack>
            {children}
        </HStack>
    )
}

ProjectSticker.propTypes = {
    item: PropTypes.object.isRequired,
    size: PropTypes.number,
    element: PropTypes.node,
    children: PropTypes.node
}

export default ProjectSticker;