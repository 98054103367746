import React from 'react';
import PropTypes from 'prop-types';
import EmblemBadge from './EmblemBadge';
import { RiTimerFlashLine } from 'react-icons/ri';
import Icon from '@chakra-ui/icon';
import { Center } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';
import { getItemColor, getTextColor } from '../../theme';
import { RxTimer } from 'react-icons/rx';

const projectLabels = new Map([
    [ "deliverable", "Deliverable" ],
    [ "service", "Service" ]    
]);

const ProjectInfoBadge = ({ item }) => {

    return (
        <EmblemBadge color={getItemColor(item)} >
            <Tooltip hasArrow label={projectLabels.get(item.type)} fontSize="xs" placement="top-end" bg={getItemColor(item)} color={getTextColor(item)}>
                <Center color={getTextColor(item)} alignItems="center" p="0.1rem">
                    { item.type == "deliverable" && <Icon as={RxTimer} h="0.75rem" w="0.75rem" /> }
                    { item.type == "service" && <Icon as={RiTimerFlashLine} h="0.75rem" w="0.75rem" /> }
                </Center>
            </Tooltip>
        </EmblemBadge>
    )
}

ProjectInfoBadge.propTypes = {
    item: PropTypes.object
}

export default ProjectInfoBadge;